import { IntegrationType } from '@piccolohealth/echo-common';
import React from 'react';
import MedicalObjectsLogo from '/logos/mo.logo.png';
import { useAppContext } from '../../../../hooks/useAppContext';
import { showModal } from '../../../generic/Modal';
import { IntegrationCard } from '../IntegrationCard';
import { MedicalObjectsIntegrationModal } from './MedicalObjectsIntegrationModal';

const title = 'Medical-Objects';
const description = 'Medical-Objects secure messaging connects doctors';

export const MedicalObjectsIntegrationCard = () => {
  const { organization } = useAppContext();

  const isIntegrationEnabled = organization.hasIntegrationEnabled(IntegrationType.MedicalObjects);

  return (
    <IntegrationCard
      title={title}
      description={description}
      logo={MedicalObjectsLogo}
      isIntegrationEnabled={isIntegrationEnabled}
      onEditIntegration={() =>
        showModal(MedicalObjectsIntegrationModal, {
          title,
          description,
          logo: MedicalObjectsLogo,
          isIntegrationEnabled,
        })
      }
      h='4xs'
      w='sm'
    />
  );
};
