import { Tabs, TabsProps } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

export interface Props extends TabsProps {
  routes: string[];
  keepQueryParams?: boolean;
}

export const TabsRouter = (props: Props) => {
  const { routes, keepQueryParams, children, ...rest } = props;

  const resolvedPath = useResolvedPath('');
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // If the current path is not in the list of routes, redirect to the first route.
    const lastPath = P.last(location.pathname.split('/'));
    if (P.isNil(lastPath) || (lastPath && !routes.includes(lastPath))) {
      navigate(routes[0], { replace: true });
    }
  }, [navigate, location.pathname, routes]);

  const selectedTab = React.useMemo(() => {
    const strippedRoute = location.pathname.replace(`${resolvedPath.pathname}/`, '');
    const tabIndex = routes.findIndex((route) => route === strippedRoute);
    return tabIndex > -1 ? tabIndex : 0;
  }, [location.pathname, resolvedPath.pathname, routes]);

  const handleTabsChange = React.useCallback(
    (index: number) => {
      const queryParams = keepQueryParams ? location.search : '';
      const route = `${routes[index]}${queryParams}`;

      navigate(route, { replace: true });
    },
    [keepQueryParams, location.search, navigate, routes],
  );

  return (
    <Tabs index={selectedTab} onChange={handleTabsChange} {...rest}>
      {children}
    </Tabs>
  );
};
