import { Button } from '@chakra-ui/react';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { useConfig } from '../../context/ConfigContext';

export const HelpCenterButton = () => {
  const { config } = useConfig();

  return (
    <Button
      as='a'
      colorScheme='purple'
      color='white'
      bg='transparent'
      href={config.intercom.helpCenterUrl}
      target='_blank'
      leftIcon={<FaQuestionCircle />}
    >
      Help center
    </Button>
  );
};
