import { Box, Center, HStack, Icon, Spacer, Text } from '@chakra-ui/react';
import { ReportAttachment } from '@piccolohealth/echo-common';
import { getExtension } from '@piccolohealth/util';
import React from 'react';
import { FaFile, FaFileCsv, FaFileImage, FaFilePdf, FaFileVideo, FaFileWord } from 'react-icons/fa';

const getAttachmentIcon = (attachment: ReportAttachment) => {
  const extension = getExtension(attachment.name);

  switch (extension) {
    case 'pdf':
      return { icon: FaFilePdf, color: 'red.500' };
    case 'csv':
      return { icon: FaFileCsv, color: 'green.500' };
    case 'doc':
    case 'docx':
      return { icon: FaFileWord, color: 'blue.500' };
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return { icon: FaFileImage, color: 'orange.500' };
    case 'mp4':
    case 'avi':
    case 'wmv':
    case 'mov':
    case 'mkv':
      return { icon: FaFileVideo, color: 'purple.500' };
    default:
      return { icon: FaFile, color: 'gray.500' };
  }
};

interface Props {
  attachment: ReportAttachment;
}

export const AttachmentHeader = (props: Props) => {
  const { attachment } = props;

  const { icon, color } = getAttachmentIcon(attachment);

  return (
    <Box w='full'>
      <HStack spacing={4} h='auto'>
        <Center w={10} h={10} p={1} rounded='xl' bg='gray.100'>
          <Icon as={icon} boxSize='6' color={color} />
        </Center>
        <Text ml={4} fontSize='xl' fontWeight='semibold' noOfLines={1}>
          {attachment.name}
        </Text>
        <Spacer />
      </HStack>
    </Box>
  );
};
