import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading } from '@chakra-ui/react';
import React from 'react';
import { NavLink, useMatches } from 'react-router-dom';

type RouteHandle = {
  crumb: (data: any) => React.ReactElement | undefined;
};

export const Breadcrumbs = () => {
  const matches = useMatches();

  const breadcrumbs = matches
    .filter((match) => match.handle && (match.handle as RouteHandle).crumb)
    .map((match) => ({
      breadcrumb: (match.handle as RouteHandle).crumb(match.data),
      match,
    }));

  return (
    <Heading size='md'>
      <Breadcrumb separator='/'>
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <BreadcrumbItem key={match.pathname}>
            <BreadcrumbLink as={NavLink} to={match.pathname}>
              {breadcrumb}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Heading>
  );
};
