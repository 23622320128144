import {
  Button,
  ButtonGroup,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReportShare, ReportShareStatus } from '@piccolohealth/echo-common';
import { DataTable, Empty, Spin, createColumnHelper } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaCheckCircle, FaSyncAlt, FaTimesCircle } from 'react-icons/fa';
import * as Yup from 'yup';
import { Error } from '../../components/generic/Error';
import { useReportSharesMinimalQuery } from '../../graphql/hooks/useReportQuery';
import { useShareReportMutation } from '../../graphql/hooks/useShareReportMutation';
import { useUnshareReportMutation } from '../../graphql/hooks/useUnshareReportMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { FormSection } from '../forms/FormSection';
import { HookedAutoCompleteEmail } from '../forms/hookform/HookedAutoCompleteEmail';
import { HookedFormItem } from '../forms/hookform/HookedFormItem';
import { HookedSelect } from '../forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../forms/hookform/HookedSubmitButton';
import { HookedTextArea } from '../forms/hookform/HookedTextArea';
import { Divider } from '../generic/Divider';
import { createModal } from '../generic/Modal';
import { ReportShareExpiryTag } from '../reports/custom/ReportShareExpiryTag';

const POLLING_INTERVAL = 4000;

interface Props {
  reportId: string;
}

interface FormValues {
  email: string;
  message?: string;
  expiry: ExpiryOption;
}

type ExpiryOption = 'OneDay' | 'OneWeek' | 'TwoWeeks' | 'OneMonth' | 'NoExpiry';

const expiryFromString = (value: ExpiryOption): DateTime | null => {
  switch (value) {
    case 'OneDay':
      return DateTime.now().plus({ day: 1 });
    case 'OneWeek':
      return DateTime.now().plus({ week: 1 });
    case 'TwoWeeks':
      return DateTime.now().plus({ week: 2 });
    case 'OneMonth':
      return DateTime.now().plus({ month: 1 });
    case 'NoExpiry':
      return null;
  }
};

export const ReportShareModal = createModal<Props>((props) => {
  const { reportId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const initialValues = { email: '', message: undefined, expiry: 'OneMonth' };
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useShareReportMutation();

  const onSubmit = async (values: FormValues) => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId: reportId,
        shareReportRequest: {
          toEmail: values.email,
          message: values.message,
          expiresAt: expiryFromString(values.expiry),
        },
      })
      .then(() => {
        successToast(`Report shared successfully`);
        methods.reset();
      })
      .catch((err) => {
        errorToast(`Error sharing report: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-pw='reportShareModalHeader'>Share report</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <Text mb={4} data-pw='reportShareModalDescription'>
                You can share this report with other users
              </Text>
              <FormSection mb={4}>
                <HookedFormItem label='Email' name='email' data-pw='reportShareModalEmailFormItem'>
                  <HookedAutoCompleteEmail
                    name='email'
                    data-pw='reportShareModalEmailAutocomplete'
                    isDisabled={false}
                    placeholder='Enter an email'
                  />
                </HookedFormItem>
                <HookedFormItem
                  label='Message'
                  name='message'
                  data-pw='reportShareModalMessageFormItem'
                >
                  <HookedTextArea
                    name='message'
                    placeholder='Enter a message'
                    data-pw='reportShareModalMessageTextarea'
                    size='sm'
                    minRows={4}
                    maxRows={8}
                  />
                </HookedFormItem>
                <HookedFormItem
                  label='Expiry'
                  name='expiry'
                  data-pw='reportShareModalExpiryFormItem'
                >
                  <HookedSelect
                    name='expiry'
                    data-pw='reportShareModalExpirySelect'
                    options={[
                      {
                        label: '1 day',
                        value: 'OneDay',
                        raw: 'OneDay',
                      },
                      {
                        label: '7 days',
                        value: 'OneWeek',
                        raw: 'OneWeek',
                      },
                      {
                        label: '14 days',
                        value: 'TwoWeeks',
                        raw: 'TwoWeeks',
                      },
                      {
                        label: '30 days',
                        value: 'OneMonth',
                        raw: 'OneMonth',
                      },
                      {
                        label: 'No expiry',
                        value: 'NoExpiry',
                        raw: 'NoExpiry',
                      },
                    ]}
                  />
                </HookedFormItem>
              </FormSection>
              <Divider />
              <ReportShareDetails reportId={reportId} />
              <Divider />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button onClick={modal.hide} data-pw='reportShareModalCloseButton'>
                  Close
                </Button>
                <HookedSubmitButton data-pw='reportShareModalSubmitButton'>
                  Share
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});

export const ReportShareStatusTag = (props: { status: ReportShareStatus }) => {
  switch (props.status) {
    case ReportShareStatus.Processing:
      return (
        <Tooltip label='The server is sending your message.' placement='top'>
          <Tag size='sm' colorScheme='blue'>
            <Icon as={() => <FaSyncAlt className='fa-spin' />} />
            <TagLabel ml={2}>Processing</TagLabel>
          </Tag>
        </Tooltip>
      );
    case ReportShareStatus.Sent:
      return (
        <Tooltip label='The server delivered your message successfully.' placement='top'>
          <Tag size='sm' colorScheme='green'>
            <Icon as={FaCheckCircle} />
            <TagLabel ml={2}>Delivered</TagLabel>
          </Tag>
        </Tooltip>
      );
    case ReportShareStatus.Failed:
      return (
        <Tooltip
          label='The server was unable to deliver your message (ex: unknown user, mailbox not found).'
          placement='top'
        >
          <Tag size='sm' colorScheme='red'>
            <Icon as={FaTimesCircle} />
            <TagLabel ml={2}>Failed</TagLabel>
          </Tag>
        </Tooltip>
      );
  }
};

export const ReportShareDetails = (props: Props) => {
  const { organization, successToast, errorToast } = useAppContext();
  const { reportId } = props;

  const [refetchInterval, setRefetchInterval] = React.useState<number | undefined>(undefined);

  const mutation = useUnshareReportMutation({});

  const unshareReport = React.useCallback(
    async (id: string, shareeEmail: string) => {
      return mutation
        .mutateAsync({
          organizationId: organization.id,
          reportShareId: id,
          reportId: reportId,
        })
        .then(() => {
          successToast(`Report no longer shared with ${shareeEmail}`);
        })
        .catch((err) => {
          errorToast(`Error unsharing report: ${err.message}`);
        });
    },
    [mutation, organization.id, reportId, successToast, errorToast],
  );

  const { isLoading, data, error } = useReportSharesMinimalQuery(
    {
      organizationId: organization.id,
      reportId,
    },
    { refetchInterval },
  );

  const shares = React.useMemo(
    () => (data?.organization?.report?.shares ?? []) as ReportShare[],
    [data?.organization?.report?.shares],
  );

  React.useEffect(() => {
    const anyStillProcessing = shares.some(
      (share: ReportShare) => share.status === ReportShareStatus.Processing,
    );
    setRefetchInterval(anyStillProcessing ? POLLING_INTERVAL : undefined);
  }, [setRefetchInterval, shares]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<ReportShare>();

    return [
      columnHelper.display({
        header: 'Email',
        cell: (ps) => (
          <Text fontSize='sm' key={ps.row.original.shareeEmail}>
            {ps.row.original.shareeEmail}
          </Text>
        ),
        minSize: 300,
      }),
      columnHelper.display({
        header: 'Status',
        cell: (ps) => <ReportShareStatusTag status={ps.row.original.status} />,
        minSize: 100,
      }),
      columnHelper.display({
        header: 'Expiry',
        cell: (ps) => (
          <ReportShareExpiryTag
            expiresAt={ps.row.original.expiresAt ?? null}
            isExpired={ps.row.original.isExpired}
          />
        ),
        minSize: 200,
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (ps) => (
          <Button
            variant='outline'
            size='xs'
            onClick={() => unshareReport(ps.row.original.id, ps.row.original.shareeEmail)}
            data-pw='reportShareModalUnshareButton'
          >
            Unshare
          </Button>
        ),
        minSize: 100,
      }),
    ];
  }, [unshareReport]);

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (P.isEmpty(shares)) {
    return (
      <Empty title='Report not shared with anyone' data-pw='reportShareModalDetailsPlaceholder' />
    );
  }

  return (
    <DataTable
      columns={columns}
      data={shares}
      error={error}
      isLoading={isLoading}
      size='sm'
      data-pw='reportShareModalDetailsTable'
    />
  );
};
