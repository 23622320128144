import { HStack, Text, VStack } from '@chakra-ui/react';
import { DateTime } from '@piccolohealth/util';
import React from 'react';

interface FancyDateToolTipProps {
  date: DateTime | undefined | null;
  title: string;
}

const FancyTooltipDate = (props: FancyDateToolTipProps) => {
  const { date, title } = props;

  if (!date) {
    return (
      <Text>
        <strong>No {title.toLowerCase()} found:</strong>
      </Text>
    );
  }

  const humanized = date.toRelative();
  const absolute = date.toFormat('dd/MM/yyyy - h:mm a');

  return (
    <VStack spacing={0} alignItems='flex-start'>
      <Text fontWeight='bold'>{title}</Text>
      <HStack>
        <Text display='inline'>{absolute}</Text>
        <Text color='secondary' display='inline'>
          {humanized}
        </Text>
      </HStack>
    </VStack>
  );
};

interface DateTooltipProps {
  uploadDate: DateTime;
  studyDate: DateTime | null | undefined;
}

export const DateTooltip = (props: DateTooltipProps) => {
  const { uploadDate, studyDate } = props;
  return (
    <VStack spacing={2} alignItems='flex-start'>
      <FancyTooltipDate title='Upload date' date={uploadDate} />
      <FancyTooltipDate title='Study date' date={studyDate} />
    </VStack>
  );
};
