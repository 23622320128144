import { Box, Center, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react';
import {
  Distribution,
  DistributionPayload,
  renderHealthLinkName,
  renderMedicalObjectsProviderName,
} from '@piccolohealth/echo-common';
import React from 'react';
import { useDistributionTypeInfo } from '../../../hooks/useDistributionTypeInfo';
import { DistributionStatusTag } from './DistributionStatusTag';

const getDescriptionItems = (payload: DistributionPayload) => {
  switch (payload.__typename) {
    case 'DistributionEmailPayload': {
      return [payload.shareeEmail];
    }
    case 'DistributionMedicalObjectsPayload': {
      return [
        renderMedicalObjectsProviderName(payload.to.providerDetails),
        payload.to.providerNumber,
      ];
    }
    case 'DistributionHealthLinkPayload': {
      return [renderHealthLinkName(payload.to.practitionerName), payload.to.providerNumber];
    }
    case 'DistributionFaxPayload': {
      return [payload.to.faxNumber];
    }
    default:
      return [];
  }
};

interface Props {
  distribution: Distribution;
}

export const DistributionTooltipSummary = (props: Props) => {
  const { distribution } = props;
  const { title, logo } = useDistributionTypeInfo(distribution.payload.__typename);
  const items = getDescriptionItems(distribution.payload);

  return (
    <HStack alignItems='start'>
      <Center w={7} h={7} mt={1} flexShrink={0}>
        <Image src={logo} />
      </Center>

      <Stack spacing={0}>
        <Text fontSize='sm' fontWeight='bold'>
          {title}
        </Text>
        {items.map((item, index) => (
          <Text key={`${item}-${index}`} fontSize='xs'>
            {item}
          </Text>
        ))}
      </Stack>
      <Spacer />
      <Box bg='white' rounded='md' flexShrink={0}>
        <DistributionStatusTag status={distribution.status} size='sm' display='flex' />
      </Box>
    </HStack>
  );
};
