import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { RemoveUser } from '../mutations/removeUser.mutation';
import { useUsersQuery } from './useUsersQuery';

export const useRemoveUserMutation = (
  options?: MutationOptions<Types.RemoveUserMutation, Types.RemoveUserMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(RemoveUser), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useUsersQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
    },
  });
};
