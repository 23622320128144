import { MeasurementRangeLevel } from '@piccolohealth/echo-common';

export interface MeasurementRangeColor {
  dark: string;
  light: string;
}

export const getMeasurementRangeColor = (level: MeasurementRangeLevel): MeasurementRangeColor => {
  switch (level) {
    case MeasurementRangeLevel.Level1:
      return { light: '#C6f6D5', dark: '#38A169' };
    case MeasurementRangeLevel.Level2:
      return { light: '#FEFCBF', dark: '#F6E05E' };
    case MeasurementRangeLevel.Level3:
      return { light: '#FEEBC8', dark: '#F6AD55' };
    case MeasurementRangeLevel.Level4:
      return { light: '#FED7D7', dark: '#E53E3E' };
  }
};
