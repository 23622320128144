import { Container, HStack, Stack, Text } from '@chakra-ui/react';
import { COMPANY_NAME, COMPANY_URL } from '@piccolohealth/echo-common';
import React from 'react';
import { PiccoloIcon } from '../../../components/generic/PiccoloIcon';

export const AuthCard = (props: React.PropsWithChildren<{}>) => {
  return (
    <Container maxW='md'>
      <Stack w='full' align='center' spacing={4}>
        <a href={COMPANY_URL} target='_blank' rel='noreferrer'>
          <HStack>
            <PiccoloIcon color='white' boxSize='6' />
            <Text color='white' fontWeight='bold' fontSize='xl'>
              {COMPANY_NAME}
            </Text>
          </HStack>
        </a>

        <Stack w='full' bg='white' layerStyle='bordered' px={6} py={8} spacing={6}>
          {props.children}
        </Stack>
      </Stack>
    </Container>
  );
};
