import {
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { RadioGroup } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { ControlTooltip } from './ControlTooltip';

export interface ContainerDimensions {
  isScratching: boolean;
  dx: number | undefined;
  dy: number | undefined;
  width: number | undefined;
  height: number | undefined;
}

interface Props {
  speed: number;
  onChangeSpeed: (value: number) => void;
  brightness: number;
  onChangeBrightness: (value: number) => void;
  contrast: number;
  onChangeContrast: (value: number) => void;
  toolbarPosition: 'top' | 'bottom';
  onChangeToolbarPosition: (position: 'top' | 'bottom') => void;
}

export const SettingsControl = (props: Props) => {
  const {
    speed,
    onChangeSpeed,
    brightness,
    onChangeBrightness,
    contrast,
    onChangeContrast,
    toolbarPosition,
    onChangeToolbarPosition,
  } = props;

  const reset = React.useCallback(() => {
    onChangeSpeed(1.0);
    onChangeBrightness(1.0);
    onChangeContrast(1.0);
    onChangeToolbarPosition('top');
  }, [onChangeSpeed, onChangeBrightness, onChangeContrast, onChangeToolbarPosition]);

  return (
    <Popover isLazy>
      <ControlTooltip label='Settings'>
        <PopoverTrigger>
          <IconButton
            aria-label='Settings'
            icon={<FaCog />}
            size='sm'
            fontSize='xl'
            variant='imageViewer'
            colorScheme='purple'
          />
        </PopoverTrigger>
      </ControlTooltip>
      <PopoverContent bg='gray.700' borderColor='gray.700' userSelect='none'>
        <PopoverBody w='full' py={4}>
          <Stack w='full' fontWeight='semibold' spacing={4} color='white'>
            <Stack spacing={1}>
              <HStack>
                <Text>Speed</Text>
                <Spacer />
                <Text>{P.round(speed * 100, 0)}%</Text>
              </HStack>
              <Slider
                size='lg'
                colorScheme='purple'
                value={speed}
                min={0.1}
                max={3.0}
                step={0.1}
                onChange={(value) => onChangeSpeed(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Stack>

            <Stack spacing={1}>
              <HStack>
                <Text>Brightness</Text>
                <Spacer />
                <Text>{P.round(brightness * 100, 0)}%</Text>
              </HStack>
              <Slider
                size='lg'
                colorScheme='purple'
                value={brightness}
                min={0.1}
                max={3.0}
                step={0.1}
                onChange={(value) => onChangeBrightness(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Stack>

            <Stack spacing={1}>
              <HStack>
                <Text>Contrast</Text>
                <Spacer />
                <Text>{P.round(contrast * 100, 0)}%</Text>
              </HStack>
              <Slider
                size='lg'
                colorScheme='purple'
                value={contrast}
                min={0.1}
                max={3.0}
                step={0.1}
                onChange={(value) => onChangeContrast(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Stack>

            <Stack spacing={1}>
              <HStack>
                <Text>Toolbar position</Text>
              </HStack>
              <RadioGroup
                value={toolbarPosition}
                onChange={(value) => onChangeToolbarPosition(value as 'top' | 'bottom')}
                options={[
                  {
                    label: 'Top',
                    value: 'top',
                  },
                  {
                    label: 'Bottom',
                    value: 'bottom',
                  },
                ]}
                color='initial'
              />
            </Stack>
          </Stack>
          <Flex mt={4} justify='end' w='full'>
            <Button size='xs' colorScheme='purple' variant='solid' onClick={reset}>
              Reset
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
