import { Box, Divider, HStack, Heading, Stack, Text } from '@chakra-ui/react';
import {
  Machine,
  OrganizationMachineStats,
  ReportTemplate,
  ReportTemplateDryRun,
} from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import { inflection } from '@piccolohealth/util';
import React from 'react';
import { MachineTag } from '../../../features/dicom/components/mappings/MachineTag';
import { useOrganizationMachineStatsQuery } from '../../../graphql/hooks/useMachineStatsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportTemplateMeasurementsTransferList } from './ReportTemplateMeasurementsTransferList';

interface Props {
  reportTemplate: ReportTemplate;
  dryRun?: ReportTemplateDryRun;
}

export const ReportTemplateMeasurementsEditor = (props: Props) => {
  const { reportTemplate, dryRun } = props;

  const { organization } = useAppContext();

  const machineStatsQuery = useOrganizationMachineStatsQuery({
    organizationId: organization.id,
  });

  const machineStats =
    machineStatsQuery.data?.organization?.machineStats ?? ([] as OrganizationMachineStats[]);
  const machines = machineStats.map((machineStats) => machineStats.machine);

  if (machineStatsQuery.isLoading) {
    return <Spin />;
  }

  return (
    <Stack h='full' w='full' px={4} py={6} spacing={4}>
      <Stack spacing={4}>
        <Heading size='md' w='full'>
          Machine Stats
        </Heading>
        <HStack spacing={4}>
          {machineStats.map((machineStats: OrganizationMachineStats) => {
            return (
              <Box key={machineStats.machine.model}>
                <MachineTag machine={machineStats.machine as Machine} />
                <Text fontSize='md' fontWeight='semibold'>
                  {machineStats.count} {inflection.inflect('report', machineStats.count)}
                </Text>
                <Text fontSize='sm' fontWeight='normal'>
                  {machineStats.percentage}%
                </Text>
              </Box>
            );
          })}
        </HStack>
      </Stack>
      <Divider />
      <Stack minH='0' h='full'>
        <ReportTemplateMeasurementsTransferList
          reportTemplate={reportTemplate}
          machines={machines}
          dryRun={dryRun}
        />
      </Stack>
    </Stack>
  );
};
