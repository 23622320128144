import { Box, Button, Collapse, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { QRCode, Spin } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedPinInput } from '../../../components/forms/hookform/HookedPinInput';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { useAuth } from '../../../context/AuthContext';
import { TotpEnrolChallenge } from '../../../context/AuthContext';
import { AuthCard } from './AuthCard';
import { AuthLayout } from './AuthLayout';
import { MFAChooseMethodButton } from './MFAChooseMethodButton';

type FormValues = {
  verificationCode: string;
};

const schema: Yup.SchemaOf<FormValues> = Yup.object({
  verificationCode: Yup.string()
    .required('Verification code is required')
    .length(6, 'Verification code must be 6 characters'),
});

export const TotpEnrolCard = () => {
  const auth = useAuth();

  const [totpSecret, setTotpSecret] = React.useState<TotpEnrolChallenge | null>(null);
  const showSecretDisclosure = useDisclosure();

  const methods = useForm<FormValues>({
    defaultValues: {
      verificationCode: '',
    },
    resolver: yupResolver(schema),
  });

  const setQrCode = React.useCallback(async () => {
    if (totpSecret) {
      return;
    }

    try {
      const totpSecret = await auth.generateTotpEnrolCode();
      setTotpSecret(totpSecret);
    } catch (err) {
      return auth.logout();
    }
  }, [auth, setTotpSecret, totpSecret]);

  React.useEffect(() => {
    setQrCode();
  }, [auth, setQrCode, setTotpSecret, totpSecret]);

  const onVerifyCode = async (values: FormValues) => {
    if (!totpSecret) {
      return;
    }

    return auth.verifyTotpEnrolmentCode(totpSecret, values.verificationCode).catch(() => {
      methods.setError('verificationCode', {
        message: 'Invalid verification code',
      });
      methods.resetField('verificationCode', {
        keepError: true,
      });
    });
  };

  if (auth.isLoading) {
    return (
      <AuthLayout>
        <Spin />
      </AuthLayout>
    );
  }

  if (!auth.isLoggedIn) {
    return <Navigate to='/login' />;
  }

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Secure Your Account
          </Text>
          <Text fontSize='sm' color='secondary'>
            Scan the QR Code below using your preferred authenticator app and then enter the
            provided one-time code below.
          </Text>
        </Stack>
        <Stack spacing={8}>
          <Stack align='center' w='full' spacing={2}>
            <Box h={48} w={48}>
              {totpSecret ? (
                <QRCode
                  value={totpSecret.uri}
                  size={256}
                  bgColor='transparent'
                  style={{
                    height: 'auto',
                    maxWidth: '100%',
                    width: '100%',
                  }}
                />
              ) : (
                <Skeleton w='full' h='full' />
              )}
            </Box>
            <Stack spacing={2} textAlign='center'>
              <Button variant='link' onClick={showSecretDisclosure.onToggle} size='xs'>
                Trouble scanning?
              </Button>
              <Collapse in={showSecretDisclosure.isOpen} animateOpacity>
                <Text fontSize='xs' userSelect='text'>
                  <strong>Secret Key</strong>:{' '}
                  <span data-secret-key={totpSecret?.secret?.secretKey}>
                    {totpSecret?.secret?.secretKey}
                  </span>
                </Text>
              </Collapse>
            </Stack>
          </Stack>

          <FormProvider {...methods}>
            <Stack as='form' spacing={4} onSubmit={methods.handleSubmit(onVerifyCode)}>
              <HookedFormItem name='verificationCode'>
                <HookedPinInput name='verificationCode' fieldCount={6} size='lg' />
              </HookedFormItem>
              <HookedSubmitButton size='md' mt={4} isDisabled={false} loadingText='Verifying...'>
                Verify
              </HookedSubmitButton>
            </Stack>
          </FormProvider>
        </Stack>
        <MFAChooseMethodButton />
      </AuthCard>
    </AuthLayout>
  );
};
