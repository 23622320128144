import { Stack, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import { FaLock, FaSms } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AuthCard } from './components/AuthCard';
import { AuthLayout } from './components/AuthLayout';
import { MFATypeButton } from './components/MFATypeButton';

export const MFAEnrol = () => {
  const navigate = useNavigate();

  const onEnrolTOTP = () => {
    navigate('/mfa/totp-enrol');
  };

  const onEnrolPhone = () => {
    navigate('/mfa/phone-enrol');
  };

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Keep your account safe
          </Text>
          <Text fontSize='sm' color='secondary'>
            Add another authentication method.
          </Text>
        </Stack>
        <Stack spacing={4}>
          <MFATypeButton
            title='Authenticator app'
            description='Use an authenticator app to generate codes'
            icon={FaLock}
            onClick={onEnrolTOTP}
            badge={
              <Tag size='sm' colorScheme='green' fontSize='2xs'>
                Recommended
              </Tag>
            }
          />
          <MFATypeButton
            title='SMS'
            description='Receive a code via phone text message'
            icon={FaSms}
            onClick={onEnrolPhone}
          />
        </Stack>
      </AuthCard>
    </AuthLayout>
  );
};
