import * as Types from '@piccolohealth/echo-common';
import { Report } from '@piccolohealth/echo-common';
import { RowSelectionOptions } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { ReportsFilter } from '../../hooks/useReportsFilter';
import { createGqlQuery, createPaginatedGqlQuery } from '../fetcher';
import { Reports } from '../queries/reports.query';

export const getReportsBaseKey = (variables: Types.ReportsQueryVariables) => ['reports', variables];

export const useReportsQuery = createGqlQuery<Types.ReportsQueryVariables, Types.ReportsQuery>(
  getReportsBaseKey,
  Reports,
);

export const useReportsFilterQuery = (filter: ReportsFilter) => {
  const { organization } = useAppContext();
  const [selectedRows, onRowsSelect] = React.useState<Report[]>([]);

  const query = createPaginatedGqlQuery<Types.ReportsQueryVariables, Types.ReportsQuery>(
    getReportsBaseKey,
    Reports,
    {
      filter,
      getPaginationResponse: (response) => {
        return response?.organization?.reports?.pagination;
      },
    },
  );

  const rowSelection: RowSelectionOptions<Report> = {
    selectedRows,
    onRowsSelect,
  };

  const resp = query({
    organizationId: organization.id,
    getReportsRequest: {
      pagination: {
        offset: filter.currentPageNumber * filter.pageSize,
        limit: filter.pageSize,
      },
      statuses: filter.reportStatusesFilter,
      types: filter.reportTypesFilter,
      name: filter.reportPatientNameFilter,
      dob: filter.reportPatientDobFilter ? filter.reportPatientDobFilter : null,
      assignedTo: filter.assignedToFilter,
      labels: filter.labelsFilter,
      start: filter.reportStartDateFilter ? filter.reportStartDateFilter : null,
      end: filter.reportEndDateFilter ? filter.reportEndDateFilter : null,
    },
  });

  const reports = (resp.data?.organization?.reports?.reports as Report[]) ?? [];

  return {
    ...resp,
    reports,
    rowSelection,
  };
};
