import { PiccoloError, renderPiccoloError } from '@piccolohealth/echo-common';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { sendExceptionToSentry } from '../../utils/errors';

interface Props extends ChakraV3.ErrorProps {
  error: PiccoloError;
}

export const Error = (props: Props) => {
  const { error, ...rest } = props;

  const rendered = renderPiccoloError(error);

  if (rendered.type === 'UnknownError') {
    sendExceptionToSentry(error);
  }

  if (error.type === 'AuthUnableToVerifyToken') {
    return <Navigate to='/logout' />;
  }

  return <ChakraV3.Error title={rendered.type} description={rendered.message} {...rest} />;
};
