import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LooseObject, OrganizationMembership, User } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateUserMutation } from '../../graphql/hooks/useUpdateUserMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { FormSection } from '../forms/FormSection';
import { HookedFormItem } from '../forms/hookform/HookedFormItem';
import { HookedInput } from '../forms/hookform/HookedInput';
import { HookedSubmitButton } from '../forms/hookform/HookedSubmitButton';
import { createModal } from '../generic/Modal';
import { UserRolesSelectionTable } from '../settings/users/UserRolesSelectionTable';

interface FormValues {
  name: string;
  email: string;
  roleIds: string[];
}

interface Props {
  user: User;
}

export const UserEditModal = createModal<Props>((props) => {
  const { user, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const userOrganizationMembership = user.organizationMemberships.find((organizationMembership) => {
    return organizationMembership.organizationId === organization.id;
  });

  const initialValues = {
    name: user.name,
    email: user.email,
    roleIds: (userOrganizationMembership?.roles ?? []).map((role) => role.id),
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    roleIds: Yup.array().min(1, 'At least one role required').required('Required'),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useUpdateUserMutation();

  const onSubmit = async (values: Partial<FormValues>) => {
    const updateUserRequest = Object.entries({
      name: values.name,
      email: values.email,
      roleIds: values.roleIds,
    }).reduce((acc: LooseObject, [key, value]) => {
      return !P.isEqual(value, (initialValues as LooseObject)[key])
        ? { ...acc, [key]: value }
        : acc;
    }, {});
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        userId: user.id,
        updateUserRequest,
      })
      .then(() => {
        successToast('User edited successfully');
      })
      .catch((err) => {
        errorToast(`Error editing user: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-pw='userEditModalHeader'>Edit user</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection>
                <HookedFormItem label='Name' name='name' data-pw='userEditModalNameFormItem'>
                  <HookedInput name='name' data-pw='userEditModalNameInput' />
                </HookedFormItem>
                <HookedFormItem label='Email' name='email' data-pw='userEditModalEmailFormItem'>
                  <HookedInput name='email' data-pw='userEditModalEmailInput' />
                </HookedFormItem>
                <HookedFormItem label='Roles' name='roleIds' data-pw='userEditModalRolesFormItem'>
                  <UserRolesSelectionTable name='roleIds' />
                </HookedFormItem>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={modal.hide} data-pw='userEditModalCloseButton'>
                  Close
                </Button>
                <HookedSubmitButton key='submit' data-pw='userEditModalSubmitButton'>
                  Save
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
