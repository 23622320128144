import { Element as CraftJsElement } from '@craftjs/core';
import { P } from '@piccolohealth/util';
import _ from 'lodash';
import React from 'react';
import { renderNode } from '../../../utils/craftjs';
import { useSSREditor } from '../hooks/useSSREditor';
import { RESOLVERS } from '../report/helpers';

export declare type ElementProps<T extends React.ElementType> = {
  id?: string;
  is?: T;
  custom?: Record<string, any>;
  children?: React.ReactNode;
  canvas?: boolean;
} & React.ComponentProps<T>;

export function Element<T extends React.ElementType>(props: ElementProps<T>) {
  const editor = useSSREditor(props);

  if (P.isEmptyObject(editor)) {
    //  TODO: fix types for linked and id from any
    const resolvedLinkedNodeId = _.get(props.linked, props.id);
    return renderNode(props.nodes, RESOLVERS, resolvedLinkedNodeId, props.ssr);
  }

  return <CraftJsElement {...props} />;
}
