import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export const Placeholder = () => {
  return (
    <Box
      alignItems='center'
      justifyContent='center'
      borderWidth='2px'
      borderRadius='2px'
      borderStyle='dashed'
      borderColor='gray.400'
      padding={2}
    >
      <Text fontSize='sm' color='gray.400' fontWeight='bold' textAlign='center'>
        Drag any element into this area.
      </Text>
    </Box>
  );
};
