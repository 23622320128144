import { Divider, Stack, Tag, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import { P, inflection } from '@piccolohealth/util';
import _ from 'lodash';
import React from 'react';

interface Props extends TextProps {
  value: any;
  reportTemplateVariable: ReportTemplateVariable;
  template: string;
  capitalize: boolean;
  showChoices: boolean;
}

export const HighlightedVariableValue = (props: Props) => {
  const { value, reportTemplateVariable, template, capitalize, showChoices, ...rest } = props;

  const showPlaceholder = _.isNil(value) || (_.isArrayLike(value) && _.isEmpty(value));

  const rendered = showPlaceholder ? (
    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
  ) : (
    <>{capitalize ? P.capitalize(template) : template}</>
  );

  const label = P.run(() => {
    if (!showChoices) {
      return reportTemplateVariable.label;
    }

    switch (reportTemplateVariable.__typename) {
      case 'ReportTemplateChoiceVariable': {
        return (
          <Stack align='start'>
            <Stack spacing={0}>
              <Text fontWeight='semibold'>{reportTemplateVariable.label}</Text>
              <Text fontSize='xs'>
                {reportTemplateVariable.choices.length}{' '}
                {inflection.inflect('choices', reportTemplateVariable.choices.length)}
              </Text>
            </Stack>

            <Divider />
            {reportTemplateVariable.choices.map((choice) => (
              <Tag key={choice.id} size='sm' colorScheme={choice.default ? 'green' : 'gray'}>
                {choice.value}
              </Tag>
            ))}
          </Stack>
        );
      }
      default: {
        return reportTemplateVariable.label;
      }
    }
  });

  return (
    <Tooltip label={label} p={2} bg='gray.700' color='white' openDelay={500}>
      <Text
        as='span'
        variant='highlighted'
        cursor='pointer'
        zIndex={10}
        userSelect='text'
        {...rest}
      >
        {rendered}
      </Text>
    </Tooltip>
  );
};
