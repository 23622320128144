import { Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const BackToLoginButton = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/login');
  };

  return (
    <Button variant='link' w='fit-content' cursor='pointer' onClick={onClick}>
      Back to login
    </Button>
  );
};
