import { Image as ChakraImage } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { ReportVariable } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { useSSRNode } from '../../hooks/useSSRNode';
import { NodeSizeProps, NodeSizeSettings } from '../nodes/NodeSize';
import { SettingsAccordion } from '../settings/SettingsAccordion';
import { SettingsItem } from '../settings/SettingsItem';
import { VariableChooser } from '../settings/VariableChooser';

export interface Props extends BaseNodeProps, NodeSizeProps {
  variableId: string | null;
}

export const Image = (props: Props) => {
  const { variableId, ssr, nodes, ...rest } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const variable = useWatch({ name: `variables.${variableId}` }) as ReportVariable;

  const cleanedProps = P.pickBy(rest, (item) => !P.isUndefined(item));

  return (
    <ChakraImage
      ref={(ref) => connect(drag(ref))}
      objectFit='contain'
      src={variable?.value}
      fallbackSrc={variable?.value}
      {...cleanedProps}
    />
  );
};

const ImageSettings = () => {
  const {
    actions: { setProp },
    variableId,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Image'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value))}
          />
        </FormItem>
      </SettingsItem>

      <SettingsItem title='Size'>
        <NodeSizeSettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

Image.defaultProps = {
  variableId: 'logo',
  ...NodeSizeSettings.defaultProps,
};

Image.craft = {
  name: 'Image',
  props: Image.defaultProps,
  related: {
    settings: ImageSettings,
  },
};
