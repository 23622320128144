import { Badge, BadgeProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends BadgeProps {
  count: number;
}

export const NumberedBadge = (props: Props) => {
  const { count, ...rest } = props;

  const colorScheme = count > 0 ? 'purple' : 'gray';

  return (
    <Badge borderRadius='full' px='2' colorScheme={colorScheme} {...rest}>
      {count}
    </Badge>
  );
};
