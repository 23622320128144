import { Button, ButtonGroup, Icon, Kbd, Text } from '@chakra-ui/react';
import { Invoice, PiccoloError, renderCurrency } from '@piccolohealth/echo-common';
import { DataTable, FancyDate, createColumnHelper } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FaCreditCard, FaDownload } from 'react-icons/fa';
import { InvoicePeriod } from './InvoicePeriod';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import { InvoiceSubscriptionItemsControl } from './InvoiceSubscriptionItemsControl';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null | undefined;
  invoices: Invoice[];
}

export const InvoicesTable = (props: Props) => {
  const { isLoading, error, invoices } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Invoice>();

    return [
      columnHelper.display({
        header: 'Number',
        maxSize: 200,
        cell: (ps) => {
          return <Kbd fontSize='md'>{ps.row.original.number}</Kbd>;
        },
      }),

      columnHelper.display({
        header: 'Amount',
        maxSize: 100,
        cell: (ps) => {
          return (
            <Text fontWeight='semibold'>{renderCurrency(ps.row.original.amountDue / 100)}</Text>
          );
        },
      }),
      columnHelper.display({
        header: 'Period',
        maxSize: 400,
        cell: (ps) => {
          return (
            <InvoicePeriod
              periodStart={ps.row.original.periodStart}
              periodEnd={ps.row.original.periodEnd}
            />
          );
        },
      }),
      columnHelper.display({
        header: 'Status',
        maxSize: 200,
        cell: (ps) => {
          return <InvoiceStatusTag status={ps.row.original.status ?? undefined} />;
        },
      }),
      columnHelper.display({
        header: 'Usage',
        maxSize: 300,
        cell: (ps) => {
          return <InvoiceSubscriptionItemsControl invoice={ps.row.original} />;
        },
      }),

      columnHelper.display({
        header: 'Due',
        maxSize: 300,
        cell: (ps) => {
          return ps.row.original.due ? (
            <FancyDate date={DateTime.fromISO(ps.row.original.due as unknown as string)} />
          ) : (
            <Text>-</Text>
          );
        },
      }),

      columnHelper.display({
        header: 'Action',
        cell: (ps) => {
          return (
            <ButtonGroup>
              {ps.row.original.pdfDownloadUrl && (
                <Button
                  leftIcon={<Icon as={FaDownload} />}
                  onClick={() => window.open(ps.row.original.pdfDownloadUrl || '', '_blank')}
                >
                  Download PDF
                </Button>
              )}
              {ps.row.original.status === 'open' && (
                <Button
                  leftIcon={<Icon as={FaCreditCard} />}
                  colorScheme='purple'
                  onClick={() => window.open(ps.row.original.hostedPaymentUrl || '', '_blank')}
                >
                  Pay now
                </Button>
              )}
            </ButtonGroup>
          );
        },
      }),
    ];
  }, []);

  return (
    <DataTable columns={columns} isLoading={isLoading} error={error} data={invoices} size='sm' />
  );
};
