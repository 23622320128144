import { Box, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';

interface Props {
  onRemove: () => void;
  isDisabled?: boolean;
}

export const RemoveButton = (props: Props) => (
  <Box
    cursor='pointer'
    onClick={() => !props.isDisabled && props.onRemove()}
    _hover={{ opacity: 0.8 }}
  >
    <Icon as={FaRegTrashAlt} fontSize='sm' aria-label='Remove' />
  </Box>
);
