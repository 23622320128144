import * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import { BillingActions, Invoice, Invoices, SubscriptionItems } from '../queries/billing.query';

export const useSubscriptionItemsQuery = createGqlQuery<
  Types.SubscriptionItemsQueryVariables,
  Types.SubscriptionItemsQuery
>((variables) => ['billing', 'subscriptionItems', variables], SubscriptionItems);

export const useBillingActionsQuery = createGqlQuery<
  Types.BillingActionsQueryVariables,
  Types.BillingActionsQuery
>((variables) => ['billing', 'actions', variables], BillingActions);

export const useInvoicesQuery = createGqlQuery<Types.InvoicesQueryVariables, Types.InvoicesQuery>(
  (variables) => ['billing', 'invoices', variables],
  Invoices,
);

export const useInvoiceQuery = createGqlQuery<Types.InvoiceQueryVariables, Types.InvoiceQuery>(
  (variables) => ['billing', 'invoice', variables],
  Invoice,
);
