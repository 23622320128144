import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteMeasurementMapping } from '../mutations/deleteMeasurementMapping.mutation';
import { getMeasurementMappingsAndVariantsBaseKey } from './useMeasurementMappingsAndVariantsQuery';

export const useDeleteMeasurementMappingMutation = (
  options?: MutationOptions<
    Types.DeleteMeasurementMappingMutation,
    Types.DeleteMeasurementMappingMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteMeasurementMapping), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries(getMeasurementMappingsAndVariantsBaseKey({}));
    },
  });
};
