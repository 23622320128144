import { BoxProps, Center, Icon } from '@chakra-ui/react';
import { getExtension } from '@piccolohealth/util';
import React from 'react';
import {
  FaFile,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileVideo,
  FaFileWord,
} from 'react-icons/fa';

export const getIcon = (name: string) => {
  const extension = getExtension(name);

  switch (extension) {
    case 'pdf':
      return { icon: FaFilePdf, color: 'red.500', bg: 'red.50' };
    case 'csv':
      return { icon: FaFileCsv, color: 'green.500', bg: 'green.50' };
    case 'xls':
    case 'xlsx':
      return { icon: FaFileExcel, color: 'green.500', bg: 'green.50' };
    case 'doc':
    case 'docx':
      return { icon: FaFileWord, color: 'blue.500', bg: 'blue.50' };
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return { icon: FaFileImage, color: 'orange.500', bg: 'orange.50' };
    case 'mp4':
    case 'avi':
    case 'wmv':
    case 'mov':
    case 'mkv':
      return { icon: FaFileVideo, color: 'purple.500', bg: 'purple.50' };
    default:
      return { icon: FaFile, color: 'gray.500', bg: 'gray.50' };
  }
};

interface Props extends BoxProps {
  name: string;
}

export const FileIcon = (props: Props) => {
  const { name, ...rest } = props;
  const { icon, color, bg } = getIcon(name);

  return (
    <Center w={16} h={16} p={1} rounded='xl' bg={bg} {...rest}>
      <Icon as={icon} boxSize='70%' color={color} />
    </Center>
  );
};
