import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { RemoveReportLabel } from '../mutations/removeReportLabel.mutation';

export const useRemoveReportLabelMutation = (
  options?: MutationOptions<
    Types.RemoveReportLabelMutation,
    Types.RemoveReportLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(RemoveReportLabel), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('reports') || query.queryKey.includes('report'),
      });
    },
  });
};
