import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  MeasurementMapping,
  ReportTemplate,
  ReportTemplateStaticVariable,
  ReportTemplateVariable,
  ReportTemplateVariableSource,
  VariableControlType,
  renderReportTemplateVariableType,
  uuid,
} from '@piccolohealth/echo-common';
import { Select, SelectOption } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAddReportTemplateVariableMutation } from '../../../graphql/hooks/useAddReportTemplateVariableMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormItem } from '../../forms/FormItem';
import { FormSection } from '../../forms/FormSection';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedSelect } from '../../forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../../forms/hookform/HookedSwitch';
import { createModal } from '../../generic/Modal';
import { ReportTemplateVariableTypeControl } from './ReportTemplateVariableTypeControl';

type FormValues = ReportTemplateVariable;

const ReportTemplateVariableBaseChooser = (props: { variables: ReportTemplateVariable[] }) => {
  const { variables } = props;
  const { setValue, reset } = useFormContext();

  const [variable, setVariable] = React.useState<ReportTemplateVariable | undefined>(undefined);

  React.useEffect(() => {
    if (variable) {
      Object.entries(variable).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      reset();
    }
  }, [setValue, reset, variable]);

  const options: SelectOption<ReportTemplateVariable>[] = P.orderBy(variables, (v) => v.id).map(
    (variable) => {
      return {
        value: variable.id,
        label: variable.id,
        raw: variable,
      };
    },
  );

  const onChange = React.useCallback((option: SelectOption<ReportTemplateVariable>) => {
    return setVariable(option.raw);
  }, []);

  const selectedValue: SelectOption<ReportTemplateVariable> | null = React.useMemo(() => {
    if (!variable) {
      return null;
    }

    return {
      value: variable.id,
      label: variable.id,
      raw: variable,
    };
  }, [variable]);

  return (
    <FormItem id='baseVariableId' label='Variable base'>
      <Select options={options} onChange={onChange} value={selectedValue} />
    </FormItem>
  );
};

interface Props {
  reportTemplate: ReportTemplate;
}

export const ReportTemplateVariableAddModal = createModal<Props>((props) => {
  const { reportTemplate, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const initialValues: ReportTemplateStaticVariable = {
    __typename: 'ReportTemplateStaticVariable',
    id: uuid(),
    source: ReportTemplateVariableSource.Static,
    label: '',
    site: '',
    category: '',
    controlType: VariableControlType.Input,
    units: null,
    precision: null,
    defaultValue: null,
    isEditable: true,
    mappings: [],
  };

  const methods = useForm({
    defaultValues: initialValues as any,
  });

  const mutation = useAddReportTemplateVariableMutation();

  const onSubmit = async (values: Partial<FormValues>) => {
    if (values.__typename === 'ReportTemplateChoiceVariable') {
      values.defaultValue =
        (values.choices ?? []).find((choice) => choice.default === true)?.value ?? null;
    }

    values.mappings = values.mappings?.map((id) => ({ id })) as unknown as MeasurementMapping[];

    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportTemplateId: reportTemplate.id,
        request: values,
      })
      .then(() => {
        successToast('Variable added successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error adding variable: ${err.message}`);
      });
  };

  const types: NonNullable<ReportTemplateVariable['__typename']>[] = [
    'ReportTemplateStaticVariable',
    'ReportTemplateChoiceVariable',
    'ReportTemplateWallMotionVariable',
    'ReportTemplateMediaAttachmentVariable',
  ];

  const typeOptions = types.map((type) => ({
    value: type,
    label: renderReportTemplateVariableType(type),
    raw: type,
  }));

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add variable</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection>
                <ReportTemplateVariableBaseChooser variables={reportTemplate.variables} />
                <HookedFormItem label='ID' name='id'>
                  <HookedInput name='id' />
                </HookedFormItem>
                <HookedFormItem label='Label' name='label'>
                  <HookedInput name='label' />
                </HookedFormItem>
                <HookedFormItem label='Site' name='site'>
                  <HookedInput name='site' />
                </HookedFormItem>
                <HookedFormItem label='Category' name='category'>
                  <HookedInput name='category' />
                </HookedFormItem>
                <HookedFormItem label='Editable' name='isEditable'>
                  <HookedSwitch name='isEditable' />
                </HookedFormItem>
                <HookedFormItem label='Type' name='__typename'>
                  <HookedSelect name='__typename' options={typeOptions} />
                </HookedFormItem>
                <ReportTemplateVariableTypeControl />
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
