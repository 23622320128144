import { Button, Flex, FlexProps, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { Card } from '../../generic/Card';
import { Divider } from '../../generic/Divider';
import { IntegrationStatus } from './IntegrationStatus';

interface Props extends FlexProps {
  title: string;
  description: string;
  logo: string;
  isDisabled?: boolean;
  isIntegrationEnabled: boolean;
  onEditIntegration: () => void;
}

export const IntegrationCard = (props: Props) => {
  const { title, description, logo, isDisabled, isIntegrationEnabled, onEditIntegration, ...rest } =
    props;

  return (
    <Card p={4} {...rest} h='3xs'>
      <Stack h='full'>
        <IntegrationStatus
          title={title}
          description={description}
          logo={logo}
          isIntegrationEnabled={isIntegrationEnabled}
        />
        <Spacer />
        <Divider />
        <Flex align='end' flexDir='column'>
          <Button variant='ghost' colorScheme='purple' onClick={onEditIntegration}>
            Edit integration
          </Button>
        </Flex>
      </Stack>
    </Card>
  );
};
