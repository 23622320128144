import { Icon, MenuItem } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { ReportShareModal } from '../modals/ReportShareModal';

interface Props {
  reportId: string;
}

export const ReportShareMenuItem = (props: Props) => {
  const { reportId } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsShare).value;

  return (
    <MenuItem
      key='share'
      isDisabled={!hasPermission}
      onClick={() => showModal(ReportShareModal, { reportId })}
      icon={<Icon as={FaShareAlt} />}
      data-pw='reportShareMenuItem'
    >
      Share
    </MenuItem>
  );
};
