import { RadioStack, RadioStackProps } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface HookedRadioStackProps extends Omit<RadioStackProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedRadioStack = (props: HookedRadioStackProps) => {
  const { name, options, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <RadioStack options={options} {...inputProps} {...rest} />;
};
