import { Box, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from './PageHeader';

interface Props extends Omit<FlexProps, 'title'> {
  title?: JSX.Element | string;
  headerContent?: JSX.Element;
  leftExtraContent?: JSX.Element;
  rightExtraContent?: JSX.Element;
}

export const Content = (props: React.PropsWithChildren<Props>) => {
  const { headerContent, leftExtraContent, rightExtraContent, title, children, ...rest } = props;

  return (
    <Flex flexDirection='column' h='100%' {...rest}>
      <Box px={4} pt={4} pb={1}>
        <PageHeader
          title={title}
          leftExtraContent={leftExtraContent}
          rightExtraContent={rightExtraContent}
        >
          {headerContent}
        </PageHeader>
      </Box>
      <Box h='100%' minH='0'>
        {children}
      </Box>
    </Flex>
  );
};
