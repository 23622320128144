import { Checkbox, CheckboxGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { FeatureFlag } from '@piccolohealth/echo-common';
import { inflection } from '@piccolohealth/util';
import React from 'react';

interface Props {
  value: FeatureFlag[];
  onChange: (value: FeatureFlag[]) => void;
}

export const FeatureFlagSwitches = (props: Props) => {
  const { value, onChange } = props;

  return (
    <Stack>
      <CheckboxGroup value={value} onChange={onChange}>
        {Object.entries(FeatureFlag).map(([key, flag]) => (
          <HStack key={key} spacing={2}>
            <Checkbox value={flag} />
            <Text fontSize='sm'>{inflection.titleize(flag)}</Text>
          </HStack>
        ))}
      </CheckboxGroup>
    </Stack>
  );
};
