import { Box, Button, Code, HStack, Text } from '@chakra-ui/react';
import { useEditor } from '@craftjs/core';
import React from 'react';
import { useKey } from 'react-use';
import { Divider } from '../../generic/Divider';

export const Settings = () => {
  const { actions, selected } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable:
          query.node(currentNodeId).isDeletable() &&
          (query.node(currentNodeId).get().data.props.deletable ?? true),
      };
    }

    return {
      selected,
    };
  });

  const deleteNode = () => {
    if (selected && selected.isDeletable) {
      actions.delete(selected.id);
    }
  };

  useKey('Delete', deleteNode, undefined, [selected]);

  if (!selected) {
    return <Text color='gray'>Click on a component to start editing.</Text>;
  }

  return (
    <Box>
      <Box>{selected.settings && React.createElement(selected.settings)}</Box>
      <Divider />
      <HStack>
        {selected.isDeletable ? (
          <Button colorScheme='red' onClick={deleteNode} size='sm'>
            Delete
          </Button>
        ) : null}
        <Code>{selected.id}</Code>
      </HStack>
      <Divider />
    </Box>
  );
};
