import NiceModal from '@ebay/nice-modal-react';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminNavbar } from './AdminNavbar';

export const AdminLayout = () => {
  return (
    <NiceModal.Provider>
      <ChakraV3.Flex h='full' flexDir='column'>
        <AdminNavbar />
        <ChakraV3.Box h='full' flexGrow={1} overflowY='auto' bg='white'>
          <Outlet />
        </ChakraV3.Box>
      </ChakraV3.Flex>
    </NiceModal.Provider>
  );
};
