import React from 'react';
import * as Konva from 'react-konva';
import { MeasurementCrosshair } from './MeasurementCrosshair';
import { MeasurementText } from './MeasurementText';
import { UseAreaToolReturn } from './hooks/useAreaTool';

interface Props {
  tool: UseAreaToolReturn;
}

export const MeasurementArea = (props: Props) => {
  const { tool } = props;

  const { polygon, isDrawing, text } = tool;

  if (!polygon) {
    return null;
  }

  const strokeColor = isDrawing ? 'red' : 'yellow';
  const fillColor = isDrawing ? 'rgba(255,0,0,0.05)' : 'rgba(255,255,0,0.05)';

  const [start, end] = [polygon.points[0], polygon.points[polygon.points.length - 1]];

  return (
    <>
      <Konva.Line
        points={polygon.points.flatMap((p) => [p.x, p.y])}
        stroke={strokeColor}
        strokeWidth={1}
        dash={[4, 2]}
        closed={true}
        fill={fillColor}
      />
      {isDrawing && (
        <>
          <MeasurementCrosshair position={start} color={strokeColor} gap={3} size={5} />
          <MeasurementCrosshair position={end} color={strokeColor} gap={3} size={5} />
        </>
      )}
      {text && <MeasurementText value={text.value} position={text.position} color={strokeColor} />}
    </>
  );
};
