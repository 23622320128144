import { HStack, MenuItem, Spacer, Text } from '@chakra-ui/react';
import { PERMISSIONS, ReportStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { DistributionsSendBadge } from '../../features/distributions/components/DistributionsSendBadge';
import { DistributionSendModal } from '../../features/distributions/components/DistributionsSendModal';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';

interface Props {
  reportId: string;
  reportStatus: ReportStatus;
}

export const ReportDistributionsSendMenuItem = (props: Props) => {
  const { reportId, reportStatus } = props;
  const hasSendPermission = usePermission(PERMISSIONS.distributionSend).value;
  const hasReadPermission = usePermission(PERMISSIONS.distributionRead).value;

  const isDisabled = !hasSendPermission || reportStatus !== ReportStatus.Finalized;

  const onClick = React.useCallback(() => {
    showModal(DistributionSendModal, {
      reportId,
    });
  }, [reportId]);

  return (
    <MenuItem
      icon={<FaPaperPlane />}
      isDisabled={isDisabled}
      data-pw='reportSendDistributionsMenuItem'
      onClick={onClick}
    >
      <HStack>
        <Text>Send distributions</Text>
        <Spacer />
        {hasReadPermission && <DistributionsSendBadge reportId={reportId} />}
      </HStack>
    </MenuItem>
  );
};
