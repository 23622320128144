import { Flex, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { WallMotionModule } from '@piccolohealth/echo-common';
import { Dot } from '@piccolohealth/ui';
import React from 'react';

interface Props {
  module: WallMotionModule;
}

export const HorizontalWallMotionLegend = (props: Props) => {
  const { module } = props;
  return (
    <Wrap>
      {Object.values(module.scores).map(({ text, number, color }, index) => {
        return (
          <WrapItem key={`${text}-${index}`}>
            <HStack overflow='hidden' white-space='nowrap'>
              <Dot size='8px' bg={color} />
              <Text fontSize='sm'>
                {text} {number ? `(${number})` : `(blank)`}
              </Text>
            </HStack>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

export const VerticalWallMotionLegend = (props: Props) => {
  const { module } = props;

  return (
    <Flex flexDir='column' justifyContent='center'>
      {Object.values(module.scores).map(({ text, number, color }, index) => {
        return (
          <HStack key={`${text}-${index}`}>
            <Dot size='8px' bg={color} />
            <Text fontSize='sm'>
              {text} {number ? `(${number})` : `(blank)`}
            </Text>
          </HStack>
        );
      })}
    </Flex>
  );
};
