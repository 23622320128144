import { Button, HStack, Spacer, Text } from '@chakra-ui/react';
import { renderFormattedValue } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { UseMeasurementControlReturn } from '../tool/hooks/useMeasurementControl';

interface MeasurementInProgressProps {
  control: UseMeasurementControlReturn;
}

export const MeasurementInProgress = (props: MeasurementInProgressProps) => {
  const { control } = props;
  const { activeTool, onSaveMeasurement } = control;

  const measurement = activeTool?.measurement;

  const text = P.run(() => {
    if (P.isNil(activeTool)) {
      return 'Select measurement...';
    }

    if (P.isNil(measurement)) {
      return 'Waiting for measurement...';
    }

    const renderedValue = P.run(() => {
      if (!measurement.value) {
        return null;
      }

      const formattedValue = renderFormattedValue(measurement.value, measurement.units);

      if (!P.isString(formattedValue)) {
        return measurement.units ?? '';
      }

      return formattedValue;
    });

    return renderedValue;
  });

  return (
    <HStack fontWeight='semibold' color='white' align='start'>
      <Text fontSize='sm' color='green.300'>
        {text}
      </Text>
      <Spacer />
      <HStack flexShrink={0}>
        <Button
          size='xs'
          colorScheme='purple'
          isDisabled={P.isNil(measurement)}
          onClick={activeTool?.reset}
        >
          Reset
        </Button>
        <Button
          size='xs'
          colorScheme='green'
          isDisabled={P.isNil(measurement)}
          onClick={onSaveMeasurement}
        >
          Save
        </Button>
      </HStack>
    </HStack>
  );
};
