import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import {
  MeasurementRangeGroup,
  UpdateMeasurementRangeGroupRequest,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedFormItem } from '../../../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../../components/generic/Modal';
import { useUpdateMeasurementRangeGroupMutation } from '../../../../graphql/hooks/useUpdateMeasurementRangeGroupMutation';
import { useAppContext } from '../../../../hooks/useAppContext';

type FormValues = UpdateMeasurementRangeGroupRequest;

interface Props {
  rangeGroup: MeasurementRangeGroup;
}

export const MeasurementRangeGroupEditModal = createModal<Props>((props) => {
  const { rangeGroup, modal } = props;
  const { successToast, errorToast } = useAppContext();

  const mutation = useUpdateMeasurementRangeGroupMutation();

  const onSubmit = async (values: FormValues) => {
    await mutation
      .mutateAsync({
        rangeGroupId: rangeGroup.id,
        request: values,
      })
      .then(() => {
        successToast('Measurement range group edited successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error editing measurement range group: ${err.message}`);
      });
  };

  const methods = useForm({
    defaultValues: {
      label: rangeGroup.label,
      units: rangeGroup.units,
      precision: rangeGroup.precision,
      ranges: rangeGroup.ranges,
      sources: rangeGroup.sources,
    },
  });

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit measurement range group</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack>
                <HookedFormItem label='Label' name='label'>
                  <HookedInput
                    name='label'
                    placeholder='Range group label (e.g. Left Ventricular End Diastolic Dimension)'
                  />
                </HookedFormItem>
                <HookedFormItem label='Units' name='units'>
                  <HookedInput name='units' placeholder='Units (e.g. mm)' />
                </HookedFormItem>
                <HookedFormItem label='Precision' name='precision'>
                  <HookedInput name='precision' placeholder='Precision (e.g. 0)' />
                </HookedFormItem>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
