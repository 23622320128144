import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useDeleteLabelMutation } from '../../graphql/hooks/useDeleteLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { createModal } from '../generic/Modal';

interface Props {
  labelId: string;
}

export const LabelDeleteModal = createModal<Props>((props) => {
  const { organization, successToast, errorToast } = useAppContext();
  const { labelId, modal } = props;

  const deleteLabelMutation = useDeleteLabelMutation();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onDeleteLabel = React.useCallback(() => {
    deleteLabelMutation
      .mutateAsync({
        organizationId: organization.id,
        labelId,
      })
      .then(() => {
        successToast('Label deleted successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error deleting label: ${err.message}`);
      });
  }, [deleteLabelMutation, modal, successToast, errorToast, organization.id, labelId]);

  return (
    <AlertDialog
      isOpen={modal.visible}
      leastDestructiveRef={cancelRef}
      onClose={modal.hide}
      onCloseComplete={modal.resolveHide}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete label
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this label? You can&apos;t undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={modal.hide} data-pw='labelDeleteModalCloseButton'>
              Cancel
            </Button>
            <Button
              ml={3}
              colorScheme='red'
              onClick={onDeleteLabel}
              data-pw='labelDeleteModalSubmitButton'
              isLoading={deleteLabelMutation.isLoading}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});
