import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { Link } from 'react-router-dom';

export const OrganizationNotFound = () => {
  return (
    <ChakraV3.Result
      status='error'
      title='404'
      description='Sorry, no organization could be found. Please contact support to sign up.'
      extra={
        <Link to={'/logout'}>
          <ChakraV3.Button>Logout</ChakraV3.Button>
        </Link>
      }
    />
  );
};
