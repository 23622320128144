import { Icon, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaRegCheckCircle } from 'react-icons/fa';
import * as Yup from 'yup';
import { HookedFormItem } from '../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../components/forms/hookform/HookedSubmitButton';
import { useAuth } from '../../context/AuthContext';
import { AuthCard } from './components/AuthCard';
import { AuthLayout } from './components/AuthLayout';
import { BackToLoginButton } from './components/BackToLoginButton';

type FormValues = {
  email: string;
};

const schema: Yup.SchemaOf<FormValues> = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const RequestResetPassword = () => {
  const { requestResetPasswordEmail } = useAuth();

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const email = methods.watch('email');

  const onSubmit = async (data: FormValues) => {
    try {
      await requestResetPasswordEmail(data.email);
    } catch (error) {
      methods.setError('email', {
        message: `We couldn't complete your password reset request. 
        Please double-check the information you entered and try again. 
        If the issue persists, contact our support team for assistance`,
      });
    }
  };

  const content = P.run(() => {
    if (methods.formState.isSubmitSuccessful) {
      return (
        <AuthCard>
          <Stack spacing={4} align='center'>
            <Icon as={FaRegCheckCircle} color='success' boxSize={16} />
            <Text fontSize='sm' textAlign='center'>
              Please check the email address {email} for instructions to reset your password.
            </Text>
          </Stack>
        </AuthCard>
      );
    }

    return (
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Reset Password
          </Text>
          <Text fontSize='sm' color='secondary'>
            Enter your email address and we&apos;ll send you instructions to reset your password
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <Stack as='form' spacing={4} onSubmit={methods.handleSubmit(onSubmit)}>
            <HookedFormItem name='email' label='Email'>
              <HookedInput name='email' size='md' placeholder='Enter email' />
            </HookedFormItem>
            <HookedSubmitButton size='md' mt={4} loadingText='Sending reset instructions...'>
              Send Reset Instructions
            </HookedSubmitButton>
          </Stack>
          <BackToLoginButton />
        </FormProvider>
      </AuthCard>
    );
  });

  return <AuthLayout>{content}</AuthLayout>;
};
