import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Distribution, DistributionStatus } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Divider } from '../../../components/generic/Divider';
import { createModal } from '../../../components/generic/Modal';
import { useSendDistributionMutation } from '../../../graphql/hooks/useSendDistribution';
import { useAppContext } from '../../../hooks/useAppContext';
import { DistributionHeader } from './DistributionHeader';
import { DistributionSummary } from './DistributionSummary';

interface Props {
  distribution: Distribution;
}

export const DistributionSendModal = createModal<Props>((props) => {
  const { distribution, modal } = props;
  const { successToast, errorToast } = useAppContext();
  const { hide, visible } = modal;

  const mutation = useSendDistributionMutation({});

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: distribution.organizationId,
        reportId: distribution.reportId,
        distributionId: distribution.id,
      })
      .then(async (res) => {
        hide();

        // TODO: Don't do this check in future when we have some sort of
        // async notification system, since may not know the actual
        // distribution status at time of sending. Right now its fine
        // since we effectively deem it 'failed' if it couldn't send
        // it at all.

        if (res.sendDistribution.status === DistributionStatus.Failed) {
          const message = P.first(res.sendDistribution.events)?.payload.message;
          errorToast(`Error sending distribution: ${message}`);
        } else {
          successToast('Distribution sent successfully');
        }
      })
      .catch((err) => {
        errorToast(`Error sending distribution: ${err.message}`);
      });
  }, [mutation, distribution, hide, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to send this distribution?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <DistributionHeader payload={distribution.payload} status={distribution.status} />
          <Divider my={6} />
          <DistributionSummary
            payload={distribution.payload}
            trigger={distribution.trigger}
            createdAt={distribution.createdAt}
          />
          <Divider my={6} />
          <Text color='secondary'>
            The distribution will be sent out now. You cannot undo this operation
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='purple'
              isLoading={mutation.isLoading}
              loadingText='Sending...'
              type='submit'
              onClick={onClick}
              data-pw='distributionSendModalSubmitButton'
            >
              Send
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
