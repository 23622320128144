import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateReportTemplate } from '../mutations/updateReportTemplate.mutation';
import {
  useReportTemplateMinimalQuery,
  useReportTemplateQuery,
  useReportTemplateVersionsQuery,
} from './useReportTemplateQuery';
import { useReportTemplatesQuery } from './useReportTemplatesQuery';

export const useUpdateReportTemplateMutation = (
  options?: MutationOptions<
    Types.UpdateReportTemplateMutation,
    Types.UpdateReportTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateReportTemplate), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportTemplateMinimalQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.reportTemplateId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplateQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.reportTemplateId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplatesQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplateVersionsQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.reportTemplateId,
        }),
      );
      queryClient.invalidateQueries({
        predicate: (query: any) => {
          return (
            query.state.data?.organization?.report?.reportTemplate?.id ===
            variables.reportTemplateId
          );
        },
      });
    },
  });
};
