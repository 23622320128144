import { Box, Button, Input } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import { BaseNodeProps, cleanProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { showModal } from '../../../generic/Modal';
import { NodeBackgroundProps, NodeBackgroundSettings } from '../../common/nodes/NodeBackground';
import { NodeBorderProps, NodeBorderSettings } from '../../common/nodes/NodeBorder';
import { NodeLayoutProps, NodeLayoutSettings } from '../../common/nodes/NodeLayout';
import { NodeSizeProps, NodeSizeSettings } from '../../common/nodes/NodeSize';
import { NodeSpacingProps, NodeSpacingSettings } from '../../common/nodes/NodeSpacing';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { useSSRNode } from '../../hooks/useSSRNode';
import { StatementSitesEditModal } from '../../statements/StatementSitesEditModal';

export interface Props
  extends BaseNodeProps,
    NodeLayoutProps,
    NodeSizeProps,
    NodeSpacingProps,
    NodeBorderProps,
    NodeBackgroundProps {
  text: string;
}

export const ManageStatementsButton = (props: Props) => {
  const { text, ssr, ...rest } = props;
  const {
    id,
    connectors: { connect, drag },
  } = useSSRNode(props);

  const { reportTemplate, isDisabled } = useReport();

  const modalId = `statement-sites-edit-${reportTemplate.id}-${id}`;

  return (
    <Box ref={(ref) => connect(drag(ref))} {...cleanProps(rest)}>
      {!ssr && (
        <>
          <Button
            onClick={() => showModal(modalId)}
            color='gray.400'
            variant='link'
            tabIndex={-1}
            isDisabled={isDisabled}
          >
            {text}
          </Button>
          <StatementSitesEditModal id={modalId} reportTemplate={reportTemplate} />
        </>
      )}
    </Box>
  );
};

const ManageStatementsButtonSettings = () => {
  const {
    actions: { setProp },
    text,
  } = useNode((node) => ({
    text: node.data.props.text,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Text'>
        <FormItem id='text' label='Text'>
          <Input
            defaultValue={text}
            onChange={(e) => setProp((props: Props) => (props.text = e.target.value))}
            size='sm'
          />
        </FormItem>
      </SettingsItem>
      <SettingsItem title='Layout'>
        <NodeLayoutSettings />
      </SettingsItem>

      <SettingsItem title='Spacing'>
        <NodeSpacingSettings />
      </SettingsItem>

      <SettingsItem title='Size'>
        <NodeSizeSettings />
      </SettingsItem>

      <SettingsItem title='Background'>
        <NodeBackgroundSettings />
      </SettingsItem>

      <SettingsItem title='Border'>
        <NodeBorderSettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

ManageStatementsButton.defaultProps = {
  ...NodeLayoutSettings.defaultProps,
  ...NodeSpacingSettings.defaultProps,
  ...NodeSizeSettings.defaultProps,
  ...NodeBackgroundSettings.defaultProps,
  ...NodeBorderSettings.defaultProps,
  text: 'Manage statements',
};

ManageStatementsButton.craft = {
  name: 'ManageStatementsButton',
  props: ManageStatementsButton.defaultProps,
  related: {
    settings: ManageStatementsButtonSettings,
  },
};
