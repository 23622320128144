import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { MergeReportTemplate } from '../mutations/mergeReportTemplate.mutation';
import {
  useReportTemplateMinimalQuery,
  useReportTemplateQuery,
  useReportTemplateVersionsQuery,
} from './useReportTemplateQuery';
import { useReportTemplatesQuery } from './useReportTemplatesQuery';

export const useMergeReportTemplateMutation = (
  options?: MutationOptions<
    Types.MergeReportTemplateMutation,
    Types.MergeReportTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(MergeReportTemplate), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportTemplateMinimalQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.oldReportTemplateId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplateQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.oldReportTemplateId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplatesQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
      queryClient.invalidateQueries(
        useReportTemplateVersionsQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.oldReportTemplateId,
        }),
      );
    },
  });
};
