import { DistributionTrigger } from '@piccolohealth/echo-common';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSection } from '../../../components/forms/FormSection';
import { HookedDistributionTypeChooser } from '../../../components/forms/hookform/HookedDistributionTypeChooser';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedRadioStack } from '../../../components/forms/hookform/HookedRadioStack';
import { DistributionPayloadForm } from './DistributionPayloadForm';

export const DefaultDistributionForm = () => {
  const { watch } = useFormContext();

  const type = watch('type');

  return (
    <FormSection heading='Distribution details'>
      <HookedFormItem
        name='type'
        label='Distribution type'
        helperText='Choose what sort of distribution to create'
      >
        <HookedDistributionTypeChooser name='type' data-pw='distributionModalTypeChooser' />
      </HookedFormItem>

      <DistributionPayloadForm type={type} />
      <HookedFormItem
        name='trigger'
        label='Distribution trigger'
        helperText='When should this distribution be triggered?'
        data-pw='distributionModalTriggerRadioStack'
      >
        <HookedRadioStack
          name='trigger'
          options={[
            {
              value: DistributionTrigger.OnFinalize,
              label: 'Send on finalize',
              description: 'Send the distribution when the report is finalized',
            },
            {
              value: DistributionTrigger.Manual,
              label: 'Send manually',
              description: 'Send the distribution manually',
            },
          ]}
        />
      </HookedFormItem>
    </FormSection>
  );
};
