import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteReportAttachment } from '../mutations/deleteReportAttachment.mutation';
import { getReportBaseKey } from './useReportQuery';

export const useDeleteReportAttachmentMutation = (
  options?: MutationOptions<
    Types.DeleteReportAttachmentMutation,
    Types.DeleteReportAttachmentMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteReportAttachment), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        getReportBaseKey({
          organizationId: variables.organizationId,
          reportId: variables.reportId,
        }),
      );
    },
  });
};
