import { HStack, Text } from '@chakra-ui/react';
import {
  Report,
  getReportVariableValueAsDateTime,
  getReportVariableValueAsString,
  renderNullable,
} from '@piccolohealth/echo-common';
import { DescriptionList } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { ReportTypeTag } from '../../../components/generic/ReportTypeTag';

interface Props {
  report: Report;
}

export const DistributionReportSummary = (props: Props) => {
  const { report } = props;

  const patientName = getReportVariableValueAsString(report.variables, 'patientName');
  const dobValue = getReportVariableValueAsDateTime(report.variables, 'dob');
  const dob = renderNullable(dobValue?.toLocaleString(DateTime.DATE_SHORT));
  const studyDateValue = getReportVariableValueAsDateTime(report.variables, 'studyDate');
  const studyDate = renderNullable(studyDateValue?.toLocaleString(DateTime.DATETIME_SHORT));

  return (
    <HStack spacing={8}>
      <DescriptionList
        rowGap={2}
        items={[
          {
            title: 'Patient name',
            content: <Text fontSize='sm'>{renderNullable(patientName)}</Text>,
          },
          {
            title: 'Date of birth',
            content: <Text fontSize='sm'>{renderNullable(dob)}</Text>,
          },
        ]}
      />
      <DescriptionList
        rowGap={2}
        items={[
          {
            title: 'Report type',
            content: (
              <ReportTypeTag
                name={report.reportTemplate.name}
                labelColor={report.reportTemplate.labelColor}
              />
            ),
          },
          {
            title: 'Date',
            content: <Text fontSize='sm'>{renderNullable(studyDate)}</Text>,
          },
        ]}
      />
    </HStack>
  );
};
