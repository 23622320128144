import { IntegrationType } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { showModal } from '../../../generic/Modal';
import { IntegrationCard } from '../IntegrationCard';
import { HealthLinkIntegrationModal } from './HealthLinkIntegrationModal';

import HealthLinkLogo from '/logos/healthlink.logo.png';

const title = 'HealthLink';
const description = 'HealthLink secure messaging connects doctors';

export const HealthLinkIntegrationCard = () => {
  const { organization } = useAppContext();

  const isIntegrationEnabled = organization.hasIntegrationEnabled(IntegrationType.HealthLink);

  return (
    <IntegrationCard
      title={title}
      description={description}
      logo={HealthLinkLogo}
      isIntegrationEnabled={isIntegrationEnabled}
      onEditIntegration={() =>
        showModal(HealthLinkIntegrationModal, {
          title,
          description,
          logo: HealthLinkLogo,
          isIntegrationEnabled,
        })
      }
      h='4xs'
      w='sm'
    />
  );
};
