import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useDeleteReportAttachmentMutation } from '../../graphql/hooks/useDeleteReportAttachmentMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { createModal } from '../generic/Modal';

interface Props {
  reportId: string;
  attachmentId: string;
}

export const ReportAttachmentDeleteModal = createModal<Props>((props) => {
  const { organization, successToast, errorToast } = useAppContext();
  const { reportId, attachmentId, modal } = props;
  const mutation = useDeleteReportAttachmentMutation();

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportAttachmentId: attachmentId,
        reportId,
      })
      .then(() => {
        successToast('Attachment deleted successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error deleting attachment: ${err.message}`);
      });
  }, [mutation, organization, reportId, attachmentId, modal, successToast, errorToast]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete report attachment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2} fontSize='md'>
            Do you want to delete this attachment?
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button key='close' onClick={modal.hide}>
              Close
            </Button>
            <Button
              colorScheme='red'
              onClick={onClick}
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              data-pw='reportAttachmentDeleteModalSubmitButton'
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
