import { Box } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import { RowSelectionOptions, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { SelectedReportsActionsButton } from '../../components/controls/SelectedReportsActionsControl';
import { Content } from '../../components/generic/Content';
import { ReportsTable } from '../../components/reports/tables/ReportsTable';
import {
  NonExpiredReportShare,
  SharedReportsTable,
} from '../../components/reports/tables/SharedReportsTable';
import { ReportsFilterSidebar } from '../../components/sidebar/ReportsFilterSidebar';
import { SharedReportsFilterSidebar } from '../../components/sidebar/SharedReportsFilterSidebar';
import { SidebarLayout } from '../../components/sidebar/SidebarLayout';
import { useReportSharesFilterQuery } from '../../graphql/hooks/useReportSharesQuery';
import { useReportsFilterQuery } from '../../graphql/hooks/useReportsQuery';
import { usePermission } from '../../hooks/usePermission';
import { useReportsFilter } from '../../hooks/useReportsFilter';

const SharedReportsView = () => {
  const filter = useReportsFilter();
  const { isLoading, error, shares, refetch, pagination, rowSelection } =
    useReportSharesFilterQuery(filter);

  const reportIds = React.useMemo(
    () => rowSelection.selectedRows.map((r) => r.id),
    [rowSelection.selectedRows],
  );

  const nonExpiredShares = React.useMemo(
    () => shares.filter((share) => !P.isNull(share.report)) as NonExpiredReportShare[],
    [shares],
  );

  const nonExpiredRowSelection: RowSelectionOptions<NonExpiredReportShare> = React.useMemo(
    () => ({
      selectedRows: rowSelection.selectedRows.filter(
        (share) => !P.isNull(share.report),
      ) as NonExpiredReportShare[],
      onRowsSelect: (selectedRows: NonExpiredReportShare[]) =>
        rowSelection.onRowsSelect(selectedRows),
    }),
    [rowSelection],
  );

  return (
    <SidebarLayout
      width='220px'
      sidebarContent={<SharedReportsFilterSidebar filter={filter} />}
      mainContent={
        <Content
          title='Reports'
          rightExtraContent={
            <SelectedReportsActionsButton reportIds={reportIds} onDelete={refetch} />
          }
        >
          <SharedReportsTable
            error={error}
            isLoading={isLoading}
            reportShares={nonExpiredShares}
            refetch={refetch}
            pagination={pagination}
            rowSelection={nonExpiredRowSelection}
          />
        </Content>
      }
    />
  );
};

export const ReportsView = () => {
  const location = useLocation();
  const filter = useReportsFilter();

  const { isLoading, error, reports, refetch, pagination, rowSelection } =
    useReportsFilterQuery(filter);

  React.useEffect(() => {
    if (location.state !== null) {
      refetch();
    }
  }, [refetch, location.state]);

  const reportIds = rowSelection.selectedRows.map((r) => r.id);

  return (
    <SidebarLayout
      width='260px'
      sidebarContent={<ReportsFilterSidebar filter={filter} />}
      mainContent={
        <Content
          title='Reports'
          rightExtraContent={
            <SelectedReportsActionsButton reportIds={reportIds} onDelete={refetch} />
          }
        >
          <Box px={2} h='full'>
            <ReportsTable
              error={error}
              isLoading={isLoading}
              reports={reports}
              refetch={refetch}
              pagination={pagination}
              rowSelection={rowSelection}
            />
          </Box>
        </Content>
      }
    />
  );
};

export const Reports = () => {
  const hasReportsReadPermission = usePermission(PERMISSIONS.reportsRead).value;
  const hasReportsReadSharedPermission = usePermission(PERMISSIONS.reportsReadShared).value;

  if (hasReportsReadPermission) {
    return <ReportsView />;
  } else if (hasReportsReadSharedPermission) {
    return <SharedReportsView />;
  } else {
    return <Spin />;
  }
};
