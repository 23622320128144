import { CloseButton, HStack, Image } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { blobToBase64, readResizedImage } from '../utils/fileReader';
import { FileUploadControl } from './FileUploadControl';

export interface ImageUploaderProps {
  onChange: (base64File: string | null) => void;
  onError?: (error: Error) => void;
  value: string | null;
  maxWidth: number;
  maxHeight: number;
  minRatio?: number;
  maxRatio?: number;
  handleFileTypes?: {
    [fileType: string]: (file: File) => Promise<File>;
  };
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const { value, onChange, onError, maxWidth, maxHeight, minRatio, maxRatio } = props;

  const onFilesChange = async (files: File[]) => {
    // Should only ever be 1 upload for image upload since 'multiple'
    // mode is off.
    const file = P.first(files);

    if (!file) {
      return;
    }

    const transformedFile = (await props.handleFileTypes?.[file.type]?.(file)) ?? file;

    try {
      const resizedImage = await readResizedImage(transformedFile, {
        maxWidth,
        maxHeight,
        maxRatio,
        minRatio,
      });

      const base64 = await blobToBase64(resizedImage);
      onChange(base64);
    } catch (error) {
      onError?.(error as Error);
    }
  };

  return value ? (
    <HStack pos='relative' align='start' h='full' w='full'>
      <CloseButton pos='absolute' top={1} right={1} onClick={() => onChange(null)} />
      <Image src={value} objectFit='cover' maxHeight='full' height='full' />
    </HStack>
  ) : (
    <FileUploadControl
      multiple={false}
      files={[]}
      accept='image/*,.pdf'
      onFilesChange={onFilesChange}
      status='idle'
    />
  );
};
