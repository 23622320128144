import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { UserAvatar } from './UserAvatar';

interface Props {
  name: string;
  email: string;
  picture?: string | null;
}

export const UserDescription = (props: Props) => {
  const { name, email, picture } = props;

  return (
    <Flex alignItems='center'>
      <Box>
        <UserAvatar picture={picture} name={name} size='sm' />
      </Box>
      <Box ml={4}>
        <Text fontWeight='semibold' data-pw='userName'>
          {name}
        </Text>
        <Text color='secondary' data-pw='userEmail'>
          {email}
        </Text>
      </Box>
    </Flex>
  );
};
