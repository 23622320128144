import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { CreateLabel } from '../mutations/createLabel.mutation';
import { useLabelsQuery } from './useLabelsQuery';

export const useCreateLabelMutation = (
  options?: MutationOptions<Types.CreateLabelMutation, Types.CreateLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateLabel), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useLabelsQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
    },
  });
};
