import { Input, SimpleGrid } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { FormItem } from '../../../forms/FormItem';

export interface NodeSizeProps {
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
}

export const NodeSizeSettings = () => {
  const {
    actions: { setProp },
    width,
    height,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  } = useNode<NodeSizeProps>((node) => ({
    width: node.data.props.width,
    maxWidth: node.data.props.maxWidth,
    minWidth: node.data.props.minWidth,
    height: node.data.props.height,
    minHeight: node.data.props.minHeight,
    maxHeight: node.data.props.maxHeight,
  }));

  return (
    <SimpleGrid columns={2} spacing={4}>
      <FormItem id='width' label='Width'>
        <Input
          defaultValue={width}
          onChange={(e) => setProp((props: NodeSizeProps) => (props.width = e.target.value))}
          size='sm'
        />
      </FormItem>
      <FormItem id='height' label='Height'>
        <Input
          defaultValue={height}
          onChange={(e) => setProp((props: NodeSizeProps) => (props.height = e.target.value))}
          size='sm'
        />
      </FormItem>
      <FormItem id='minWidth' label='Min width'>
        <Input
          defaultValue={minWidth}
          onChange={(e) => setProp((props: NodeSizeProps) => (props.minWidth = e.target.value))}
          size='sm'
        />
      </FormItem>
      <FormItem id='maxWidth' label='Max width'>
        <Input
          defaultValue={maxWidth}
          onChange={(e) => setProp((props: NodeSizeProps) => (props.maxWidth = e.target.value))}
          size='sm'
        />
      </FormItem>
      <FormItem id='minHeight' label='Min height'>
        <Input
          defaultValue={minHeight}
          onChange={(e) => setProp((props: NodeSizeProps) => (props.minHeight = e.target.value))}
          size='sm'
        />
      </FormItem>
      <FormItem id='maxHeight' label='Max height'>
        <Input
          defaultValue={maxHeight}
          onChange={(e) => setProp((props: NodeSizeProps) => (props.maxHeight = e.target.value))}
          size='sm'
        />
      </FormItem>
    </SimpleGrid>
  );
};

NodeSizeSettings.defaultProps = {
  width: undefined,
  height: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  minHeight: undefined,
  maxHeight: undefined,
};
