import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import { PaginationOptions } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { VariablesTableFilter } from './useVariablesTableFilter';

export const usePaginatedVariablesQuery = (
  filter: VariablesTableFilter,
  variables: ReportTemplateVariable[],
) => {
  const [paginationState, setPaginationState] = React.useState({
    currentPage: filter.currentPageNumber,
    pageSize: filter.pageSize,
  });

  const { total, entries } = React.useMemo(() => {
    const filteredVariables = variables.filter((variable) => {
      const searchFiltered =
        new RegExp(filter.searchFilter, 'i').test(variable.label) ||
        new RegExp(filter.searchFilter, 'i').test(variable.id);

      const typeFiltered = P.isEmpty(filter.typesFilter)
        ? true
        : filter.typesFilter.includes(variable.__typename);

      return searchFiltered && typeFiltered;
    });

    const entries = filteredVariables.slice(
      paginationState.currentPage * paginationState.pageSize,
      paginationState.currentPage * paginationState.pageSize + paginationState.pageSize,
    );

    return {
      total: filteredVariables.length,
      entries: entries,
    };
  }, [filter.typesFilter, filter.searchFilter, variables, paginationState]);

  const nextPage = React.useCallback(() => {
    setPaginationState((oldPaginationState) => ({
      ...oldPaginationState,
      currentPage: oldPaginationState.currentPage + 1,
    }));
  }, []);

  const previousPage = React.useCallback(() => {
    setPaginationState((oldPaginationState) => ({
      ...oldPaginationState,
      currentPage: oldPaginationState.currentPage - 1,
    }));
  }, []);

  const onPageSizeChange = React.useCallback((pageSize: number) => {
    setPaginationState((old) => ({
      ...old,
      pageSize,
    }));
  }, []);

  const hasNextPage = paginationState.currentPage < Math.ceil(total / paginationState.pageSize) - 1;
  const hasPreviousPage = paginationState.currentPage > 0;

  const pagination: PaginationOptions = {
    currentPage: paginationState.currentPage,
    pageSize: paginationState.pageSize,
    total,
    hasNextPage,
    hasPreviousPage,
    pageSizeOptions: [10, 20, 30],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} variables`,
    nextPage,
    previousPage,
    onPageSizeChange,
  };

  return {
    entries,
    errors: [],
    isLoading: false,
    refetch: P.noop,
    pagination,
  };
};
