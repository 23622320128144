import { Node, mergeAttributes } from '@tiptap/core';

export type StatementOptions = {
  HTMLAttributes: Record<string, any>;
};

export const Statements = Node.create<StatementOptions>({
  name: 'statements',
  group: 'block',
  content: '(text|variable?)+',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: 'statements' }];
  },

  renderHTML(props) {
    return ['statements', mergeAttributes(this.options.HTMLAttributes, props.HTMLAttributes), 0];
  },
});
