import { Resolver } from '@craftjs/core';
import { Deprecated } from '../common/components/Deprecated';
import * as common from '../common/helpers';
import { InlineAutocomplete } from './components/InlineAutoComplete';
import { InlineDatepicker } from './components/InlineDatepicker';
import { InlineInput } from './components/InlineInput';
import { InlineSelect } from './components/InlineSelect';
import { InlineStatementSite } from './components/InlineStatementSite';
import { InlineStatementSiteList } from './components/InlineStatementSiteList';
import { ManageStatementsButton } from './components/ManageStatementsButton';
import { Measurement } from './components/Measurement';
import { Page } from './components/Page';
import { Pages } from './components/Pages';
import { ToggleableSection } from './components/ToggleableSection';
import { WallMotionDiagram } from './components/WallMotionDiagram';
import { MediaAttachmentZone } from './components/attachments/media/MediaAttachmentZone';

export const DEPRECATED_RESOLVERS: Resolver = {
  StatementsSection: Deprecated,
};

export const RESOLVERS: Resolver = {
  ...common.RESOLVERS,
  ...DEPRECATED_RESOLVERS,
  Pages,
  Page,
  MediaAttachmentZone,
  WallMotionDiagram,
  InlineInput,
  InlineAutocomplete,
  InlineSelect,
  InlineDatepicker,
  InlineStatementSite,
  InlineStatementSiteList,
  ManageStatementsButton,
  ToggleableSection,
  Measurement,
};
