import React from 'react';
import { useReport } from '../../../context/ReportContext';
import { useAppContext } from '../../../hooks/useAppContext';
import { useHistoricalVariables } from '../../../hooks/useHistoricalVariables';
import { NumberedBadge } from '../../generic/NumberedBadge';

interface Props {
  reportId: string;
}

export const MeasurementsBadge = (props: Props) => {
  const { reportId } = props;
  const { reportTemplate } = useReport();
  const { organization } = useAppContext();

  const historicalVariables = useHistoricalVariables({
    organizationId: organization.id,
    reportId,
    reportTemplate,
  });

  const measurementsCount = historicalVariables.currentVariables.filter(
    (variable) => !variable.isEmpty,
  ).length;

  return <NumberedBadge count={measurementsCount} />;
};
