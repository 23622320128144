import { HStack, Input, Kbd, Spacer, Text } from '@chakra-ui/react';
import {
  MedicalObjectsIntegrationProvider,
  renderMedicalObjectsProviderName,
} from '@piccolohealth/echo-common';
import {
  PaginationSelectFooter,
  Select,
  SelectComponents,
  SelectGroup,
  SelectOption,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import debouncePromise from 'debounce-promise';
import React from 'react';
import { useMedicalObjectsIntegrationProvidersInfiniteQuery } from '../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { FormItem } from './FormItem';
import { FormSection } from './FormSection';

type MedicalObjectsProvider = {
  providerDetails: MedicalObjectsIntegrationProvider;
  providerNumber: string;
};

export interface MedicalObjectsProviderToChooserProps {
  value: MedicalObjectsProvider | null;
  onChange: (value: MedicalObjectsProvider) => void;
  isDisabled?: boolean;
}

export const MedicalObjectsProviderToChooser = (props: MedicalObjectsProviderToChooserProps) => {
  const { value, onChange, isDisabled } = props;

  const { organization } = useAppContext();

  const [filter, setFilter] = React.useState({
    firstName: '',
    lastName: '',
  });

  const { refetch, hasNextPage, fetchNextPage, data, isFetching } =
    useMedicalObjectsIntegrationProvidersInfiniteQuery(
      {
        organizationId: organization.id,
        request: {
          filter,
        },
      },
      {
        enabled: false,
        keepPreviousData: true,
      },
    );

  const debouncedRefetch = React.useMemo(() => debouncePromise(refetch, 300), [refetch]);

  React.useEffect(() => {
    if (!P.isEmptyString(filter.firstName) || !P.isEmptyString(filter.lastName)) {
      debouncedRefetch();
    }
  }, [filter, debouncedRefetch]);

  const isLoading = isFetching;
  const hasMore = hasNextPage ?? false;
  const pages = data?.pages ?? [];
  const pagination = P.first(pages)?.organization?.integrations.medicalObjects.providers.pagination;

  const providers = pages.flatMap(
    (page) => page.organization?.integrations.medicalObjects.providers.results ?? [],
  );

  const options: SelectGroup<MedicalObjectsProvider, MedicalObjectsIntegrationProvider>[] =
    providers.flatMap((providerDetails) => {
      return {
        id: providerDetails.id,
        label: renderMedicalObjectsProviderName(providerDetails),
        raw: providerDetails,
        options: providerDetails.providerNumbers.map((providerNumber) => {
          return {
            value: providerNumber,
            label: `${renderMedicalObjectsProviderName(providerDetails)} - ${providerNumber}`,
            raw: {
              providerNumber,
              providerDetails,
            },
          };
        }),
      };
    });

  const valuePrime: SelectOption<MedicalObjectsProvider> | null =
    value && value.providerNumber
      ? {
          label: `${renderMedicalObjectsProviderName(value.providerDetails)} - ${
            value.providerNumber
          }`,
          value: value.providerNumber,
          raw: value,
        }
      : null;

  const onChangePrime = React.useCallback(
    (value: SelectOption<MedicalObjectsProvider>) => {
      return onChange(value.raw);
    },
    [onChange],
  );

  const components = React.useMemo<
    SelectComponents<MedicalObjectsProvider, MedicalObjectsIntegrationProvider>
  >(() => {
    return {
      OptionGroup: (props) => (
        <HStack fontWeight='semibold' color='gray.500' py={2} px={1} fontSize='xs'>
          <Text>{props.group.label}</Text>
          <Spacer />
          <Text>{P.first(props.group.raw.address)?.city ?? null}</Text>
        </HStack>
      ),
      Option: (props) => <Kbd fontSize='sm'>{props.option.value}</Kbd>,
      Value: (props) => (
        <HStack w='full' mr={1}>
          <Text>{renderMedicalObjectsProviderName(props.option.raw.providerDetails)}</Text>
          <Spacer />
          <Kbd fontSize='sm'>{props.option.raw.providerNumber}</Kbd>
        </HStack>
      ),
      Header: () => (
        <SelectComponents.Header color='revert'>
          <FormSection
            w='full'
            heading='Filter providers'
            description='Search providers using first name or last name'
          >
            <HStack w='full' pb={2}>
              <FormItem>
                <Input
                  value={filter.firstName}
                  onChange={(e) => setFilter((prev) => ({ ...prev, firstName: e.target.value }))}
                  placeholder='First name'
                  size='sm'
                  data-pw='distributionModalMedicalObjectsToFirstNameInput'
                />
              </FormItem>
              <FormItem>
                <Input
                  value={filter.lastName}
                  onChange={(e) => setFilter((prev) => ({ ...prev, lastName: e.target.value }))}
                  placeholder='Last name'
                  size='sm'
                  data-pw='distributionModalMedicalObjectsToLastNameInput'
                />
              </FormItem>
            </HStack>
          </FormSection>
        </SelectComponents.Header>
      ),
      Footer: () =>
        pagination ? (
          <PaginationSelectFooter
            items='result'
            total={pagination.total}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            hasMore={hasMore}
          />
        ) : null,
    };
  }, [filter.firstName, filter.lastName, hasMore, isLoading, pagination, fetchNextPage]);

  return (
    <Select
      options={options}
      value={valuePrime}
      onChange={onChangePrime}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder='Choose a provider number'
      data-pw='distributionModalMedicalObjectsToProviderNumberChooser'
      components={components}
    />
  );
};
