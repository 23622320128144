import { yupResolver } from '@hookform/resolvers/yup';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormSection } from '../../../components/forms/FormSection';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedResetButton } from '../../../components/forms/hookform/HookedResetButton';
import { HookedSelect } from '../../../components/forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { Content } from '../../../components/generic/Content';
import { Divider } from '../../../components/generic/Divider';
import { useDeleteOrganizationMutation } from '../../../graphql/hooks/useDeleteOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface FormValues {
  organizationId: string;
}

export const Offboard = () => {
  const { user, successToast, errorToast } = useAppContext();

  const mutation = useDeleteOrganizationMutation();

  const initialValues = {
    organizationId: '',
  };

  const validationSchema = Yup.object({
    organizationId: Yup.string().required(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: FormValues) => {
    await mutation
      .mutateAsync(values)
      .then(() => {
        successToast('Organization deleted successfully');
        methods.reset();
      })
      .catch((err) => {
        errorToast(`Error deleting organization: ${err.message}`);
      });
  };

  const organizationOptions = user.organizationMemberships.map((membership) => ({
    label: membership.name,
    value: membership.organizationId,
    raw: membership.organizationId,
  }));

  return (
    <Content title='Offboard'>
      <ChakraV3.Box maxW='400px' p={4}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormSection heading='Delete an organization'>
              <HookedFormItem label='Organization' name='organizationId'>
                <HookedSelect name='organizationId' options={organizationOptions} />
              </HookedFormItem>
            </FormSection>
            <Divider />
            <ChakraV3.HStack alignItems='center'>
              <HookedResetButton>Reset</HookedResetButton>
              <HookedSubmitButton>Delete</HookedSubmitButton>
            </ChakraV3.HStack>
          </form>
        </FormProvider>
      </ChakraV3.Box>
    </Content>
  );
};
