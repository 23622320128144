import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { PhoneEnrolChallenge } from '../../context/AuthContext';
import { PhoneEnrolCard } from './components/PhoneEnrolCard';
import { PhoneVerifyCard } from './components/PhoneVerifyCard';

export const PhoneEnrol = () => {
  const auth = useAuth();

  const [mfaEnrolResponse, setMFAEnrolResponse] = React.useState<PhoneEnrolChallenge | null>(null);

  const onVerifyCode = async (verificationCode: string): Promise<void> => {
    if (!mfaEnrolResponse) {
      return;
    }

    return auth.verifyPhoneEnrolmentCode(mfaEnrolResponse, verificationCode);
  };

  const onSendCode = async (phoneNumber: string) => {
    try {
      const response = await auth.sendPhoneEnrolmentCode(phoneNumber);
      setMFAEnrolResponse(response);
    } catch (err) {
      if (err.code === 'auth/requires-recent-login') {
        await auth.logout();
      }
    }
  };

  const onResendCode = async () => {
    if (!mfaEnrolResponse) {
      return;
    }

    onSendCode(mfaEnrolResponse.phoneNumber);
  };

  if (auth.isMFAEnrolled) {
    return <Navigate to='/login' />;
  }

  if (mfaEnrolResponse) {
    return <PhoneVerifyCard onVerifyCode={onVerifyCode} onResendCode={onResendCode} />;
  }

  return <PhoneEnrolCard onSendCode={onSendCode} />;
};
