import { MenuItem, Text } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import { inflection } from '@piccolohealth/util';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { ReportsDeleteModal } from '../modals/ReportsDeleteModal';

interface Props {
  reportIds: string[];
  onDelete: () => Promise<void>;
}

export const SelectedReportsDeleteMenuItem = ({ reportIds, onDelete }: Props) => {
  const hasPermission = usePermission(PERMISSIONS.reportsDelete).value;

  return (
    <MenuItem
      key='deleteReports'
      icon={<FaRegTrashAlt />}
      onClick={() => showModal(ReportsDeleteModal, { reportIds, onDelete })}
      isDisabled={!hasPermission}
      data-pw='selectedReportsDeleteMenuItem'
    >
      <Text color='danger'>
        Delete {reportIds.length} {inflection.inflect('report', reportIds.length)}
      </Text>
    </MenuItem>
  );
};
