import { Box, Input } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { getReportTemplateVariableById } from '@piccolohealth/echo-common';
import { RadioGroup } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedAutoCompleteReportVariable } from '../../../forms/hookform/HookedAutoCompleteReportVariable';
import { HookedMultiSelectReportVariable } from '../../../forms/hookform/HookedMultiSelectReportVariable';
import { ReportVariableRenderingError } from '../../common/ReportVariableRenderingError';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

export interface Props extends BaseNodeProps {
  variableId: string;
  mode: 'single' | 'multiple';
  size: 'xs' | 'sm' | 'md' | 'lg';
}

export const InlineAutocomplete = (props: Props) => {
  const { variableId, mode, size, ssr } = props;
  const {
    connectors: { connect, drag },
  } = useSSRNode(props);
  const { isDisabled, reportTemplate } = useReport();

  const alias = getReportTemplateVariableById(reportTemplate.variables, variableId)?.alias;

  const name = `variables.${variableId}.value`;
  const isMulti = mode === 'multiple';
  const selectedValues = useWatch({ name });

  if (!alias) {
    return (
      <ReportVariableRenderingError
        variableId={variableId}
        message={`Unable to find alias for variable ${variableId} in autocomplete component`}
      />
    );
  }

  if (ssr) {
    return (
      <Input
        size={size}
        isDisabled={isDisabled}
        variant='unstyled'
        value={[selectedValues]
          .flat()
          .flatMap((value) => (P.isString(value) && value.length > 0 ? P.upperFirst(value) : []))
          .join(', ')}
        readOnly
      />
    );
  }

  const content = isMulti ? (
    <HookedMultiSelectReportVariable
      name={name}
      alias={alias}
      isDisabled={isDisabled}
      variant='selectGhost'
      size={size}
      components={{
        Placeholder: () => null,
      }}
    />
  ) : (
    <HookedAutoCompleteReportVariable
      name={name}
      alias={alias}
      isDisabled={isDisabled}
      variant='unstyled'
      size={size}
    />
  );

  return (
    <Box ref={(ref) => connect(drag(ref))} display='inline' w='full'>
      {content}
    </Box>
  );
};

const InlineAutocompleteSettings = () => {
  const {
    actions: { setProp },
    variableId,
    mode,
    size,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    mode: node.data.props.mode,
    size: node.data.props.size,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Inline autocomplete'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
          />
        </FormItem>
        <FormItem id='mode' label='Mode'>
          <RadioGroup
            value={mode}
            onChange={(e) => setProp((props: Props) => (props.mode = e as Props['mode']), 1000)}
            options={[
              { label: 'Single', value: 'single' },
              { label: 'Multiple', value: 'multiple' },
            ]}
            size='sm'
            variant='outline'
          />
        </FormItem>
        <FormItem id='size' label='Size'>
          <Input
            defaultValue={size}
            value={size}
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
          />
        </FormItem>
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineAutocomplete.defaultProps = {
  variableId: 'sex',
  mode: 'single' as const,
  size: 'sm' as const,
};

InlineAutocomplete.craft = {
  name: 'InlineAutocomplete',
  props: InlineAutocomplete.defaultProps,
  related: {
    settings: InlineAutocompleteSettings,
  },
};
