import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { autoPlacement, offset } from '@floating-ui/react';
import {
  ReportTemplateWallMotionVariable,
  getWallMotionModule,
  renderWallMotion,
} from '@piccolohealth/echo-common';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { HookedWallMotionControl } from '../../../forms/hookform/HookedWallMotionControl';
import { Divider } from '../../../generic/Divider';
import { HighlightedVariableValue } from './HighlightedVariableValue';

interface Props {
  name: string;
  reportTemplateVariable: ReportTemplateWallMotionVariable;
  isDisabled?: boolean;
}

export const WallMotionVariableControl = (props: Props) => {
  const { name, reportTemplateVariable, isDisabled } = props;
  const disclosure = useDisclosure();
  const { field } = useController({ name });

  const module = getWallMotionModule(field.value?.variant);
  const wallMotionText = renderWallMotion(
    module.present.yes.value,
    field.value?.wmComplex ?? {},
    module,
  );

  return (
    <Box layerStyle='inlineFocus' display='inline'>
      <FloatingPopover
        open={disclosure.isOpen}
        setOpen={(value) => (value ? disclosure.onOpen() : disclosure.onClose())}
        placement='bottom-start'
        isRoot={true}
        enabled={!isDisabled}
        isPortal
        trigger='focus'
        middleware={[offset(5), autoPlacement({})]}
        data-pw={`wallMotionVariableControl-${reportTemplateVariable.id}`}
        render={() => (
          <Box
            w='3xl'
            shadow='2xl'
            rounded='md'
            bg='white'
            borderWidth='1px'
            borderColor='gray.300'
          >
            <Box px={3} py={2} borderBottomWidth='1px' borderBottomColor='gray.300'>
              <Text fontWeight='semibold' color='gray.500' fontSize='xs'>
                {reportTemplateVariable.label}
              </Text>
            </Box>
            <Box px={3} py={2} tabIndex={-1}>
              <HookedWallMotionControl name={name} isDisabled={isDisabled} />
              <Divider />
              <Box bg='gray.50' p={4} rounded='md' fontSize='sm'>
                {wallMotionText}
              </Box>
            </Box>
          </Box>
        )}
      >
        <Box display='inline' tabIndex={0}>
          <HighlightedVariableValue
            value={field.value}
            reportTemplateVariable={reportTemplateVariable}
            template={wallMotionText}
            capitalize={false}
            showChoices={false}
          />
        </Box>
      </FloatingPopover>
    </Box>
  );
};
