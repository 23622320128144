import {
  ReportVariable,
  getReportVariableValueAsString,
  renderNullable,
} from '@piccolohealth/echo-common';
import { useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useReportMinimalQuery } from '../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const ReportBreadcrumb = () => {
  const { reportId } = useRequiredParams<{ reportId: string }>();

  const { organization } = useAppContext();

  const { isLoading, data, error } = useReportMinimalQuery({
    organizationId: organization.id,
    reportId,
  });

  if (isLoading) {
    return <span>...</span>;
  }

  if (error) {
    return <span>_</span>;
  }

  const report = data?.organization?.report;

  if (!report) {
    return <span>_</span>;
  }

  const patientName = getReportVariableValueAsString(
    report.variables as ReportVariable[],
    'patientName',
  );

  return <span>{renderNullable(patientName)}</span>;
};
