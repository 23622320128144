import { Tag, Text, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { MeasurementProperty } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  properties: MeasurementProperty[];
}

export const MeasurementPropertiesTagsList = (props: Props) => {
  const { properties } = props;

  const displayedProperties = properties.slice(0, 3);
  const remainingProperties = properties.slice(3);

  const tooltip = remainingProperties.length > 0 && (
    <Tooltip
      label={
        <Wrap>
          {remainingProperties.map((property) => (
            <WrapItem key={property.name}>
              <Tag>
                {property.name} = {property.value}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      }
      placement='bottom'
      hasArrow
    >
      <Text fontSize='xs' fontWeight='semibold'>
        +{remainingProperties.length} more
      </Text>
    </Tooltip>
  );

  if (P.isEmpty(properties)) {
    return (
      <Text color='secondary' fontSize='sm'>
        No properties
      </Text>
    );
  }

  return (
    <Wrap align='center'>
      {displayedProperties.map((property) => (
        <WrapItem key={property.name}>
          <Tag>
            {property.name} = {property.value}
          </Tag>
        </WrapItem>
      ))}
      {tooltip}
    </Wrap>
  );
};
