import {
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  Text,
} from '@chakra-ui/react';
import { Role, renderPermissions } from '@piccolohealth/echo-common';
import { DataTable, createColumnHelper } from '@piccolohealth/ui';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

type PermissionDetails = {
  resource: string;
  details: string;
};

interface Props {
  role: Role;
}

export const UserRoleDetailsPopover = (props: Props) => {
  const { role } = props;

  const permissionsDetails = renderPermissions(role.permissions);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<PermissionDetails>();

    return [
      columnHelper.display({
        header: 'Resource',
        cell: (ps) => <Text key='resource'>{ps.row.original.resource}</Text>,
        size: 100,
        minSize: 100,
      }),
      columnHelper.display({
        header: 'Details',
        cell: (ps) => <Text key='details'>{ps.row.original.details}</Text>,
      }),
    ];
  }, []);

  return (
    <Popover trigger='hover' isLazy={true} lazyBehavior='unmount'>
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={FaInfoCircle} />}
          size='sm'
          aria-label='User role details'
          variant='ghost'
          _hover={undefined}
        />
      </PopoverTrigger>
      <PopoverContent w='lg'>
        <PopoverHeader fontWeight='bold' display='inline-flex'>
          <HStack>
            <Text>Role details</Text>
            <Tag>{role.name}</Tag>
          </HStack>
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <DataTable
            columns={columns}
            data={permissionsDetails}
            size='sm'
            data-pw='usersRolesDetailsPopoverTable'
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
