import { Box, Button, Icon, Menu, MenuButton, MenuDivider, MenuList } from '@chakra-ui/react';
import { User } from '@piccolohealth/echo-common';
import { DataTable, createColumnHelper } from '@piccolohealth/ui';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { useAppContext } from '../../../hooks/useAppContext';
import { UserEditMenuItem } from '../../controls/UserEditControl';
import { UserRemoveMenuItem } from '../../controls/UserRemoveControl';
import { UserDescription } from '../../user/UserDescription';
import { UserRoles } from '../../user/UserRoles';

interface Props {
  users: User[];
  isLoading: boolean;
}

export const UsersSettingsTable = (props: Props) => {
  const { users, isLoading } = props;

  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<User>();

    return [
      columnHelper.display({
        header: 'Name',
        cell: (ps) => (
          <UserDescription
            name={ps.row.original.name}
            email={ps.row.original.email}
            picture={ps.row.original.picture}
          />
        ),
        minSize: 500,
      }),
      columnHelper.display({
        header: 'Roles',
        cell: (ps) => <UserRoles organizationId={organization.id} user={ps.row.original} />,
        size: 500,
        minSize: 500,
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (ps) => (
          <Box>
            <Menu>
              <MenuList>
                <UserEditMenuItem user={ps.row.original} />
                <MenuDivider />
                <UserRemoveMenuItem user={ps.row.original} />
              </MenuList>
              <MenuButton
                as={Button}
                variant='outline'
                size='sm'
                rightIcon={<Icon as={FaAngleDown} />}
                data-pw={`userActionsButton-${ps.row.original.id}`}
              >
                Actions
              </MenuButton>
            </Menu>
          </Box>
        ),
        minSize: 150,
      }),
    ];
  }, [organization]);

  return (
    <DataTable
      columns={columns}
      data={users}
      isLoading={isLoading}
      size='md'
      data-pw='usersSettingsTable'
    />
  );
};
