import React from 'react';
import { Line } from 'react-konva';
import { MeasurementCrosshair } from './MeasurementCrosshair';
import { MeasurementText } from './MeasurementText';
import { UseLengthToolReturn } from './hooks/useLengthTool';

interface Props {
  tool: UseLengthToolReturn;
}

export const MeasurementLine = (props: Props) => {
  const { tool } = props;

  const { line, isDrawing, text } = tool;

  if (!line) {
    return null;
  }

  const { start, end } = line;

  const color = isDrawing ? 'red' : 'yellow';

  return (
    <>
      <Line
        points={[start.x, start.y, end.x, end.y]}
        stroke={color}
        strokeWidth={2}
        dash={[4, 3]}
        lineCap='round'
      />
      <MeasurementCrosshair position={start} color={color} gap={3} size={5} />
      <MeasurementCrosshair position={end} color={color} gap={3} size={5} />
      {text && <MeasurementText value={text.value} position={text.position} color={color} />}
    </>
  );
};
