import gql from 'graphql-tag';

export const Insights = gql`
  query Insights($organizationId: ID!, $request: GetInsightsRequest!) {
    organization(organizationId: $organizationId) {
      insights(request: $request) {
        reportsUploaded
        reportsFinalized
        reportsShared
        uniquePatients
        sonographers {
          name
          count
        }
        cardiologists {
          name
          count
        }
        referringPhysicians {
          name
          count
        }
        reportsUploadedOverTime {
          reportTemplateId
          reportTemplateName
          reportTemplateLabelColor
          reportCreatedAtDay
          reportCount
        }
        reportsUploadedByType {
          name
          count
          color
        }
      }
    }
  }
`;
