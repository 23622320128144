import { Box, Flex, HStack, Tab, TabList, TabPanel, TabPanels, Text } from '@chakra-ui/react';
import { PiccoloError, Report } from '@piccolohealth/echo-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { ReportActionMenu } from '../../../components/controls/ReportActionMenu';
import { Content } from '../../../components/generic/Content';
import { Error } from '../../../components/generic/Error';
import { TabsRouter } from '../../../components/generic/TabsRouter';
import { ReportPreview } from '../../../components/reports/ReportPreview';
import { ImagesBadge } from '../../../components/reports/custom/ImagesBadge';
import { ReportEditor } from '../../../components/reports/custom/ReportEditor';
import { SharedReportHeader } from '../../../components/reports/custom/SharedReportHeader';
import { ImageViewer } from '../../../components/viewer/ImageViewer';
import { Attachments } from '../../../features/attachments/components/Attachments';
import { AttachmentsBadge } from '../../../features/attachments/components/AttachmentsBadge';
import { useReportShareQuery } from '../../../graphql/hooks/useReportShareQuery';
import { useAppContext } from '../../../hooks/useAppContext';

export const ReportShare = () => {
  const { reportId } = useRequiredParams<{ reportId: string }>();

  const { organization } = useAppContext();

  const { isLoading, data, error } = useReportShareQuery({
    organizationId: organization.id,
    reportId,
  });

  const reportShare = data?.organization?.reportShare;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!reportShare) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ReportShareNotFound',
            message: 'Report not found',
          })
        }
      />
    );
  }

  const report = reportShare.report as Report;

  if (reportShare.isExpired) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ReportShareNotFound',
            message: 'Report not found',
          })
        }
      />
    );
  }

  return (
    <Content
      rightExtraContent={
        <ReportActionMenu
          reportId={report.id}
          reportStatus={report.status}
          onDelete={() => Promise.resolve()}
        />
      }
      headerContent={<SharedReportHeader reportId={reportShare.reportId} />}
    >
      <ReportEditor reportId={reportShare.reportId}>
        <Flex h='full' w='full'>
          <Box overflowX='auto' overflowY='auto' flexGrow={1}>
            <TabsRouter
              routes={['report', 'images', 'attachments']}
              colorScheme='purple'
              w='full'
              h='full'
              display='flex'
              flexDir='column'
              isLazy
              lazyBehavior='keepMounted'
            >
              <TabList>
                <Tab key='report'>Report</Tab>
                <Tab key='images'>
                  <HStack>
                    <Text>Images</Text>
                    <ImagesBadge reportId={reportId} />
                  </HStack>
                </Tab>
                <Tab key='attachments'>
                  <HStack>
                    <Text>Attachments</Text>
                    <AttachmentsBadge reportId={reportId} />
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels h='full' overflowY='auto'>
                <TabPanel key='report-tabpanel' h='full'>
                  <ReportPreview
                    reportId={reportShare.reportId}
                    reportQuery={useReportShareQuery}
                    shouldWrap={true}
                  />
                </TabPanel>
                <TabPanel key='images-tabpanel' h='full'>
                  <ImageViewer reportId={reportShare.reportId} allowShowReport={false} />
                </TabPanel>
                <TabPanel key='attachments-tabpanel' h='full'>
                  <Attachments reportId={reportShare.reportId} />
                </TabPanel>
              </TabPanels>
            </TabsRouter>
          </Box>
        </Flex>
      </ReportEditor>
    </Content>
  );
};
