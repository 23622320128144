import { Alert, AlertIcon, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { showModal } from '../../generic/Modal';
import { ReportFastForwardModal } from './ReportFastForwardModal';

export interface Props {
  reportId: string;
}

export const ReportVersionStatus = (props: Props) => {
  const { reportId } = props;

  return (
    <Alert status='info'>
      <AlertIcon />
      <Text fontSize='sm'>
        This report is using an older version of this report template. If you would like to use the
        latest version{' '}
        <Button
          variant='link'
          display='inline-block'
          size='sm'
          colorScheme='purple'
          onClick={() => showModal(ReportFastForwardModal, { reportId })}
        >
          click here.
        </Button>
      </Text>
    </Alert>
  );
};
