import { Box, Flex, HStack, Heading, Spacer } from '@chakra-ui/react';
import React from 'react';
import { Breadcrumbs } from './Breadcrumbs';

interface Props {
  title?: JSX.Element | string;
  leftExtraContent?: JSX.Element;
  rightExtraContent?: JSX.Element;
}

export const PageHeader = (props: React.PropsWithChildren<Props>) => {
  const { title, leftExtraContent, rightExtraContent, children } = props;

  const headerTitle = title ? <Heading size='md'>{title}</Heading> : <Breadcrumbs />;

  return (
    <Box>
      <Flex>
        <HStack spacing={4} py={1}>
          <Box>{headerTitle}</Box>
          {leftExtraContent}
        </HStack>
        <Spacer />
        <Box>{rightExtraContent}</Box>
      </Flex>
      {children && <Box>{children}</Box>}
    </Box>
  );
};
