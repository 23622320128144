import {
  Box,
  Button,
  Collapse,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import { Empty } from '@piccolohealth/ui';
import { Spin } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import { useReport } from '../../../context/ReportContext';
import { MeasurementsChart } from '../../../features/measurements/MeasurementsChart';
import { MeasurementsTableContent } from '../../../features/measurements/MeasurementsTableContent';
import { useAppContext } from '../../../hooks/useAppContext';
import {
  HistoricalVariable,
  VariableHistory,
  useHistoricalVariables,
} from '../../../hooks/useHistoricalVariables';
import { Card, CardContent, CardHeader, Property } from '../../generic/Card';
import { createModal, showModal } from '../../generic/Modal';
import { VariableControl } from '../statements/VariableControls';

const MeasurementsModal = createModal<{ history: VariableHistory }>((props) => {
  const { modal, history } = props;

  let chartContent;

  if (history.chart.isGraphable) {
    chartContent = (
      <Box h='sm' borderWidth='1px' borderRadius='lg' shadow='sm'>
        <MeasurementsChart ssr={false} data={[history.chart]} />
      </Box>
    );
  } else {
    chartContent = (
      <Empty
        title='No measurement history'
        description='There was not enough history or the provided data was not able to be graphed'
      />
    );
  }

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Stack spacing={0}>
              <Text>{history.reportTemplateVariable?.label}</Text>
            </Stack>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MeasurementsTableContent history={history} />
          {chartContent}
        </ModalBody>
        <ModalFooter>
          <Button onClick={modal.hide}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const Measurements = (props: { history: VariableHistory }) => {
  const { history } = props;

  if (history.allNonNil.length === 0) {
    return (
      <Text color='gray.500' fontWeight='semibold' fontSize='xs'>
        No history
      </Text>
    );
  }

  const modalId = `measurement-modal-${history.reportTemplateVariable.id}`;

  return (
    <>
      <Button
        colorScheme='gray'
        variant='link'
        size='xs'
        alignSelf='start'
        onClick={() => showModal(modalId)}
      >
        View history ({history.allNonNil.length}{' '}
        {inflection.inflect('measurement', history.allNonNil.length)})
      </Button>
      <MeasurementsModal id={modalId} history={history} />
    </>
  );
};

interface SiteCardProps {
  site: string;
  measurements: {
    reportTemplateVariable: ReportTemplateVariable | undefined;
    variables: HistoricalVariable[];
  }[];
  getHistory: (site: string) => VariableHistory | undefined;
}

export const SiteCard = (props: SiteCardProps) => {
  const { site, measurements, getHistory } = props;

  const { isOpen, getButtonProps } = useDisclosure({ defaultIsOpen: false });

  const properties = P.compact(
    measurements.map(({ reportTemplateVariable }) => {
      if (!reportTemplateVariable) {
        return null;
      }

      const history = getHistory(reportTemplateVariable.id);

      if (!history) {
        return null;
      }

      const showVariable = history.current.isEmpty ? isOpen : true;

      return (
        <Collapse key={`${site}-${reportTemplateVariable.id}`} in={showVariable} animateOpacity>
          <Property
            key={`${site}-${reportTemplateVariable.id}`}
            py={2}
            label={
              <Stack spacing={0}>
                <Text fontWeight='medium'>{reportTemplateVariable.label}</Text>
                <Measurements history={history} />
              </Stack>
            }
            value={
              <Box w='3xs'>
                <VariableControl variable={reportTemplateVariable} />
              </Box>
            }
            alignItems='center'
          />
        </Collapse>
      );
    }),
  );

  const emptyVariableCount = measurements
    .flatMap((v) => v.variables)
    .filter(({ isEmpty }) => isEmpty).length;

  const emptyVariableText = `empty ${inflection.inflect('measurement', emptyVariableCount)}`;

  return (
    <Card key={site}>
      <CardHeader
        title={site}
        action={
          emptyVariableCount > 0 && (
            <Button variant='link' {...getButtonProps()}>
              {isOpen
                ? `Hide ${emptyVariableCount} ${emptyVariableText}`
                : `Show ${emptyVariableCount} ${emptyVariableText}`}
            </Button>
          )
        }
        px={4}
      />

      <CardContent fontSize='base' py='4'>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={0}>
          {properties}
        </SimpleGrid>
      </CardContent>
    </Card>
  );
};

interface Props {
  reportId: string;
}

export const VariablesEditor = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();
  const { reportTemplate } = useReport();

  const { isLoading, allVariables, getHistory } = useHistoricalVariables({
    organizationId: organization.id,
    reportId,
    reportTemplate,
  });

  const currentVariablesBySite: {
    site: string;
    measurements: {
      reportTemplateVariable: ReportTemplateVariable | undefined;
      variables: HistoricalVariable[];
    }[];
  }[] = P.orderBy(
    Object.entries(
      P.groupBy(
        allVariables,
        ({ reportTemplateVariable }) => reportTemplateVariable?.site ?? 'Misc',
      ),
    ).map(([site, measurements]) => ({
      site,
      measurements: P.orderBy(measurements ?? [], (v) => v.reportTemplateVariable?.label),
    })),
    (v) => {
      if (v.site === 'Patient Characteristics') {
        return 1;
      } else if (v.site === 'Study') {
        return 2;
      } else {
        return v.site;
      }
    },
  );

  const cards = React.useMemo(() => {
    return currentVariablesBySite.map(({ measurements, site }) => (
      <SiteCard key={site} site={site} measurements={measurements} getHistory={getHistory} />
    ));
  }, [currentVariablesBySite, getHistory]);

  if (isLoading) {
    return <Spin />;
  }

  if (P.isEmpty(currentVariablesBySite)) {
    return (
      <Empty title='No measurements' description='There are no measurements for this report type' />
    );
  }

  return (
    <Stack p={4} spacing={6}>
      {cards}
    </Stack>
  );
};
