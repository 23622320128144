import { Box, Flex, HStack, Spacer } from '@chakra-ui/react';
import { ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { VariablesTableFilter } from '../../../hooks/useVariablesTableFilter';
import { ReportTemplateVariableAddButton } from './ReportTemplateVariableAddControl';
import { ReportTemplateVariablesTypeFilterControl } from './ReportTemplateVariableTypeFilterControl';
import { ReportTemplateVariablesSearchFilterControl } from './ReportTemplateVariablesSearchFilterControl';

interface Props {
  filter: VariablesTableFilter;
  reportTemplate: ReportTemplate;
}

export const ReportTemplateVariablesTableFilterHeader = (props: Props) => {
  const { filter, reportTemplate } = props;

  return (
    <Flex w='100%'>
      <HStack align='normal' spacing={4}>
        <Box w={300}>
          <ReportTemplateVariablesSearchFilterControl filter={filter} />
        </Box>
        <Box w={300}>
          <ReportTemplateVariablesTypeFilterControl filter={filter} />
        </Box>
      </HStack>
      <Spacer />
      <ReportTemplateVariableAddButton reportTemplate={reportTemplate} />
    </Flex>
  );
};
