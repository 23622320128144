import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { AddReportTemplateVariables } from '../mutations/addReportTemplateVariables.mutation';
import { useReportTemplateQuery } from './useReportTemplateQuery';

export const useAddReportTemplateVariablesMutation = (
  options?: MutationOptions<
    Types.AddReportTemplateVariablesMutation,
    Types.AddReportTemplateVariablesMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(AddReportTemplateVariables), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportTemplateQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.reportTemplateId,
        }),
      );

      queryClient.invalidateQueries({
        predicate: (query: any) => {
          return (
            query.state.data?.organization?.report?.reportTemplate?.id ===
            variables.reportTemplateId
          );
        },
      });
    },
  });
};
