import React from 'react';
import { Result, ResultProps } from './chakra/Result';

interface Props extends Omit<ResultProps, 'status'> {
  title?: string;
  description?: string | React.ReactNode;
  'data-pw'?: string;
}

export const Empty = (props: Props) => {
  const { title, description, ...rest } = props;

  return (
    <Result status='info' title={title ?? 'No data found'} description={description} {...rest} />
  );
};
