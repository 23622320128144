import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useDeleteReportMutation } from '../../graphql/hooks/useDeleteReportMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { createModal } from '../generic/Modal';

interface Props {
  reportId: string;
  onDelete: () => Promise<void>;
}

export const ReportDeleteModal = createModal<Props>((props) => {
  const { reportId, modal, onDelete } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteReportMutation({});
  const { hide, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId,
      })
      .then(async () => {
        hide();
        await onDelete();
        successToast('Report deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting report: ${err.message}`);
      });
  }, [mutation, hide, onDelete, organization, reportId, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent data-pw='reportDeleteModalContent'>
        <ModalHeader>Do you want to delete this report?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              type='submit'
              onClick={onClick}
              data-pw='reportDeleteModalSubmitButton'
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
