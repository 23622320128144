import { HStack, Icon, Spinner, Text } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export const AutoSaveStatusIndicator = () => {
  const { isSubmitting, errors } = useFormState();
  const [saveCount, setSaveCount] = React.useState(0);

  React.useEffect(() => {
    if (isSubmitting && P.isEmptyObject(errors)) {
      setSaveCount((count) => count + 1);
    }
  }, [errors, isSubmitting]);

  const content = P.run(() => {
    if (isSubmitting) {
      return (
        <>
          <Spinner size='sm' />
          <Text data-pw='reportSavingStatusIndicator' lineHeight={1}>
            Saving...
          </Text>
        </>
      );
    }

    if (!P.isEmptyObject(errors)) {
      return (
        <>
          <Icon as={FaTimesCircle} color='red.400' />
          <Text lineHeight={1}>Failed to save</Text>
        </>
      );
    }
    return (
      <>
        <Icon as={FaCheckCircle} />
        <Text data-pw='reportSavedStatusIndicator' data-save-count={saveCount} lineHeight={1}>
          Saved
        </Text>
      </>
    );
  });

  return (
    <HStack alignItems='center' fontSize='sm' fontWeight='semibold' color='gray.600'>
      {content}
    </HStack>
  );
};
