import {
  PERMISSIONS,
  PiccoloError,
  Report,
  ReportFormValues,
  ReportStatementSite,
  ReportStatus,
  ReportTemplate,
  ReportVariable,
  andPermission,
  formValuesFromReport,
  reportFormValuesCombine,
  reportFormValuesDiff,
} from '@piccolohealth/echo-common';
import { Spin, typedMemo } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../../components/generic/Error';
import { ReportContextProvider } from '../../../context/ReportContext';
import { useReportQuery } from '../../../graphql/hooks/useReportQuery';
import { useUpdateReportMutation } from '../../../graphql/hooks/useUpdateReportMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { AutoSaveMemo, OnSaveOptions, OnSaveResponse } from '../../forms/hookform/AutoSave';
import { ReportSaveError } from './ReportSaveError';

interface ReportEditorContentProps {
  reportId: string;
  report: Report;
  isDisabled: boolean;
}

export const ReportEditorContent = (props: React.PropsWithChildren<ReportEditorContentProps>) => {
  const { children, reportId, report } = props;
  const { statementSites, variables } = report;
  const { user, organization } = useAppContext();

  const startingValues = React.useMemo(() => {
    return formValuesFromReport(statementSites, variables);
  }, [statementSites, variables]);

  const { error: mutationError, mutateAsync } = useUpdateReportMutation();

  const onSave = React.useCallback(
    async (options: OnSaveOptions<ReportFormValues>): Promise<OnSaveResponse<ReportFormValues>> => {
      const { dirtyValues, takeOwnership, changesetId, existingChangesetId } = options;

      const variables = Object.values(
        P.mapValues(dirtyValues.variables, (val, id) => ({
          value: val.value,
          id,
        })),
      ).filter((val) => !P.isUndefined(val.value));

      const statementSites = Object.values(
        P.mapValues(dirtyValues.statementSites, (val, id) => {
          const statements = val.statements.map((statement) => ({
            id: statement.id,
            value: statement.value,
          }));

          return {
            id,
            statements,
          };
        }),
      );

      const req = {
        organizationId: organization.id,
        reportId: reportId,
        updateReportRequest: {
          reportTemplateVersionId: report.reportTemplate.versionId,
          existingChangesetId,
          changesetId,
          takeOwnership,
          variables,
          statementSites,
        },
      };

      const result = await mutateAsync(req);

      const updatedReport = result.updateReport;

      return {
        existingChangesetId: updatedReport.changesetId,
        values: {
          statementSites: P.keyBy(
            updatedReport.statementSites as ReportStatementSite[],
            (ss) => ss.id,
          ),
          variables: P.keyBy(updatedReport.variables as ReportVariable[], (v) => v.id),
        },
      };
    },
    [organization.id, reportId, report.reportTemplate.versionId, mutateAsync],
  );

  if (mutationError) {
    return <ReportSaveError error={mutationError} />;
  }

  return (
    <AutoSaveMemo
      debounceMs={1000}
      startingValues={startingValues}
      changesetId={user.id}
      initialExistingChangesetId={report.changesetId}
      onSave={onSave}
      diff={reportFormValuesDiff}
      combine={reportFormValuesCombine}
    >
      {children}
    </AutoSaveMemo>
  );
};

const ReportEditorContentMemo = typedMemo(ReportEditorContent);

interface Props {
  reportId: string;
}

export const ReportEditor = (props: React.PropsWithChildren<Props>) => {
  const { reportId, children } = props;
  const { organization } = useAppContext();

  const hasPermission = usePermission(
    andPermission(PERMISSIONS.reportsReport, PERMISSIONS.reportTemplateRead),
  ).value;

  const { isLoading, isFetching, data, error } = useReportQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const report = data?.organization?.report as Report | undefined;

  if (isFetching && isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (P.isNil(report)) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ReportNotFound',
            message: 'Report not found',
          })
        }
      />
    );
  }

  const reportTemplate = report.reportTemplate as ReportTemplate;
  const isDisabled = report.status === ReportStatus.Finalized || !hasPermission;

  return (
    <ReportContextProvider
      key={`${reportId}-${reportTemplate.id}`}
      reportId={reportId}
      changesetId={report.changesetId}
      timezone={organization.timezone}
      reportTemplate={reportTemplate}
      hasFeature={organization.hasFeature}
      isDisabled={isDisabled}
    >
      <ReportEditorContentMemo reportId={reportId} report={report} isDisabled={isDisabled}>
        {children}
      </ReportEditorContentMemo>
    </ReportContextProvider>
  );
};
