import { Box, Input } from '@chakra-ui/react';
import React from 'react';
import { FormItem } from '../../forms/FormItem';

interface Props {
  userNameFilter: string;
  onUserNameFilter: (value: string) => void;
}

export const UsersSettingsFilter = (props: Props) => {
  const { userNameFilter, onUserNameFilter } = props;

  return (
    <Box width='300px'>
      <FormItem id='name' label='Name'>
        <Input
          size='sm'
          defaultValue={userNameFilter}
          placeholder='Search by name'
          onChange={(event) => onUserNameFilter(event.target.value)}
        />
      </FormItem>
    </Box>
  );
};
