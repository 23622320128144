import { HStack, Text } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Bullet } from './Bullet';

interface Props {
  currentStep: number;
  totalSteps: number;
}

export const BulletSteps = (props: Props) => {
  const { currentStep, totalSteps } = props;

  return (
    <HStack spacing={6} align='center'>
      <Text fontSize='sm' fontWeight='semibold'>
        Step {currentStep} of {totalSteps}
      </Text>
      <HStack spacing={4}>
        {P.range(1, totalSteps + 1).map((step) => (
          <Bullet key={step} isFilled={step <= currentStep} isOutlined={step === currentStep} />
        ))}
      </HStack>
    </HStack>
  );
};
