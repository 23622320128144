import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useCreateWorklistEntryMutation } from '../../../graphql/hooks/useCreateWorklistEntryMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { WorklistEntryForm } from './WorklistEntryForm';

type FormValues = {
  patientFirstName: string;
  patientLastName: string;
  patientId?: string;
  patientDob: string;
  patientSex: string;
  patientHeight?: string;
  patientWeight?: string;
  reportTemplate: { id: string };
  performingPhysicianId: string;
};

export const WorklistItemsSchema = Yup.object({
  patientFirstName: Yup.string().nullable().required('Patients first name is required'),
  patientLastName: Yup.string().nullable().required('Patients first name is required'),
  patientId: Yup.string().nullable(),
  patientDob: Yup.date().required('Patients date of birth is required'),
  patientSex: Yup.string().required('Patients sex is required'),
  patientHeight: Yup.number(),
  patientWeight: Yup.number(),
  reportTemplate: Yup.object({
    id: Yup.string(),
  })
    .default(undefined)
    .required('Report type is required'),
  performingPhysicianId: Yup.string().required('Cardiologist is required'),
});

export const WorklistEntryAddModal = createModal<{}>((props) => {
  const { organization, successToast, errorToast } = useAppContext();

  const { modal } = props;
  const { hide, visible } = modal;

  const initialValues: Partial<FormValues> = {
    patientSex: 'male',
  };

  const methods = useForm({
    defaultValues: initialValues as any,
    mode: 'all',
    resolver: yupResolver(WorklistItemsSchema),
  });

  const mutation = useCreateWorklistEntryMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: {
            patientFirstName: values.patientFirstName,
            patientLastName: values.patientLastName,
            patientId: values.patientId,
            patientDob: values.patientDob as unknown as DateTime,
            patientSex: values.patientSex,
            patientHeight: isNaN(Number(values.patientHeight))
              ? null
              : Number(values.patientHeight),
            patientWeight: isNaN(Number(values.patientWeight))
              ? null
              : Number(values.patientWeight),
            reportTemplateId: values.reportTemplate.id,
            performingPhysicianId: values.performingPhysicianId,
          },
        })
        .then(() => {
          hide();
          successToast('Worklist entry created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating worklist entry: ${err.message}`);
        });
    },
    [mutation, organization.id, hide, errorToast, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Add a worklist entry</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <WorklistEntryForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={hide}>Close</Button>
                <HookedSubmitButton>Add worklist entry</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
