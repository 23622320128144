import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BillingActionType,
  PermissionName,
  ReportTemplate,
  ReportTemplateStatus,
  renderReportTemplateStatus,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateReportTemplateMutation } from '../../../graphql/hooks/useUpdateReportTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormSection } from '../../forms/FormSection';
import { HookedBillingActionChooser } from '../../forms/hookform/HookedBillingActionChooser';
import { HookedColorPicker } from '../../forms/hookform/HookedColorPicker';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedResetButton } from '../../forms/hookform/HookedResetButton';
import { HookedSelect } from '../../forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { Divider } from '../../generic/Divider';
import { createModal } from '../../generic/Modal';

interface FormValues {
  name: string;
  labelColor: string;
  status: ReportTemplateStatus;
  billingActionId?: BillingActionType | null;
}

interface Props {
  reportTemplate: ReportTemplate;
}

export const ReportTemplateSettingsModal = createModal<Props>((props) => {
  const { modal, reportTemplate } = props;
  const { user, organization, successToast, errorToast } = useAppContext();

  const hasAdminPermission = user.extraPermissions.includes(PermissionName.Admin);

  const initialValues: FormValues = {
    name: reportTemplate.name,
    labelColor: reportTemplate.labelColor,
    status: reportTemplate.status,
    billingActionId: reportTemplate.billingActionId,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    status: Yup.mixed<ReportTemplateStatus>().oneOf(Object.values(ReportTemplateStatus)).required(),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useUpdateReportTemplateMutation();

  const onSubmit = async (values: Partial<FormValues>) => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportTemplateId: reportTemplate.id,
        request: {
          name: values.name,
          labelColor: values.labelColor,
          status: values.status,
          billingActionId: values.billingActionId,
        },
      })
      .then(() => {
        successToast('Report template settings updated successfully');
      })
      .catch((err) => {
        errorToast(`Error updating report template settings: ${err.message}`);
      });
  };

  const statusOptions = Object.values(ReportTemplateStatus).map((status) => {
    return { label: renderReportTemplateStatus(status), value: status, raw: status };
  });

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Report template settings</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection heading='General'>
                <HookedFormItem label='Name' name='name'>
                  <HookedInput name='name' data-pw='reportTemplateSettingsModalNameInput' />
                </HookedFormItem>
                <HookedFormItem label='Status' name='status'>
                  <HookedSelect
                    name='status'
                    options={statusOptions}
                    data-pw='reportTemplateSettingsModalStatusSelect'
                  />
                </HookedFormItem>
                <HookedFormItem label='Color' name='labelColor'>
                  <HookedColorPicker
                    name='labelColor'
                    data-pw='reportTemplateSettingsModalColorPicker'
                  />
                </HookedFormItem>
                {hasAdminPermission && (
                  <HookedFormItem label='Billing Action' name='billingActionId'>
                    <HookedBillingActionChooser name='billingActionId' />
                  </HookedFormItem>
                )}
              </FormSection>
            </ModalBody>
            <Divider />
            <ModalFooter>
              <ButtonGroup>
                <HookedResetButton>Reset</HookedResetButton>
                <HookedSubmitButton data-pw='reportTemplateSettingsModalSubmitButton'>
                  Submit
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
