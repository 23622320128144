import React from 'react';
import { useHealthLinkIntegrationSettingsQuery } from '../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ProviderFromChooser } from './ProviderFromChooser';

type HealthLinkProvider = {
  providerNumber: string;
  name: string;
};

export interface HealthLinkProviderFromChooserProps {
  value: HealthLinkProvider | null;
  onChange: (value: HealthLinkProvider) => void;
  reportId?: string;
  isDisabled?: boolean;
}

export const HealthLinkProviderFromChooser = (props: HealthLinkProviderFromChooserProps) => {
  const { organization } = useAppContext();

  const { data, isLoading } = useHealthLinkIntegrationSettingsQuery({
    organizationId: organization.id,
  });

  const mappings = data?.organization?.integrations.healthLink.settings.providerMappings ?? [];

  return (
    <ProviderFromChooser
      {...props}
      mappings={mappings}
      isLoading={isLoading}
      data-pw='distributionModalHealthLinkFromProviderNumberChooser'
    />
  );
};
