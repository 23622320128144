import type { ButtonProps as ChakraButtonProps } from 'chakra-ui-v3';
import { AbsoluteCenter, Button as ChakraButton, Span, Spinner } from 'chakra-ui-v3';
import React, { forwardRef } from 'react';

export interface ButtonLoadingProps {
  loading?: boolean;
  loadingText?: React.ReactNode;
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const { loading, disabled, loadingText, children, ...rest } = props;
  return (
    <ChakraButton disabled={loading || disabled} ref={ref} {...rest}>
      {loading && !loadingText ? (
        <>
          <AbsoluteCenter display='inline-flex'>
            <Spinner size='inherit' color='inherit' />
          </AbsoluteCenter>
          <Span opacity={0}>{children}</Span>
        </>
      ) : loading && loadingText ? (
        <>
          <Spinner size='inherit' color='inherit' />
          {loadingText}
        </>
      ) : (
        children
      )}
    </ChakraButton>
  );
});
