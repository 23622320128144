import { Heading } from '@chakra-ui/react';
import { User } from '@piccolohealth/echo-common';
import { MultiSelect, MultiSelectOption, OnChangeRequest } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useUsersMinimalQuery } from '../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportsFilter } from '../../../hooks/useReportsFilter';

const userToOption = (user: User): MultiSelectOption<User> => {
  return {
    label: user.name,
    value: user.name,
    raw: user,
  };
};

interface Props {
  filter: ReportsFilter;
}

export const AssignedToFilterControl = ({ filter }: Props) => {
  const { onAssignedToFilter, assignedToFilter } = filter;
  const { organization } = useAppContext();
  const { data } = useUsersMinimalQuery({ organizationId: organization.id });

  const users = (data?.organization?.users as User[]) || [];
  const options = users.map(userToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<User>) => {
      onAssignedToFilter(req.values.map((item) => item.value));
    },
    [onAssignedToFilter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(assignedToFilter.map((v) => options.find((o) => P.isEqual(o.value, v))));
  }, [assignedToFilter, options]);

  return (
    <>
      <Heading as='h5' size='sm' pb='2'>
        Assigned to
      </Heading>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All users'
        data-pw='filterByAssignedToMultiselect'
      />
    </>
  );
};
