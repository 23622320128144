import { HStack } from '@chakra-ui/react';
import { FeatureFlag, PERMISSIONS, orPermission, uuid } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { NavButton } from '../generic/NavButton';

export const LinksNav = () => {
  const { auth, organization } = useAppContext();
  const hasReportsPermission = usePermission(
    orPermission(PERMISSIONS.reportsRead, PERMISSIONS.reportsReadShared),
  ).value;

  const hasWorklistPermission = usePermission(orPermission(PERMISSIONS.worklistRead)).value;
  const hasWorklistFeature = organization.hasFeature(FeatureFlag.Worklist);

  if (auth.isAuthenticated && hasReportsPermission) {
    return (
      <HStack spacing={4}>
        <NavButton
          to={`/organizations/${organization.id}/reports`}
          replace
          state={uuid()}
          bg='transparent'
          _active={{
            bg: 'purple.500',
          }}
        >
          Reports
        </NavButton>
        {hasWorklistPermission && hasWorklistFeature && (
          <NavButton
            to={`/organizations/${organization.id}/worklist`}
            bg='transparent'
            _active={{
              bg: 'purple.500',
            }}
          >
            Worklist
          </NavButton>
        )}
      </HStack>
    );
  }

  return null;
};
