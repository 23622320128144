import { Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ResetPasswordButton = () => {
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    navigate('/request-reset-password');
  };
  return (
    <Button variant='link' w='fit-content' cursor='pointer' onClick={handleForgotPassword}>
      Forgot your password?
    </Button>
  );
};
