import { IconButton, Stack } from '@chakra-ui/react';
import { FloatingPopover, typedMemo } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { VariablesBySite } from './sidebar/MeasurementsSidebar';
import { AreaMeasurementStep } from './steps/AreaMeasurementStep';
import { LinearMeasurementStep } from './steps/LinearMeasurementStep';
import { MeasurementTypeSelectionStep } from './steps/MeasurementTypeSelectionStep';
import { VolumeMeasurementStep } from './steps/VolumeMeasurementStep';
import { UseMeasurementControlReturn } from './tool/hooks/useMeasurementControl';

interface Props {
  measurementControl: UseMeasurementControlReturn;
  variables: VariablesBySite;
}

export const AddMeasurementControl = typedMemo((props: Props) => {
  const { measurementControl, variables } = props;

  const content = P.run(() => {
    switch (measurementControl.step) {
      case 'typeSelection':
        return (
          <MeasurementTypeSelectionStep
            measurementControl={measurementControl}
            variables={variables}
          />
        );
      case 'linear':
        return (
          <LinearMeasurementStep measurementControl={measurementControl} variables={variables} />
        );
      case 'area':
        return (
          <AreaMeasurementStep measurementControl={measurementControl} variables={variables} />
        );
      case 'volume':
        return (
          <VolumeMeasurementStep measurementControl={measurementControl} variables={variables} />
        );
    }
  });

  return (
    <FloatingPopover
      open={measurementControl.isOpen}
      setOpen={(isOpen) => (isOpen ? measurementControl.onOpen() : measurementControl.onClose())}
      placement='bottom-end'
      isRoot={false}
      closeOnBlur={false}
      render={() => (
        <Stack w='310px' bg='gray.700' px={3} py={3} pb={4} rounded='lg'>
          {content}
        </Stack>
      )}
    >
      <IconButton
        aria-label='Add new measurement'
        icon={<FaPlus />}
        size='sm'
        variant='solid'
        colorScheme='purple'
      />
    </FloatingPopover>
  );
});
