import { Box, BoxProps, Flex, FlexProps, Spacer, Text, forwardRef } from '@chakra-ui/react';
import React from 'react';

export const CardContent = (props: BoxProps) => <Box {...props} />;

interface PropertyProps extends FlexProps {
  label: JSX.Element;
  value: JSX.Element;
}

export const Property = (props: PropertyProps) => {
  const { label, value, ...flexProps } = props;
  return (
    <Flex as='dl' flexDir='row' px='6' py='2' {...flexProps}>
      <Box as='dt'>{label}</Box>
      <Spacer mx={2} />
      <Box as='dd'>{value}</Box>
    </Flex>
  );
};

interface CardHeaderProps extends Omit<FlexProps, 'title'> {
  title: React.ReactNode;
  action?: React.ReactNode;
}

export const CardHeader = (props: CardHeaderProps) => {
  const { title, action, ...rest } = props;
  return (
    <Flex align='center' justify='space-between' fontSize='xl' fontWeight='semibold' {...rest}>
      <Text>{title}</Text>
      {action}
    </Flex>
  );
};

export const Card = forwardRef((props: FlexProps, ref) => (
  <Flex ref={ref} direction='column' rounded='2xl' shadow='md' borderWidth='1px' p={4} {...props} />
));
