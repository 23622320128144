import { Button, ButtonGroup, HStack, Select, Text } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';

export interface PaginationOptions {
  total: number;
  currentPage: number;
  pageSize: number;
  pageSizeOptions: number[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  showTotal: (total: number, range: [number, number]) => string;
  onPageSizeChange: (pageSize: number) => void;
}

export interface TimestampPaginationOptions {
  total?: number;
  pageSize: number;
  pageSizeOptions: number[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  showTotal?: (total: number) => string;
  onPageSizeChange: (pageSize: number) => void;
}

export const Pagination = (props: PaginationOptions | TimestampPaginationOptions) => {
  const total = P.run(() => {
    if (!props.showTotal || !props.total) {
      return null;
    }

    if ('currentPage' in props) {
      return props.showTotal(props.total, [
        props.currentPage * props.pageSize + 1,
        Math.min((props.currentPage + 1) * props.pageSize, props.total),
      ]);
    }

    return props.showTotal(props.total);
  });

  return (
    <HStack alignItems='center'>
      <Text data-pw='paginationTotalText' color='secondary'>
        {total}
      </Text>

      <ButtonGroup size='sm'>
        <Button isDisabled={!props.hasPreviousPage} onClick={() => props.previousPage()}>
          Prev
        </Button>
        <Button isDisabled={!props.hasNextPage} onClick={() => props.nextPage()}>
          Next
        </Button>
        <Select
          size='sm'
          value={props.pageSize}
          onChange={(e) => props.onPageSizeChange(P.parseNumeric(e.target.value) ?? 1)}
        >
          {props.pageSizeOptions.map((p) => (
            <option key={p} value={p}>
              {p} / page
            </option>
          ))}
        </Select>
      </ButtonGroup>
    </HStack>
  );
};
