import {
  FloatingContext,
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  FloatingTree,
  Middleware,
  Placement,
  ReferenceType,
  UseFloatingProps,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import React from 'react';

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    visibility: 'visible',
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    transitionEnd: {
      visibility: 'hidden',
    },
    opacity: 0,
    transition: {
      duration: 0.1,
      easings: 'easeOut',
    },
  },
};

export const FloatingPortalContent = (props: {
  children: JSX.Element;
  isPortal?: boolean;
  portalRootId?: string;
}) => {
  // If the portalRootId is not provided, we will use the default portal root
  const portalElement = props.portalRootId
    ? document.getElementById(props.portalRootId)
    : undefined;

  return props.isPortal ? (
    <FloatingPortal root={portalElement}>{props.children}</FloatingPortal>
  ) : (
    <>{props.children}</>
  );
};

export const FloatingFocusManagerContent = (props: {
  children: JSX.Element;
  context: FloatingContext<ReferenceType>;
  shouldInitialFocus?: boolean;
}) => {
  return props.shouldInitialFocus ? (
    <FloatingFocusManager
      context={props.context}
      modal={false}
      order={['content']}
      returnFocus={false}
    >
      {props.children}
    </FloatingFocusManager>
  ) : (
    <>{props.children}</>
  );
};

export const FloatingOverlayContent = (props: { children: JSX.Element; isRoot?: boolean }) => {
  return props.isRoot ? (
    <FloatingOverlay lockScroll={false} style={{ zIndex: 9999 }}>
      {props.children}
    </FloatingOverlay>
  ) : (
    <>{props.children}</>
  );
};

interface Props {
  enabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  clientRect?: () => DOMRect;
  render: (data: { context: FloatingContext<ReferenceType> }) => React.ReactNode;
  middleware?: Middleware[];
  trigger?: 'hover' | 'focus' | 'click';
  children?: JSX.Element;
  isRoot?: boolean;
  isPortal?: boolean;
  portalRootId?: string;
  shouldInitialFocus?: boolean;
  closeOnBlur?: boolean;
  placement?: Placement;
}

const FloatingPopoverInternal = (props: Props) => {
  const {
    middleware,
    children,
    isRoot,
    isPortal,
    portalRootId,
    shouldInitialFocus,
    closeOnBlur,
    placement,
    render,
  } = props;
  const nodeId = useFloatingNodeId();

  const [isOpen, setIsOpen] = React.useState<boolean>(props.open || false);
  const open = props.open ?? isOpen;
  const trigger = props.trigger ?? 'click';

  const onOpenChange = React.useCallback(
    (value: boolean) => {
      props.setOpen ? props.setOpen(value) : setIsOpen(value);
    },
    [props],
  );

  const options: Partial<UseFloatingProps> = {
    open: open,
    onOpenChange,
    nodeId,
    whileElementsMounted: autoUpdate,
    placement: placement ?? 'right-start',
    strategy: 'fixed',
    middleware: middleware ?? [
      offset(10),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  };

  const { x, y, reference, floating, strategy, context } = useFloating(options);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: trigger === 'click',
    }),
    useHover(context, {
      enabled: trigger === 'hover',
      handleClose: safePolygon({
        blockPointerEvents: false,
      }),
      delay: {
        close: 200,
        open: 300,
      },
    }),
    useFocus(context, {
      enabled: trigger === 'focus',
      keyboardOnly: false,
    }),
    useDismiss(context, {
      ancestorScroll: true,
      outsidePress: closeOnBlur ?? true,
    }),
  ]);

  React.useEffect(() => {
    if (props.clientRect) {
      reference({
        getBoundingClientRect: props.clientRect,
      });
    }
  }, [props.clientRect, reference]);

  const ref = reference;

  return (
    <FloatingNode id={nodeId}>
      {children && React.cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {open && (
        <FloatingPortalContent isPortal={isPortal} portalRootId={portalRootId}>
          <AnimatePresence>
            {open && (
              <FloatingOverlayContent isRoot={isRoot}>
                <FloatingFocusManagerContent
                  context={context}
                  shouldInitialFocus={shouldInitialFocus}
                >
                  <motion.div
                    initial='initial'
                    animate='enter'
                    exit='exit'
                    variants={variants}
                    {...getFloatingProps({
                      ref: floating,
                      style: {
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        boxSizing: 'border-box',
                        zIndex: 9999,
                      },
                    })}
                    onKeyDown={(e) => {
                      const el = context.refs.domReference.current;
                      if (e.shiftKey && e.key === 'Tab') {
                        (el as any).focus();
                      }
                    }}
                  >
                    {render({ context })}
                  </motion.div>
                </FloatingFocusManagerContent>
              </FloatingOverlayContent>
            )}
          </AnimatePresence>
        </FloatingPortalContent>
      )}
    </FloatingNode>
  );
};

export const FloatingPopover = (props: Props) => {
  const isRoot = props.isRoot ?? true;
  const enabled = props.enabled ?? true;
  const parentId = useFloatingParentNodeId();

  if (!enabled) {
    return <>{props.children}</>;
  }

  if (parentId == null) {
    return (
      <FloatingTree>
        <FloatingPopoverInternal {...props} isRoot={isRoot} />
      </FloatingTree>
    );
  }

  return <FloatingPopoverInternal {...props} />;
};
