import { Badge, Box, Text } from '@chakra-ui/react';
import { ReportAttachment } from '@piccolohealth/echo-common';
import { DescriptionList, FancyDate } from '@piccolohealth/ui';
import { DateTime, getExtension } from '@piccolohealth/util';
import React from 'react';

interface Props {
  attachment: ReportAttachment;
}

export const AttachmentSummary = (props: Props) => {
  const { attachment } = props;

  const items = [
    {
      title: 'Date',
      content: (
        <FancyDate date={DateTime.fromISO(attachment.createdAt.toString())} direction='row' />
      ),
    },
    {
      title: 'Uploader',
      content: <Text noOfLines={1}>{attachment.uploader?.name}</Text>,
    },
    {
      title: 'File name',
      content: <Text noOfLines={2}>{attachment.name}</Text>,
    },
    {
      title: 'File type',
      content: <Badge>{getExtension(attachment.name)}</Badge>,
    },
  ];

  return (
    <Box w='full' px={2} mt={2} fontSize='md' fontWeight='normal'>
      <DescriptionList items={items} rowGap={4} fontSize='sm' wordBreak='break-all' />
    </Box>
  );
};
