import * as Types from '@piccolohealth/echo-common';
import { useInfiniteQuery } from 'react-query';
import { InfiniteQueryOptions, createGqlQuery } from '../fetcher';
import {
  FaxIntegrationSettings,
  HealthLinkIntegrationPractitioners,
  HealthLinkIntegrationSettings,
  MedicalObjectsIntegrationPracticeProviders,
  MedicalObjectsIntegrationProviders,
  MedicalObjectsIntegrationSettings,
} from '../queries/integrations.query';

export const useMedicalObjectsIntegrationSettingsQuery = createGqlQuery<
  Types.MedicalObjectsIntegrationSettingsQueryVariables,
  Types.MedicalObjectsIntegrationSettingsQuery
>(
  (variables) => ['integrations', 'medicalObjects', variables, 'settings'],
  MedicalObjectsIntegrationSettings,
);

export const useMedicalObjectsIntegrationPracticeProvidersQuery = createGqlQuery<
  Types.MedicalObjectsIntegrationPracticeProvidersQueryVariables,
  Types.MedicalObjectsIntegrationPracticeProvidersQuery
>(
  (variables) => ['integrations', 'medicalObjects', variables, 'practiceProviders'],
  MedicalObjectsIntegrationPracticeProviders,
);

export const useMedicalObjectsIntegrationProvidersQuery = createGqlQuery<
  Types.MedicalObjectsIntegrationProvidersQueryVariables,
  Types.MedicalObjectsIntegrationProvidersQuery
>(
  (variables) => ['integrations', 'medicalObjects', variables, 'providers'],
  MedicalObjectsIntegrationProviders,
);

export const useMedicalObjectsIntegrationProvidersInfiniteQuery = (
  variables: Types.MedicalObjectsIntegrationProvidersQueryVariables,
  options?: InfiniteQueryOptions<Types.MedicalObjectsIntegrationProvidersQuery>,
) => {
  const fetcher = useMedicalObjectsIntegrationProvidersQuery.getFetcher();

  return useInfiniteQuery(
    useMedicalObjectsIntegrationProvidersQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage.organization?.integrations.medicalObjects.providers.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );
};

export const useHealthLinkIntegrationSettingsQuery = createGqlQuery<
  Types.HealthLinkIntegrationSettingsQueryVariables,
  Types.HealthLinkIntegrationSettingsQuery
>(
  (variables) => ['integrations', 'healthLink', variables, 'settings'],
  HealthLinkIntegrationSettings,
);

export const useHealthLinkIntegrationPractitionersQuery = createGqlQuery<
  Types.HealthLinkIntegrationPractitionersQueryVariables,
  Types.HealthLinkIntegrationPractitionersQuery
>(
  (variables) => ['integrations', 'healthLink', variables, 'practitioners'],
  HealthLinkIntegrationPractitioners,
);

export const useFaxIntegrationSettingsQuery = createGqlQuery<
  Types.FaxIntegrationSettingsQueryVariables,
  Types.FaxIntegrationSettingsQuery
>((variables) => ['integrations', 'fax', variables, 'settings'], FaxIntegrationSettings);
