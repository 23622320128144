import { Icon } from '@chakra-ui/react';
import { Command } from '@piccolohealth/ui';
import React from 'react';
import { FaFlask, FaListUl } from 'react-icons/fa';
import { TiptapCommandMenuState } from '../contextMenuRenderer';
import { dropdownsPages } from './dropdowns';
import { measurementsPages } from './measurements';

export const statementEditingPage =
  (site: string) =>
  (state: TiptapCommandMenuState): Command.Page<TiptapCommandMenuState>[] => {
    return [
      {
        kind: 'actions',
        id: 'ROOT',
        title: 'What would you like to do?',
        searchable: false,
        actions: [
          {
            kind: 'goto',
            id: 'dropdowns',
            title: 'Dropdowns',
            raw: 'Dropdowns',
            description: 'Manage dropdowns',
            leftIcon: () => <Icon as={FaListUl} />,
            tooltip: () => ({
              kind: 'image',
              src: '/screenshots/dropdowns.png',
              description: 'Create a new dropdown or add from your existing dropdowns',
            }),
          },
          {
            kind: 'goto',
            id: 'measurements',
            title: 'Measurements',
            raw: 'Measurements',
            description: 'Add a measurement',
            leftIcon: () => <Icon as={FaFlask} />,
            tooltip: () => ({
              kind: 'image',
              src: '/screenshots/measurements.png',
              description: 'Add a measurement from a list of known measurements',
            }),
          },
        ],
      },
      ...dropdownsPages(site)(state),
      ...measurementsPages(state),
    ];
  };
