import { Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { FaCompressArrowsAlt, FaExpand } from 'react-icons/fa';
import { ControlTooltip } from './ControlTooltip';

export const isFullScreenApiAvailable = () =>
  document.fullscreenEnabled ||
  (document as any).mozFullscreenEnabled ||
  (document as any).webkitFullscreenEnabled ||
  (document as any).msFullscreenEnabled;

interface Props {
  currentFullscreen: boolean;
  onChangeFullscreen: (value: boolean) => void;
}

export const FullScreenControl = (props: Props) => {
  const { currentFullscreen, onChangeFullscreen } = props;

  const fullscreenApiAvailable = isFullScreenApiAvailable();

  if (!fullscreenApiAvailable) {
    return null;
  }

  return (
    <ControlTooltip label='Toggle fullscreen'>
      <Flex>
        <IconButton
          aria-label='Add new measurement'
          icon={currentFullscreen ? <FaCompressArrowsAlt size='32px' /> : <FaExpand size='32px' />}
          variant='imageViewer'
          size='sm'
          colorScheme='purple'
          onClick={() => onChangeFullscreen(!currentFullscreen)}
          color='purple.500'
          _hover={{
            color: 'purple.300',
          }}
          _active={{
            color: 'purple.300',
          }}
        />
      </Flex>
    </ControlTooltip>
  );
};
