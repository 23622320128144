import {
  Avatar,
  Badge,
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Spacer,
  Stack,
  SystemStyleObject,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  MeasurementMappingAndVariants,
  MeasurementProperty,
  MeasurementVariant,
} from '@piccolohealth/echo-common';
import { ScrollArea, typedMemo } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import {
  ControlledTreeEnvironment,
  Tree,
  TreeItem,
  TreeItemRenderContext,
} from 'react-complex-tree';
import { FaChartLine, FaRegFolder, FaRegFolderOpen, FaTimes } from 'react-icons/fa';
import { TruncatedBadge } from '../../../../components/generic/TruncatedBadge';
import { useMappingsAndVariantsTreeState } from '../../hooks/useMappingsAndVariantsTreeState';
import { MachineTag } from './MachineTag';
import { MeasurementMappingActionsMenu } from './MeasurementMappingActionsMenu';
import { TreeData, getParentOfItem } from './utils';

interface DetailsGridProps {
  shortName?: string | null;
  site?: string | null;
  units?: string | null;
  properties: MeasurementProperty[];
}

export const DetailsGrid = (props: DetailsGridProps) => {
  const { shortName, site, units, properties } = props;

  return (
    <SimpleGrid
      w='full'
      columns={2}
      rowGap={0}
      columnGap={4}
      templateColumns='max-content auto'
      alignItems='start'
    >
      {[
        {
          title: <Text fontWeight='bold'>Short name</Text>,
          content: <Text>{shortName ?? '-'}</Text>,
        },
        {
          title: <Text fontWeight='bold'>Site</Text>,
          content: <Text>{site ?? '-'}</Text>,
        },
        {
          title: <Text fontWeight='bold'>Units</Text>,
          content: <Text>{units ?? '-'}</Text>,
        },
        ...properties.map((property) => ({
          title: <Text fontWeight='bold'>{property.name}</Text>,
          content: <Text>{property.value}</Text>,
        })),
      ].map((item, index) => (
        <React.Fragment key={index}>
          <Box>{item.title}</Box>
          <Box>{item.content}</Box>
        </React.Fragment>
      ))}
    </SimpleGrid>
  );
};

const CustomTreeItem = (props: {
  item: TreeItem<TreeData>;
  title: React.ReactNode;
  arrow: React.ReactNode;
  context: TreeItemRenderContext<string>;
  depth: number;
  children: React.ReactNode | null;
}) => {
  const { context, arrow, title, depth, children } = props;

  const style = {
    ...(context.isFocused ? { bg: 'gray.100' } : {}),
    ...(context.isSelected ? { bg: 'gray.100' } : {}),
    ...(context.isDraggingOver ? { bg: 'gray.200' } : {}),
    _hover: {
      bg: 'gray.50',
    },
  };

  return (
    <Box {...(context.itemContainerWithChildrenProps as any)}>
      <HStack
        {...(context.itemContainerWithoutChildrenProps as any)}
        {...(context.interactiveElementProps as any)}
        {...style}
        align='center'
        spacing={2}
        pl={`${depth * 20}px`}
        py={1}
      >
        <Box w={4}>{arrow}</Box>
        <Icon fontSize='18px' as={context.isExpanded ? FaRegFolderOpen : FaRegFolder} />
        {title}
      </HStack>

      {children}
    </Box>
  );
};

const TreeItemMemo = typedMemo(CustomTreeItem);

interface ItemTitleProps {
  item: TreeItem<TreeData>;
  parent: TreeItem<TreeData> | null;
  isExpanded: boolean;
  removeItem: (item: TreeItem<TreeData>) => void;
}

const ItemTitle = (props: ItemTitleProps) => {
  const { item, isExpanded, parent, removeItem } = props;

  const height = isExpanded ? '140px' : '24px';

  const text = P.run(() => {
    switch (item.data.type) {
      case 'root': {
        return null;
      }
      case 'site': {
        return (
          <Stack
            spacing={1}
            align='start'
            justify='center'
            fontSize='sm'
            w='full'
            h={height}
            borderLeftWidth='2px'
            borderLeftColor='gray.300'
            pl={2}
          >
            <Text fontWeight='bold'>{item.data.title}</Text>
          </Stack>
        );
      }
      case 'name': {
        return (
          <Stack
            spacing={1}
            align='start'
            justify='center'
            fontSize='sm'
            w='full'
            h={height}
            borderLeftWidth='2px'
            borderLeftColor='gray.300'
            pl={2}
          >
            <Text fontWeight='bold'>{item.data.title}</Text>
          </Stack>
        );
      }
      case 'hash': {
        return (
          <Stack
            spacing={1}
            align='start'
            justify='center'
            fontSize='sm'
            w='full'
            h={height}
            borderLeftWidth='2px'
            borderLeftColor='gray.300'
            pl={2}
          >
            <HStack spacing={1}>
              <Tooltip
                label={
                  <Stack>
                    <Text fontSize='md' fontWeight='bold'>
                      Hash variants properties
                    </Text>
                    <Divider />
                    <DetailsGrid
                      site={item.data.variant.site}
                      units={item.data.variant.units}
                      properties={item.data.variant.properties}
                    />
                  </Stack>
                }
              >
                <Text fontSize='sm' fontWeight='bold'>
                  {item.data.variant.name}
                </Text>
              </Tooltip>
              <TruncatedBadge text={item.data.variant.hash} length={8} tooltip='Copy hash' />
              <Badge variant='subtle'>
                {item.data.variantCount.toString()}{' '}
                {inflection.inflect('variant', item.data.variantCount)}
              </Badge>

              {parent?.data.type === 'mapping' && (
                <IconButton
                  size='xs'
                  aria-label='Remove variant hash from mapping'
                  variant='ghost'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeItem(item);
                  }}
                  icon={<Icon as={FaTimes} />}
                />
              )}
            </HStack>
          </Stack>
        );
      }
      case 'variant': {
        return (
          <Stack
            spacing={1}
            align='start'
            justify='center'
            fontSize='sm'
            w='full'
            h={height}
            borderLeftWidth='2px'
            borderLeftColor='gray.300'
            pl={2}
          >
            <HStack spacing={1}>
              <MachineTag machine={item.data.variant.machine} />
              <TruncatedBadge text={item.data.variant.id} length={8} tooltip='Copy variant ID' />
            </HStack>
          </Stack>
        );
      }
      case 'mapping':
        return (
          <Stack
            spacing={1}
            align='start'
            justify='center'
            fontSize='sm'
            w='full'
            h={height}
            borderLeftWidth='2px'
            borderLeftColor='gray.300'
            pl={2}
          >
            <HStack w='full' spacing={1}>
              <Tooltip
                label={
                  <Stack>
                    <Text fontSize='md' fontWeight='bold'>
                      Mapping properties
                    </Text>
                    <Divider />
                    <DetailsGrid
                      shortName={item.data.mapping.shortName}
                      site={item.data.mapping.site}
                      units={item.data.mapping.units}
                      properties={item.data.mapping.properties}
                    />
                  </Stack>
                }
              >
                <Text fontWeight='bold'>{item.data.mapping.name}</Text>
              </Tooltip>
              <Text>({item.data.mapping.alias})</Text>
              <TruncatedBadge text={item.data.mapping.id} length={8} tooltip='Copy mapping ID' />
              <Badge variant='subtle'>
                {item.data.hashCount.toString()} {inflection.inflect('hash', item.data.hashCount)}
              </Badge>
              {item.data.mapping.rangeGroup && (
                <Tooltip label='Range group enabled' fontSize='sm'>
                  <Box>
                    <FaChartLine color='green' />
                  </Box>
                </Tooltip>
              )}
              <Spacer />
              <MeasurementMappingActionsMenu measurementMapping={item.data.mapping} />
            </HStack>
          </Stack>
        );
    }
  });

  const icon = P.run(() => {
    switch (item.data.type) {
      case 'root':
        return null;
      case 'site':
        return <Avatar name='S' size='sm' boxSize='18px' fontWeight='bold' bg='blue.500' />;
      case 'name':
        return <Avatar name='N' size='sm' boxSize='18px' fontWeight='bold' bg='green.500' />;
      case 'hash':
        return <Avatar name='H' size='sm' boxSize='18px' fontWeight='bold' bg='red.500' />;
      case 'variant':
        return <Avatar name='V' size='sm' boxSize='18px' fontWeight='bold' bg='teal.500' />;
      case 'mapping':
        return <Avatar name='M' size='sm' boxSize='18px' fontWeight='bold' bg='purple.500' />;
    }
  });

  return (
    <HStack w='full' align='center' lineHeight='normal'>
      {icon}
      {text}
      <Spacer />
    </HStack>
  );
};

const ItemTitleMemo = typedMemo(ItemTitle);

interface Props {
  variants: MeasurementVariant[];
  mappings: MeasurementMappingAndVariants[];
  isExpanded: boolean;
}

export const MeasurementMappingsAndVariantsTree = (props: Props) => {
  const { variants, mappings, isExpanded } = props;

  const treeState = useMappingsAndVariantsTreeState({ variants, mappings });

  const css: SystemStyleObject = {
    '.item-container-without-children': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      py: 1,
    },
    '.arrow': {
      w: 4,
    },
  };

  return (
    <ControlledTreeEnvironment<TreeData>
      canDragAndDrop
      canDropOnFolder
      // autoFocus=false - important: causes weird glitches where the trees
      // will scroll to top on drag and drop
      autoFocus={false}
      canReorderItems
      items={treeState.treeState}
      getItemTitle={(item) => item.data.title}
      viewState={treeState.viewState}
      onFocusItem={treeState.onFocusItem}
      onExpandItem={treeState.onExpandItem}
      onCollapseItem={treeState.onCollapseItem}
      onSelectItems={treeState.onSelectItem}
      canDrag={treeState.canDrag}
      canDropAt={treeState.canDropAt}
      onDrop={treeState.onDrop}
      renderItemTitle={({ item }) => (
        <ItemTitleMemo
          removeItem={treeState.removeItem}
          item={item}
          isExpanded={isExpanded}
          parent={getParentOfItem(treeState.treeState, item.index)}
        />
      )}
      renderItem={(props) => (
        <TreeItemMemo
          title={props.title}
          arrow={props.arrow}
          context={props.context}
          item={props.item}
          depth={props.depth}
        >
          {props.children}
        </TreeItemMemo>
      )}
    >
      <HStack w='full' h='full' align='start' spacing={8} userSelect='none' __css={css}>
        <ScrollArea h='full' w='full' p={2} border='1px solid' borderColor='gray.300' rounded='xl'>
          <Tree treeId='V-TREE' rootItem='V-ROOT' treeLabel='Variants tree' />
        </ScrollArea>
        <ScrollArea h='full' w='full' p={2} border='1px solid' borderColor='gray.300' rounded='xl'>
          <Tree treeId='M-TREE' rootItem='M-ROOT' treeLabel='Mappings tree' />
        </ScrollArea>
      </HStack>
    </ControlledTreeEnvironment>
  );
};
