import { Text } from '@chakra-ui/react';
import { PiccoloError } from '@piccolohealth/echo-common';
import React from 'react';
import { sendExceptionToSentry } from '../../../utils/errors';

interface Props {
  variableId: string;
  message: string;
}

export const ReportVariableRenderingError = (props: Props) => {
  const { variableId, message } = props;

  React.useEffect(() => {
    sendExceptionToSentry(
      new PiccoloError({
        type: 'ReportVariableRenderingError',
        message,
        metadata: {
          variableId,
        },
      }),
    );
  }, [variableId, message]);

  return (
    <Text color='red.500' fontSize='sm' fontWeight='semibold'>
      {message}
    </Text>
  );
};
