import {
  Button,
  Flex,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { WallMotionValues, getWallMotionModule } from '@piccolohealth/echo-common';
import React from 'react';
import { useController } from 'react-hook-form';
import { WallMotionAnatomicalControl, WallMotionBullseyeControl } from '../wallmotion';

interface Props {
  name: string;
  isDisabled?: boolean;
}

export const HookedWallMotionControl = (props: Props) => {
  const { name, isDisabled } = props;

  const { field } = useController({ name: name });
  const { onChange } = field;
  const module = getWallMotionModule(field.value?.variant);
  const complexValues =
    (field.value?.wmComplex as WallMotionValues) ?? module.defaultWmComplexValues;

  const setWmComplex = React.useCallback(
    (wallKey: string, scoreKey: string) => {
      const wmComplex: WallMotionValues = {
        ...complexValues,
        [wallKey]: scoreKey,
      };
      const wmPresent = Object.values(wmComplex).some((v) => v !== 'normal');
      onChange({
        variant: module.variant,
        wmPresent,
        wmComplex,
      });
    },
    [onChange, complexValues, module.variant],
  );

  const onResetAllWallsToNormal = React.useCallback(() => {
    onChange({
      variant: module.variant,
      wmPresent: false,
      wmComplex: module.defaultWmComplexValues,
    });
  }, [onChange, module]);

  return (
    <Tabs isLazy>
      <Flex w='100%' alignItems='center'>
        <HStack>
          <TabList>
            <Tab fontSize='sm'>Bullseye</Tab>
            <Tab fontSize='sm'>Anatomical</Tab>
          </TabList>
        </HStack>
        <Spacer />
        <Button size='sm' onClick={onResetAllWallsToNormal} isDisabled={isDisabled}>
          Reset walls to Normal
        </Button>
      </Flex>
      <TabPanels mt={6} p={4}>
        <TabPanel>
          <WallMotionBullseyeControl
            width={280}
            height={280}
            module={module}
            values={complexValues}
            isDisabled={isDisabled}
            onContextMenuClick={setWmComplex}
          />
        </TabPanel>
        <TabPanel>
          <WallMotionAnatomicalControl
            module={module}
            values={complexValues}
            isDisabled={isDisabled}
            onContextMenuClick={setWmComplex}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
