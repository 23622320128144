import { MenuItem, Text } from '@chakra-ui/react';
import { MeasurementRangeGroup } from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../../../components/generic/Modal';
import { MeasurementRangeGroupDeleteModal } from './MeasurementRangeGroupDeleteModal';

interface Props {
  rangeGroup: MeasurementRangeGroup;
}

export const MeasurementRangeGroupDeleteMenuItem = (props: Props) => {
  const { rangeGroup } = props;

  return (
    <MenuItem
      onClick={() => showModal(MeasurementRangeGroupDeleteModal, { rangeGroup })}
      icon={<FaRegTrashAlt />}
    >
      <Text color='danger'>Delete</Text>
    </MenuItem>
  );
};
