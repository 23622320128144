import { Box, Wrap, WrapItem } from '@chakra-ui/react';
import { Page, PiccoloError, Report } from '@piccolohealth/echo-common';
import { Empty, Spin } from '@piccolohealth/ui';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { Error } from '../../components/generic/Error';
import { QueryOptions } from '../../graphql/fetcher';
import { useAppContext } from '../../hooks/useAppContext';
import { deserializeNodes, renderNodesToJSX } from '../../utils/craftjs';
import { PaperPage } from '../generic/PaperPage';
import { RESOLVERS as REPORT_RESOLVERS } from '../templates/report/helpers';

export type ReportQuery = (
  variables: {
    organizationId: string;
    reportId: string;
  },
  options?: QueryOptions<any>,
) => UseQueryResult<any, PiccoloError>;

interface Props {
  reportId: string;
  shouldWrap: boolean;
  reportQuery: ReportQuery;
}

export const ReportPreview = (props: Props) => {
  const { reportId, shouldWrap, reportQuery } = props;
  const { organization } = useAppContext();

  const { isLoading, data, error } = reportQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const report = (data?.organization?.reportShare?.report || data?.organization?.report) as Report;

  const { nodes, pageNodes } = React.useMemo(() => {
    if (!report) {
      return {
        nodes: [],
        pageNodes: [],
      };
    }

    const nodes = deserializeNodes(report.reportTemplate.reportNodes);
    const pageNodes = nodes.filter((node) => (node.type as any)?.resolvedName === 'Page');
    return { nodes, pageNodes };
  }, [report]);

  if (isLoading) {
    return (
      <Box h='100%'>
        <Spin />
      </Box>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!report) {
    return <Empty title='No report' description='There was no report produced' />;
  }

  const Container = shouldWrap ? Wrap : Box;

  const isDisabled = true;

  return (
    <Container spacing={0} align='start' justify='center'>
      {pageNodes.map((page, index) => (
        <WrapItem w='auto' h='full' p={4} key={`${page.id}-${index}`}>
          <PaperPage
            cursor={isDisabled ? 'not-allowed' : 'inherit'}
            w={Page.clientWidth}
            className='editor'
          >
            {renderNodesToJSX(nodes, REPORT_RESOLVERS, page.id, true)}
          </PaperPage>
        </WrapItem>
      ))}
    </Container>
  );
};
