import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { CreateReport } from '../mutations/createReport.mutation';

export const useCreateReportMutation = (
  options?: MutationOptions<Types.CreateReportMutation, Types.CreateReportMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateReport), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('reports') || query.queryKey.includes('report'),
      });
    },
  });
};
