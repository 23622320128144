import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';

export interface FormItemProps extends FormControlProps {
  label?: string;
  error?: string;
  helperText?: string;
  layout?: 'vertical' | 'horizontal';
}

export const FormItem = (props: React.PropsWithChildren<FormItemProps>) => {
  const { label, helperText, children, error, layout, ...rest } = props;

  const stackProps: Partial<StackProps> = {
    direction: layout === 'horizontal' ? 'row' : 'column',
    align: layout === 'horizontal' ? 'center' : undefined,
    spacing: 2,
  };

  return (
    <FormControl isInvalid={!P.isNil(error)} {...rest}>
      <Stack {...stackProps}>
        {label && (
          <FormLabel fontWeight='600' fontSize='sm' mb={0}>
            {label}
          </FormLabel>
        )}
        {children}
      </Stack>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && (
        <FormHelperText fontSize='sm' fontWeight='normal' whiteSpace='pre-wrap'>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
