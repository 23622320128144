import { BillingAction } from '@piccolohealth/echo-common';
import { Select, SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { useBillingActionsQuery } from '../../graphql/hooks/useBillingQuery';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const BillingActionChooser = (props: Props) => {
  const { value, onChange } = props;
  const { organization } = useAppContext();

  const { isLoading, data } = useBillingActionsQuery({
    organizationId: organization.id,
  });

  const options: SelectOption<BillingAction>[] = (data?.organization?.billing.actions ?? []).map(
    (action) => ({
      label: action.id,
      value: action.id,
      raw: action,
    }),
  );

  const selectedValue = React.useMemo(() => {
    return options.find((option) => option.value === value) ?? null;
  }, [options, value]);

  const onChangePrime = React.useCallback(
    (action: SelectOption<BillingAction>) => {
      return onChange(action.value);
    },
    [onChange],
  );

  return (
    <Select
      options={options}
      onChange={onChangePrime}
      value={selectedValue}
      isLoading={isLoading}
      placeholder='Choose billing action'
    />
  );
};
