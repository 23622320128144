import { Badge, BadgeProps, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useToast } from '../../hooks/useToast';

interface Props extends BadgeProps {
  text: string;
  length: number;
  tooltip: string;
}

export const TruncatedBadge = (props: Props) => {
  const { length, text, tooltip, children, ...rest } = props;

  const { successToast } = useToast();

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    successToast(`Copied ${text} to clipboard`);
  };

  return (
    <Tooltip label={tooltip}>
      <Badge {...rest} onClick={onClick} cursor='pointer'>
        <Text>{text.slice(0, length)}</Text>
      </Badge>
    </Tooltip>
  );
};
