import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

interface Props {
  width?: string;
  sidebarContent: React.ReactNode;
  mainContent: React.ReactNode;
}

export const SidebarLayout = (props: Props) => {
  const { width, sidebarContent, mainContent } = props;

  const sidebarWidth = width || '220px';

  return (
    <Flex h='100%'>
      <Box
        flex={`0 0 ${sidebarWidth}`}
        h='100%'
        overflowY='auto'
        py={8}
        px={6}
        bg='#fafafa'
        borderRightColor='#e8e8e8'
        borderRightWidth='1px'
        borderRightStyle='solid'
      >
        {sidebarContent}
      </Box>
      <Box flex='1' overflowY='auto'>
        {mainContent}
      </Box>
    </Flex>
  );
};
