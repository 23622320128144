import { Box, Button, Flex, Heading, Icon, Spacer, Stack } from '@chakra-ui/react';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import 'react-complex-tree/lib/style-modern.css';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../../../components/generic/Modal';
import { useMeasurementRangeGroupsQuery } from '../../../../graphql/hooks/useMeasurementRangeGroupsQuery';
import { MeasurementRangeGroupCreateModal } from './MeasurementRangeGroupCreateModal';
import { MeasurementRangeGroupsTree } from './MeasurementRangeGroupsTree';

export const MeasurementRangeGroupsEditor = () => {
  const { isLoading, data } = useMeasurementRangeGroupsQuery({});

  const rangeGroups = data?.dicom.measurementRangeGroups ?? [];

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Stack p={4} h='full'>
      <Stack w='full'>
        <Flex w='full'>
          <Heading size='md' pb={2}>
            Ranges
          </Heading>
          <Spacer />
          <Button
            colorScheme='purple'
            size='sm'
            leftIcon={<Icon as={FaPlus} />}
            alignSelf='flex-end'
            onClick={() => showModal(MeasurementRangeGroupCreateModal)}
          >
            Create range group
          </Button>
        </Flex>
      </Stack>
      <Box minH={0}>
        <MeasurementRangeGroupsTree rangeGroups={rangeGroups} />
      </Box>
    </Stack>
  );
};
