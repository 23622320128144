import { P } from '@piccolohealth/util';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import { ReportFormValues, ReportStatement, ReportStatementSite, ReportVariable } from '..';

export const statementSiteDiff = (
  prev: Record<string, ReportStatementSite>,
  curr: Record<string, ReportStatementSite>,
) => {
  return _.pickBy(curr, (currStatementSite, id) => {
    const withoutIds = (statements: ReportStatement[]) =>
      (statements ?? []).map(({ id, ...rest }) => rest);
    return !P.isEqual(withoutIds(currStatementSite?.statements), withoutIds(prev[id]?.statements));
  });
};

export const variablesDiff = (
  prev: Record<string, ReportVariable>,
  curr: Record<string, ReportVariable>,
) => {
  return _.pickBy(curr, (currVariable, id) => {
    return !P.isEqual(currVariable?.value, prev[id]?.value);
  });
};

export interface DiffResponse<A> {
  diff: A;
  isDiff: boolean;
}

export const reportFormValuesDiff = (
  prev: ReportFormValues,
  curr: ReportFormValues,
): DiffResponse<ReportFormValues> => {
  const diff = {
    variables: variablesDiff(prev.variables, curr.variables),
    statementSites: statementSiteDiff(prev.statementSites, curr.statementSites),
  };

  return {
    diff,
    isDiff: !P.isEmptyObject(diff.variables) || !P.isEmptyObject(diff.statementSites),
  };
};

export const reportFormValuesCombine = (
  prev: ReportFormValues,
  curr: ReportFormValues,
): ReportFormValues => {
  return deepmerge(prev, curr, {
    arrayMerge: (_destinationArray, sourceArray) => sourceArray,
  });
};
