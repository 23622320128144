import { VStack } from '@chakra-ui/react';
import { ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { useReportTemplateVersionsQuery } from '../../../graphql/hooks/useReportTemplateQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { VersioningTable } from './VersioningTable';

interface Props {
  reportTemplate: ReportTemplate;
}

export const VersioningEditor = (props: Props) => {
  const { organization } = useAppContext();

  const { isLoading, data, error } = useReportTemplateVersionsQuery({
    organizationId: organization.id,
    reportTemplateId: props.reportTemplate.id,
  });

  const versions = (data?.organization?.reportTemplate?.versions as ReportTemplate[]) || [];

  return (
    <VStack align='start' p={4}>
      <VersioningTable versions={versions} error={error} isLoading={isLoading} />
    </VStack>
  );
};
