import { HStack, Icon, IconButton } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { FaPauseCircle, FaPlayCircle, FaStepBackward, FaStepForward } from 'react-icons/fa';
import { ControlTooltip } from './ControlTooltip';

interface Props {
  isPlaying: boolean;
  onClickPreviousFrame: () => void;
  onClickPreviousClip: () => void;
  onClickNextFrame: () => void;
  onClickNextClip: () => void;
  onClickPlayPause: () => void;
}

const ViewerIcon = (props: { icon: IconType; helpText: string; onClick: () => void }) => (
  <ControlTooltip label={props.helpText}>
    <IconButton
      aria-label={props.helpText}
      icon={<Icon as={props.icon} />}
      onClick={props.onClick}
      size='sm'
      fontSize='2xl'
      variant='imageViewer'
      colorScheme='purple'
    />
  </ControlTooltip>
);

export const PlayControls = React.memo((props: Props) => {
  const { isPlaying, onClickPreviousClip, onClickNextClip, onClickPlayPause } = props;

  const playPause = isPlaying ? (
    <ViewerIcon icon={FaPauseCircle} helpText='Pause' onClick={onClickPlayPause} />
  ) : (
    <ViewerIcon icon={FaPlayCircle} helpText='Play' onClick={onClickPlayPause} />
  );

  return (
    <HStack spacing={1}>
      <ViewerIcon icon={FaStepBackward} helpText='Previous clip' onClick={onClickPreviousClip} />
      {playPause}
      <ViewerIcon icon={FaStepForward} helpText='Next clip' onClick={onClickNextClip} />
    </HStack>
  );
});
