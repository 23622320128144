import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateOrganization } from '../mutations/updateOrganization.mutation';
import { useOrganizationQuery } from './useOrganizationQuery';

export const useUpdateOrganizationMutation = (
  options?: MutationOptions<
    Types.UpdateOrganizationMutation,
    Types.UpdateOrganizationMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateOrganization), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useOrganizationQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
    },
  });
};
