import gql from 'graphql-tag';

export const CreateMeasurementRangeGroup = gql`
  mutation CreateMeasurementRangeGroup($request: CreateMeasurementRangeGroupRequest!) {
    createMeasurementRangeGroup(request: $request) {
      id
      label
      ranges {
        label
        sex
        age {
          lt
          lte
          gt
          gte
        }
        measurement {
          lt
          lte
          gt
          gte
        }
      }
    }
  }
`;
