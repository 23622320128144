import { Center, Image, ImageProps, forwardRef } from '@chakra-ui/react';
import { PiccoloError } from '@piccolohealth/echo-common';
import { Spin, blobToBase64 } from '@piccolohealth/ui';
import React from 'react';
import { useQuery } from 'react-query';
import { useAppContext } from '../../hooks/useAppContext';
import { fetchLimiter, fetchWithTimeout } from '../../utils/fetch';

export interface ImageBlob {
  url: string;
  base64: string;
}

interface Props extends ImageProps {
  cacheKey: string;
  url: string;
  onLoaded?: (value: { url: string; base64: string }) => void;
}

export const AuthedImage = forwardRef<Props, 'img'>((props: Props, ref) => {
  const { cacheKey, url, onLoaded, ...rest } = props;
  const { auth } = useAppContext();

  const { isLoading, error, data } = useQuery<ImageBlob>(
    [cacheKey, url],
    async () => {
      const accessToken = await auth.getAccessToken();

      const fetch = () =>
        fetchWithTimeout(url, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

      const res = await fetchLimiter<Response>(fetch);

      if (!res.ok) {
        throw new PiccoloError({
          message: res.statusText,
          type: 'ImageRequestFailure',
          metadata: { url },
        });
      }

      const blob = await res.blob();
      return { url: URL.createObjectURL(blob), base64: await blobToBase64(blob) };
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 3,
    },
  );

  React.useEffect(() => {
    if (data && onLoaded) {
      onLoaded(data);
    }
  }, [data, onLoaded]);

  if (isLoading) {
    return <Spin />;
  }

  if (error || !data?.url) {
    return (
      <Center h='full' w='full' color='white' fontSize='xs'>
        Error loading image
      </Center>
    );
  }

  return <Image ref={ref} src={data.url} {...rest} />;
});
