// 32 MiB request size limit to match cloud run http1.1 limit.
// Reverse proxy will reject requests larger than this size before it hits our server
// so this generally won't have any effect, however still useful if running outside
// of a reverse proxy, e.g. local dev without nginx.
export const FILE_SIZE_LIMIT_32MB_BYTES = 32000000;

export const DEFAULT_PRECISION = 2;
export const ONE_HOUR = 3600000;

export enum PiccoloEnv {
  Local = 'local',
  Development = 'development',
  Production = 'production',
}

export enum PermissionName {
  Admin = 'admin',
  JobRead = 'job:read',
  LabelsCreate = 'labels:create',
  LabelsDelete = 'labels:delete',
  LabelsUpdate = 'labels:update',
  LabelsRead = 'labels:read',
  OrganizationSupport = 'organization:support',
  OrganizationUpdate = 'organization:update',
  OrganizationRead = 'organization:read',
  DistributionCreate = 'distribution:create',
  DistributionRead = 'distribution:read',
  DistributionUpdate = 'distribution:update',
  DistributionDelete = 'distribution:delete',
  DistributionSend = 'distribution:send',
  ReportsCreate = 'reports:create',
  ReportsDelete = 'reports:delete',
  ReportsFinalize = 'reports:finalize',
  ReportsRead = 'reports:read',
  ReportsReadShared = 'reports:read:shared',
  ReportsReport = 'reports:report',
  ReportsShare = 'reports:share',
  ReportsExport = 'reports:export',
  ReportsLabelAdd = 'reports:labels:add',
  ReportsLabelRemove = 'reports:labels:remove',
  ReportTemplateCreate = 'reportTemplate:create',
  ReportTemplateRead = 'reportTemplate:read',
  ReportTemplateUpdate = 'reportTemplate:update',
  ReportAttachmentCreate = 'reportAttachment:create',
  ReportAttachmentRead = 'reportAttachment:read',
  ReportAttachmentDelete = 'reportAttachment:delete',
  UsersRead = 'users:read',
  UsersUpdate = 'users:update',
  WorklistCreate = 'worklist:create',
  WorklistDelete = 'worklist:delete',
  WorklistRead = 'worklist:read',
  WorklistUpdate = 'worklist:update',
}

export const COMPANY_URL = 'https://piccolohealth.com';
export const COMPANY_NAME = 'Piccolo Health';
export const NO_REPLY_EMAIL = 'noreply@piccolohealth.com';
export const ADMIN_EMAIL = 'admin@piccolohealth.com';

export const REPORT_SHARE_EMAIL_TEMPLATE_ALIAS = 'report-share';
export const REPORT_DISTRIBUTION_EMAIL_TEMPLATE_ALIAS = 'report-distribution';
export const USER_INVITE_EMAIL_TEMPLATE_ALIAS = 'user-invite';
export const USER_RESET_PASSWORD_TEMPLATE_ALIAS = 'user-reset-password';

export const SEVEN_DAYS = 86400000 * 7;

export const LIGHT_BLUE = '#bae7ff';
export const LIGHT_GREEN = '#b7eb8f';
export const DARK_RED = '#f5222d';

export const LIGHT_PURPLE = '#efdbff';
export const DARK_PURPLE = '#b37feb';

export const LIGHT_ORANGE = '#ffe7ba';
export const MEDIUM_ORANGE = '#ffc069';
export const DARK_ORANGE = '#fa8c16';

export const LIGHT_GREY = '#e8e8e8';

export const ROLES = [
  {
    id: 'rol_s2rjXSfN3AABz0F2',
    name: 'Administrator',
    description: 'Best for business owners and organization administrators',
    permissions: [
      PermissionName.JobRead,
      PermissionName.DistributionCreate,
      PermissionName.DistributionRead,
      PermissionName.DistributionUpdate,
      PermissionName.DistributionDelete,
      PermissionName.DistributionSend,
      PermissionName.LabelsCreate,
      PermissionName.LabelsUpdate,
      PermissionName.LabelsDelete,
      PermissionName.LabelsRead,
      PermissionName.OrganizationRead,
      PermissionName.OrganizationUpdate,
      PermissionName.ReportsCreate,
      PermissionName.ReportsFinalize,
      PermissionName.ReportsDelete,
      PermissionName.ReportsRead,
      PermissionName.ReportsReport,
      PermissionName.ReportsShare,
      PermissionName.ReportsExport,
      PermissionName.ReportsLabelAdd,
      PermissionName.ReportsLabelRemove,
      PermissionName.ReportTemplateCreate,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportTemplateUpdate,
      PermissionName.ReportAttachmentCreate,
      PermissionName.ReportAttachmentRead,
      PermissionName.ReportAttachmentDelete,
      PermissionName.UsersRead,
      PermissionName.UsersUpdate,
      PermissionName.WorklistCreate,
      PermissionName.WorklistDelete,
      PermissionName.WorklistRead,
      PermissionName.WorklistUpdate,
    ],
  },
  {
    id: 'rol_15pnyvZgZvIfF7F2',
    name: 'Cardiologist',
    description: 'Best for cardiologists that write and review reports',
    permissions: [
      PermissionName.JobRead,
      PermissionName.DistributionCreate,
      PermissionName.DistributionRead,
      PermissionName.DistributionUpdate,
      PermissionName.DistributionDelete,
      PermissionName.DistributionSend,
      PermissionName.LabelsCreate,
      PermissionName.LabelsUpdate,
      PermissionName.LabelsDelete,
      PermissionName.LabelsRead,
      PermissionName.OrganizationRead,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportTemplateUpdate,
      PermissionName.ReportAttachmentCreate,
      PermissionName.ReportAttachmentRead,
      PermissionName.ReportAttachmentDelete,
      PermissionName.ReportsCreate,
      PermissionName.ReportsFinalize,
      PermissionName.ReportsRead,
      PermissionName.ReportsReport,
      PermissionName.ReportsShare,
      PermissionName.ReportsExport,
      PermissionName.ReportsLabelAdd,
      PermissionName.ReportsLabelRemove,
      PermissionName.UsersRead,
      PermissionName.WorklistCreate,
      PermissionName.WorklistDelete,
      PermissionName.WorklistRead,
      PermissionName.WorklistUpdate,
    ],
  },
  {
    id: 'rol_TpkQtwfMqBZ3If3p',
    name: 'Clerical',
    description: 'Best for users that only need viewing capabilities for clerical tasks',
    permissions: [
      PermissionName.JobRead,
      PermissionName.DistributionCreate,
      PermissionName.DistributionRead,
      PermissionName.DistributionUpdate,
      PermissionName.DistributionDelete,
      PermissionName.DistributionSend,
      PermissionName.LabelsCreate,
      PermissionName.LabelsUpdate,
      PermissionName.LabelsDelete,
      PermissionName.LabelsRead,
      PermissionName.OrganizationRead,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportAttachmentCreate,
      PermissionName.ReportAttachmentRead,
      PermissionName.ReportAttachmentDelete,
      PermissionName.ReportsRead,
      PermissionName.ReportsExport,
      PermissionName.ReportsShare,
      PermissionName.ReportsLabelAdd,
      PermissionName.ReportsLabelRemove,
      PermissionName.UsersRead,
      PermissionName.WorklistCreate,
      PermissionName.WorklistDelete,
      PermissionName.WorklistRead,
      PermissionName.WorklistUpdate,
    ],
  },
  {
    id: 'rol_jLWPJ4cOKRPIYbNs',
    name: 'Support',
    description: 'Best for users that need access to debug information to provide support',
    permissions: [
      PermissionName.JobRead,
      PermissionName.DistributionCreate,
      PermissionName.DistributionRead,
      PermissionName.DistributionUpdate,
      PermissionName.DistributionDelete,
      PermissionName.DistributionSend,
      PermissionName.LabelsCreate,
      PermissionName.LabelsUpdate,
      PermissionName.LabelsDelete,
      PermissionName.LabelsRead,
      PermissionName.OrganizationSupport,
      PermissionName.OrganizationRead,
      PermissionName.OrganizationUpdate,
      PermissionName.ReportsCreate,
      PermissionName.ReportsFinalize,
      PermissionName.ReportsDelete,
      PermissionName.ReportsRead,
      PermissionName.ReportsReport,
      PermissionName.ReportsShare,
      PermissionName.ReportsExport,
      PermissionName.ReportsLabelAdd,
      PermissionName.ReportsLabelRemove,
      PermissionName.ReportTemplateCreate,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportTemplateUpdate,
      PermissionName.ReportAttachmentCreate,
      PermissionName.ReportAttachmentRead,
      PermissionName.ReportAttachmentDelete,
      PermissionName.UsersRead,
      PermissionName.UsersUpdate,
      PermissionName.WorklistCreate,
      PermissionName.WorklistDelete,
      PermissionName.WorklistRead,
      PermissionName.WorklistUpdate,
    ],
  },
  {
    id: 'rol_dYhhT6Rl3AvX9oeq',
    name: 'Guest',
    description: 'Best for users that need read-only access to reports',
    permissions: [
      PermissionName.DistributionRead,
      PermissionName.LabelsRead,
      PermissionName.OrganizationRead,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportAttachmentRead,
      PermissionName.ReportsRead,
      PermissionName.UsersRead,
      PermissionName.WorklistRead,
    ],
  },
  {
    id: 'rol_cTchV8Tj4SvZOjml',
    name: 'Shared',
    description: 'Best for users that need access to shared reports',
    permissions: [
      PermissionName.JobRead,
      PermissionName.LabelsRead,
      PermissionName.OrganizationRead,
      PermissionName.ReportsReadShared,
      PermissionName.ReportsExport,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportAttachmentRead,
    ],
  },
  {
    id: 'rol_LPbidhwAEc1osc4f',
    name: 'Sonographer',
    description: 'Best for sonographers that write reports',
    permissions: [
      PermissionName.JobRead,
      PermissionName.DistributionCreate,
      PermissionName.DistributionRead,
      PermissionName.DistributionUpdate,
      PermissionName.DistributionDelete,
      PermissionName.DistributionSend,
      PermissionName.LabelsCreate,
      PermissionName.LabelsUpdate,
      PermissionName.LabelsDelete,
      PermissionName.LabelsRead,
      PermissionName.OrganizationRead,
      PermissionName.ReportsCreate,
      PermissionName.ReportsRead,
      PermissionName.ReportsReport,
      PermissionName.ReportsShare,
      PermissionName.ReportsExport,
      PermissionName.ReportsLabelAdd,
      PermissionName.ReportsLabelRemove,
      PermissionName.ReportTemplateRead,
      PermissionName.ReportTemplateUpdate,
      PermissionName.ReportAttachmentCreate,
      PermissionName.ReportAttachmentRead,
      PermissionName.ReportAttachmentDelete,
      PermissionName.UsersRead,
      PermissionName.WorklistCreate,
      PermissionName.WorklistDelete,
      PermissionName.WorklistRead,
      PermissionName.WorklistUpdate,
    ],
  },
];
