import { Box, Button, Divider, HStack, Spacer, Stack } from '@chakra-ui/react';
import { Distribution } from '@piccolohealth/echo-common';
import React from 'react';
import { useSendDistributionMutation } from '../../../graphql/hooks/useSendDistribution';
import { useAppContext } from '../../../hooks/useAppContext';
import { DistributionHeader } from './DistributionHeader';
import { DistributionSummary } from './DistributionSummary';

interface Props {
  distribution: Distribution;
}

export const DistributionSendCard = (props: Props) => {
  const { distribution } = props;
  const { successToast, errorToast } = useAppContext();

  const mutation = useSendDistributionMutation();

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: distribution.organizationId,
        reportId: distribution.reportId,
        distributionId: distribution.id,
      })
      .then(async () => {
        successToast('Distribution sent successfully');
      })
      .catch((err) => {
        errorToast(`Error sending distribution: ${err.message}`);
      });
  }, [mutation, successToast, errorToast, distribution]);

  return (
    <Stack spacing={6}>
      <HStack>
        <DistributionHeader payload={distribution.payload} status={distribution.status} />
        <Spacer />
      </HStack>
      <DistributionSummary
        payload={distribution.payload}
        trigger={distribution.trigger}
        createdAt={distribution.createdAt}
      />
      <Box>
        <Button
          colorScheme='purple'
          isLoading={mutation.isLoading}
          loadingText='Sending...'
          onClick={onClick}
          data-pw='distributionSendModalSubmitButton'
        >
          Send distribution
        </Button>
      </Box>
      <Divider />
    </Stack>
  );
};
