import type { DatePickerValueChangeDetails, DateValue } from '@ark-ui/react/date-picker';
import { DatePicker } from '@ark-ui/react/date-picker';
import {
  Button,
  ButtonGroup,
  Divider,
  HStack,
  IconButton,
  Input,
  Spacer,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react';
import { parseZonedDateTime } from '@internationalized/date';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretLeft, FaCaretRight, FaTimes } from 'react-icons/fa';
import { FloatingPopover } from './FloatingPopover';

export const LOCALE = 'en-AU';

export const Presets = () => {
  const presets = React.useMemo(() => {
    return [
      {
        label: 'Today',
        value: [
          parseZonedDateTime(DateTime.now().startOf('day').toISO({ extendedZone: true })),
          parseZonedDateTime(DateTime.now().endOf('day').toISO({ extendedZone: true })),
        ],
      },
      {
        label: 'This week',
        value: [
          parseZonedDateTime(DateTime.now().startOf('week').toISO({ extendedZone: true })),
          parseZonedDateTime(DateTime.now().endOf('week').toISO({ extendedZone: true })),
        ],
      },
      {
        label: 'Last week',
        value: [
          parseZonedDateTime(
            DateTime.now().minus({ weeks: 1 }).startOf('week').toISO({ extendedZone: true }),
          ),
          parseZonedDateTime(
            DateTime.now().minus({ weeks: 1 }).endOf('week').toISO({ extendedZone: true }),
          ),
        ],
      },
      {
        label: 'This month',
        value: [
          parseZonedDateTime(DateTime.now().startOf('month').toISO({ extendedZone: true })),
          parseZonedDateTime(DateTime.now().endOf('month').toISO({ extendedZone: true })),
        ],
      },
      {
        label: 'Last month',
        value: [
          parseZonedDateTime(
            DateTime.now().minus({ months: 1 }).startOf('month').toISO({ extendedZone: true }),
          ),
          parseZonedDateTime(
            DateTime.now().minus({ months: 1 }).endOf('month').toISO({ extendedZone: true }),
          ),
        ],
      },
    ];
  }, []);

  return (
    <HStack align='start'>
      {presets.map(({ label, value }) => (
        <DatePicker.PresetTrigger key={label} value={value} asChild>
          <Button variant='solid' size='xs'>
            {label}
          </Button>
        </DatePicker.PresetTrigger>
      ))}
    </HStack>
  );
};

export const TableCellTrigger = (props: { id: number; value: number | string }) => {
  return (
    <IconButton
      as={DatePicker.TableCellTrigger}
      aria-label={`${props.value}-${props.id}`}
      icon={<Text>{props.value}</Text>}
      size='sm'
      variant='ghost'
      m={1}
      sx={{
        '&[data-in-range]': { bg: 'gray.100', color: 'black' },
        '&[data-selected]': { bg: 'purple.500', color: 'white' },
      }}
    />
  );
};

export interface ViewControlProps {
  text: string;
}

export const ViewControl = (props: ViewControlProps) => {
  return (
    <HStack as={DatePicker.ViewControl}>
      <IconButton
        as={DatePicker.PrevTrigger}
        aria-label='prev'
        icon={<FaCaretLeft />}
        size='sm'
        variant='ghost'
      />
      <Spacer />
      <Button as={DatePicker.ViewTrigger} variant='ghost'>
        {props.text}
      </Button>
      <Spacer />
      <IconButton
        as={DatePicker.NextTrigger}
        aria-label='prev'
        icon={<FaCaretRight />}
        size='sm'
        variant='ghost'
      />
    </HStack>
  );
};

export interface ViewProps {
  onClickClear: () => void;
  onClickPreset?: (start: DateTime, end: DateTime) => void;
}

export const DayView = (
  props: ViewProps & {
    months: number;
  },
) => {
  return (
    <DatePicker.View view='day'>
      <DatePicker.Context>
        {(api) => {
          const offset = api.getOffset({ months: props.months - 1 });
          const singleMonth = props.months === 1;

          return (
            <Stack w='fit-content' bg='white' p={3} shadow='popover' rounded='lg'>
              <ViewControl text={api.visibleRangeText.formatted} />
              <Divider />
              <HStack w='fit-content' align='start'>
                <chakra.table as={DatePicker.Table}>
                  <chakra.thead as={DatePicker.TableHead}>
                    <chakra.tr as={DatePicker.TableRow}>
                      {api.weekDays.map((weekDay, id) => (
                        <chakra.th
                          as={DatePicker.TableHeader}
                          key={id}
                          fontSize='sm'
                          color='secondary'
                          fontWeight='normal'
                          pb={2}
                        >
                          {weekDay.short}
                        </chakra.th>
                      ))}
                    </chakra.tr>
                  </chakra.thead>
                  <chakra.tbody as={DatePicker.TableBody}>
                    {api.weeks.map((week, id) => (
                      <chakra.tr as={DatePicker.TableRow} key={id}>
                        {week.map((day, id) => (
                          <chakra.td as={DatePicker.TableCell} key={id} value={day}>
                            <TableCellTrigger value={day.day} id={id} />
                          </chakra.td>
                        ))}
                      </chakra.tr>
                    ))}
                  </chakra.tbody>
                </chakra.table>

                {!singleMonth && (
                  <chakra.table as={DatePicker.Table}>
                    <chakra.thead as={DatePicker.TableHead}>
                      <chakra.tr as={DatePicker.TableRow}>
                        {api.weekDays.map((weekDay, id) => (
                          <chakra.th
                            as={DatePicker.TableHeader}
                            key={id}
                            fontSize='sm'
                            color='secondary'
                            fontWeight='normal'
                            pb={2}
                          >
                            {weekDay.short}
                          </chakra.th>
                        ))}
                      </chakra.tr>
                    </chakra.thead>
                    <chakra.tbody as={DatePicker.TableBody}>
                      {offset.weeks.map((week, id) => (
                        <chakra.tr as={DatePicker.TableRow} key={id}>
                          {week.map((day, id) => (
                            <chakra.td
                              as={DatePicker.TableCell}
                              key={id}
                              value={day}
                              visibleRange={offset.visibleRange}
                            >
                              <TableCellTrigger value={day.day} id={id} />
                            </chakra.td>
                          ))}
                        </chakra.tr>
                      ))}
                    </chakra.tbody>
                  </chakra.table>
                )}
              </HStack>
              <Divider />
              <HStack>
                <Button
                  leftIcon={<FaTimes />}
                  variant='outline'
                  size='xs'
                  onClick={props.onClickClear}
                >
                  Clear
                </Button>
                <Spacer />
                {props.onClickPreset && <Presets />}
              </HStack>
            </Stack>
          );
        }}
      </DatePicker.Context>
    </DatePicker.View>
  );
};

export const MonthView = (props: ViewProps) => {
  return (
    <DatePicker.View view='month'>
      <DatePicker.Context>
        {(api) => (
          <Stack w='fit-content' bg='white' p={3} shadow='popover' rounded='lg'>
            <ViewControl text={`${api.visibleRange.start.year}`} />
            <Divider />
            <chakra.table as={DatePicker.Table}>
              <chakra.tbody as={DatePicker.TableBody}>
                {api.getMonthsGrid({ columns: 4, format: 'short' }).map((months, id) => (
                  <chakra.tr as={DatePicker.TableRow} key={id}>
                    {months.map((month, id) => (
                      <chakra.td as={DatePicker.TableCell} key={id} value={month.value}>
                        <TableCellTrigger value={month.label} id={id} />
                      </chakra.td>
                    ))}
                  </chakra.tr>
                ))}
              </chakra.tbody>
            </chakra.table>

            <Divider />
            <HStack>
              <Button
                leftIcon={<FaTimes />}
                variant='outline'
                size='xs'
                onClick={props.onClickClear}
              >
                Clear
              </Button>
              <Spacer />
              {props.onClickPreset && <Presets />}
            </HStack>
          </Stack>
        )}
      </DatePicker.Context>
    </DatePicker.View>
  );
};

export const YearView = (props: ViewProps) => {
  return (
    <DatePicker.View view='year'>
      <DatePicker.Context>
        {(api) => {
          const years = api.getYearsGrid({ columns: 4 });
          const firstYear = P.first(years.flat());
          const lastYear = P.last(years.flat());

          return (
            <>
              <Stack w='fit-content' bg='white' p={3} shadow='popover' rounded='lg'>
                <ViewControl text={`${firstYear?.label} - ${lastYear?.label}`} />
                <Divider />
                <chakra.table as={DatePicker.Table}>
                  <chakra.tbody as={DatePicker.TableBody}>
                    {years.map((years, id) => (
                      <chakra.tr as={DatePicker.TableRow} key={id}>
                        {years.map((year, id) => (
                          <chakra.td as={DatePicker.TableCell} key={id} value={year.value}>
                            <TableCellTrigger value={year.label} id={id} />
                          </chakra.td>
                        ))}
                      </chakra.tr>
                    ))}
                  </chakra.tbody>
                </chakra.table>
                <Divider />
                <HStack>
                  <Button
                    leftIcon={<FaTimes />}
                    variant='outline'
                    size='xs'
                    onClick={props.onClickClear}
                  >
                    Clear
                  </Button>
                  <Spacer />
                  {props.onClickPreset && <Presets />}
                </HStack>
              </Stack>
            </>
          );
        }}
      </DatePicker.Context>
    </DatePicker.View>
  );
};

interface Props {
  startDate: DateTime | null;
  endDate: DateTime | null;
  onStartDateChange: (date: DateTime | null) => void;
  onEndDateChange: (date: DateTime | null) => void;
  isDisabled?: boolean;
}

export const RangeDatepicker = (props: Props) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const startInputRef = React.useRef<HTMLInputElement>(null);
  const endInputRef = React.useRef<HTMLInputElement>(null);

  const value: DateValue[] | undefined = React.useMemo(() => {
    return props.startDate && props.endDate
      ? [
          parseZonedDateTime(props.startDate.toISO({ extendedZone: true })),
          parseZonedDateTime(props.endDate.toISO({ extendedZone: true })),
        ]
      : [];
  }, [props.startDate, props.endDate]);

  const onStartInputChange = React.useCallback(
    (value: string) => {
      if (value === '') {
        props.onStartDateChange(null);
        return;
      }

      const inputDate = DateTime.fromFormat(
        startInputRef.current?.value ?? '',
        'dd/MM/yyyy',
      ).startOf('day');
      if (inputDate.isValid) {
        props.onStartDateChange(inputDate);
        return;
      }
    },
    [props],
  );

  const onEndInputChange = React.useCallback(
    (value: string) => {
      if (value === '') {
        props.onEndDateChange(null);
        return;
      }

      const inputDate = DateTime.fromFormat(endInputRef.current?.value ?? '', 'dd/MM/yyyy').endOf(
        'day',
      );
      if (inputDate.isValid) {
        props.onEndDateChange(inputDate);
        return;
      }
    },
    [props],
  );

  const onChange = React.useCallback(
    (details: DatePickerValueChangeDetails) => {
      const [start, end] = details.value;

      if (start) {
        props.onStartDateChange(DateTime.fromJSDate(start.toDate('utc')).startOf('day'));
      }

      if (end) {
        props.onEndDateChange(DateTime.fromJSDate(end.toDate('utc')).endOf('day'));
      }
    },
    [props],
  );

  const onClickPreset = React.useCallback(
    (start: DateTime, end: DateTime) => {
      props.onStartDateChange(start);
      props.onEndDateChange(end);
    },
    [props],
  );

  const onClickClear = React.useCallback(() => {
    props.onStartDateChange(null);
    props.onEndDateChange(null);
    setPopoverOpen(false);
  }, [props]);

  return (
    <DatePicker.Root
      open={true}
      numOfMonths={2}
      selectionMode='range'
      closeOnSelect={false}
      onValueChange={onChange}
      defaultValue={value}
      locale={LOCALE}
      disabled={props.isDisabled}
      aria-selected
    >
      <FloatingPopover
        isPortal
        open={popoverOpen && !props.isDisabled}
        setOpen={setPopoverOpen}
        placement='bottom-start'
        render={() => (
          <>
            <DayView onClickPreset={onClickPreset} onClickClear={onClickClear} months={2} />
            <MonthView onClickPreset={onClickPreset} onClickClear={onClickClear} />
            <YearView onClickPreset={onClickPreset} onClickClear={onClickClear} />
          </>
        )}
      >
        <DatePicker.Control>
          <ButtonGroup isAttached bg='white' w='full' isDisabled={props.isDisabled}>
            <Input
              as={DatePicker.Input}
              ref={startInputRef}
              index={0}
              size='sm'
              mr='-px'
              onChange={(e) => onStartInputChange(e.target.value)}
              placeholder='Start'
              data-pw='rangeDatepickerStartInput'
            />
            <Input
              as={DatePicker.Input}
              ref={endInputRef}
              index={1}
              size='sm'
              onChange={(e) => onEndInputChange(e.target.value)}
              placeholder='End'
              data-pw='rangeDatepickerEndInput'
            />
          </ButtonGroup>
        </DatePicker.Control>
      </FloatingPopover>
    </DatePicker.Root>
  );
};
