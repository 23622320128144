import { Stack } from '@chakra-ui/react';
import { ScrollArea } from '@piccolohealth/ui';
import React from 'react';
import { ImageViewerMode, ImageViewerModeType } from '../controls/ModeControl';
import { InstanceStack, Viewport } from '../hooks/useImageViewerActions';
import { InstanceThumbnail } from './InstanceThumbnail';

interface Props {
  instances: InstanceStack[];
  viewports: Viewport[];
  onClickInstanceStack: (instanceStack: InstanceStack) => void;
  mode: ImageViewerMode;
}

export const ImageViewerThumbnailSidebar = React.memo((props: Props) => {
  const { instances, viewports, mode, onClickInstanceStack } = props;

  const thumbs = instances.map((instanceStack, i) => {
    const firstInstance = instanceStack.instances[0];
    const isVisible = viewports.some((viewport) =>
      viewport.stack.instances.includes(firstInstance),
    );
    const index = mode.type === ImageViewerModeType.Normal ? i : undefined;
    const count =
      mode.type === ImageViewerModeType.Normal ? instances.length : instanceStack.instances.length;

    const onClickThumb = () => onClickInstanceStack(instanceStack);

    return (
      <InstanceThumbnail
        key={firstInstance.id}
        instance={firstInstance}
        index={index}
        count={count}
        isVisible={isVisible}
        onClick={onClickThumb}
      />
    );
  });

  return (
    <ScrollArea flexShrink={0} bg='gray.900'>
      <Stack spacing={2} py={2} pr={3}>
        {thumbs}
      </Stack>
    </ScrollArea>
  );
});
