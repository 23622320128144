import {
  MeasurementRange,
  MeasurementRangeGroup,
  MeasurementRangeGroupSource,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import { TreeItem, TreeItemIndex } from 'react-complex-tree';

export type RootNode = {
  type: 'root';
  title: string;
};

export type RangeGroupNode = {
  type: 'rangeGroup';
  title: string;
  rangeGroup: MeasurementRangeGroup;
};

export type SexNode = {
  type: 'sex';
  title: string;
};

export type RangesNode = {
  type: 'ranges';
  title: string;
  ranges: MeasurementRange[];
  units: string | null;
  sources: MeasurementRangeGroupSource[];
};

export type TreeData = RootNode | RangeGroupNode | RangesNode | SexNode;

export const generateRangeGroupsTree = (
  rangeGroups: MeasurementRangeGroup[],
): Record<TreeItemIndex, TreeItem<TreeData>> => {
  const prefix = 'RG';
  const rootIndex = `${prefix}-ROOT`;

  const items: Record<TreeItemIndex, TreeItem<TreeData>> = {
    [rootIndex]: {
      index: rootIndex,
      canMove: false,
      isFolder: true,
      children: [],
      data: { type: 'root', title: 'Root range group node' },
      canRename: false,
    },
  };

  rangeGroups.forEach((rangeGroup) => {
    const rangeGroupIndex = `${prefix}-G-${rangeGroup.id}`;

    items[rangeGroupIndex] = {
      index: rangeGroupIndex,
      canMove: false,
      isFolder: true,
      children: [],
      data: {
        type: 'rangeGroup',
        title: rangeGroup.label,
        rangeGroup,
      },
      canRename: true,
    };

    const rangesBySex = P.groupBy(
      P.orderBy(rangeGroup.ranges, (range) => range.sex),
      (r) => r.sex ?? 'No sex',
    );

    Object.entries(rangesBySex).forEach(([sex, groupedRanges]) => {
      const ranges = groupedRanges ?? [];
      const sexIndex = `${prefix}-S-${rangeGroup.id}-${sex}`;

      items[sexIndex] = {
        index: sexIndex,
        canMove: false,
        isFolder: true,
        children: [],
        data: {
          type: 'sex',
          title: sex,
        },
        canRename: false,
      };

      // Create a singular item for the range, as we want to display a table
      const rangeIndex = `${prefix}-R-${rangeGroup.id}-${sex}`;

      items[rangeIndex] = {
        index: rangeIndex,
        canMove: false,
        isFolder: false,
        children: [],
        data: {
          type: 'ranges',
          title: `${rangeGroup.id}-${sex}`,
          ranges,
          units: rangeGroup.units ?? null,
          sources: rangeGroup.sources,
        },
        canRename: false,
      };

      items[sexIndex].children?.push(rangeIndex);

      items[rangeGroupIndex].children?.push(sexIndex);
    });

    items[rootIndex].children?.push(rangeGroupIndex);
  });

  return items;
};

export const getParentOfItem = <A>(
  tree: Record<string, TreeItem<A>>,
  itemId: TreeItemIndex,
): TreeItem<A> | null => {
  // Find the parent by iterating over the tree
  for (const nodeId in tree) {
    const node = tree[nodeId];
    if (node.children?.includes(itemId)) {
      return node;
    }
  }
  // If no parent is found, return null
  return null;
};
