import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import { MultiSelect, MultiSelectOption, OnChangeRequest } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { VariablesTableFilter } from '../../../hooks/useVariablesTableFilter';
import { FormItem } from '../../forms/FormItem';
import { getColorAndText } from './ReportTemplateVariableTypeTag';

const typeToOption = (
  type: NonNullable<ReportTemplateVariable['__typename']>,
): MultiSelectOption<ReportTemplateVariable['__typename']> => {
  const { color, text } = getColorAndText(type);

  return {
    label: text,
    value: type,
    color: color,
    raw: type,
  };
};

interface Props {
  filter: VariablesTableFilter;
}

export const ReportTemplateVariablesTypeFilterControl = (props: Props) => {
  const { typesFilter, onTypesFilter } = props.filter;

  const types: NonNullable<ReportTemplateVariable['__typename']>[] = [
    'ReportTemplateStaticVariable',
    'ReportTemplateChoiceVariable',
    'ReportTemplateWallMotionVariable',
    'ReportTemplateMediaAttachmentVariable',
  ];

  const options = types.map(typeToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<ReportTemplateVariable['__typename']>) => {
      onTypesFilter(req.values.map(({ raw }) => raw));
    },
    [onTypesFilter],
  );

  const selectedValues = React.useMemo(() => {
    return P.compact(typesFilter.map((v) => options.find((o) => P.isEqual(o.raw, v))));
  }, [typesFilter, options]);

  return (
    <FormItem id='typeFilter' label='Type Filter'>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder='All variable types'
        optionVariant='tag'
      />
    </FormItem>
  );
};
