import Konva from 'konva';
import React from 'react';
import { useMethods } from 'react-use';
import {
  MeasurementInfo,
  MeasurementTool,
  Polygon,
  Text,
  UltrasoundRegion,
  Vector2D,
  calculateAreaLabelPosition,
  calculatePolygonArea,
  calculateRoundedMeasurement,
  clampToRegion,
  getMeasurementMetrics,
  getScaledPoint,
  isValidPolygon,
} from '../../utils';

export interface UseAreaToolOptions {
  scale: Vector2D;
  ultrasoundRegions: UltrasoundRegion[];
  measurementInfo: MeasurementInfo | null;
}

export interface UseAreaToolReturn extends MeasurementTool {
  polygon: Polygon | null;
  isDrawing: boolean;
  isPolygonValid: boolean;
  handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseUp: () => void;
}

interface State {
  isDrawing: boolean;
  polygon: Polygon | null;
  startingRegion: UltrasoundRegion | null;
}

const initialState: State = {
  isDrawing: false,
  polygon: null,
  startingRegion: null,
};

const createMethods = (options: UseAreaToolOptions) => (state: State) => ({
  reset() {
    return initialState;
  },

  startPolygon(point: Vector2D) {
    const region = options.ultrasoundRegions.find((r) => r.contains(point));

    if (!region) {
      return state;
    }

    const { suffix } = getMeasurementMetrics(options.ultrasoundRegions, [point, point]);

    if (suffix == 'pixels') {
      return state;
    }

    const clampedPoint = clampToRegion(point, region);
    return {
      ...state,
      isDrawing: true,
      startingRegion: region,
      polygon: { points: [clampedPoint] },
    };
  },

  addPointToPolygon(point: Vector2D) {
    if (!state.isDrawing || !state.polygon || !state.startingRegion) {
      return state;
    }

    const clampedPoint = clampToRegion(point, state.startingRegion);
    const points = [...state.polygon.points, clampedPoint];
    return {
      ...state,
      polygon: { ...state.polygon, points },
    };
  },

  completePolygon() {
    if (!state.polygon || !isValidPolygon(state.polygon.points)) {
      return { ...state, isDrawing: false };
    }

    const { rowPixelSpacing, colPixelSpacing, suffix } = getMeasurementMetrics(
      options.ultrasoundRegions,
      [state.polygon.points[0], state.polygon.points[1]],
    );
    const area = calculatePolygonArea(
      state.polygon.points,
      rowPixelSpacing ?? 1,
      colPixelSpacing ?? 1,
    );
    return {
      ...state,
      isDrawing: false,
      polygon: { ...state.polygon, area: { value: area, units: suffix } },
    };
  },
});

export const useAreaTool = (options: UseAreaToolOptions): UseAreaToolReturn => {
  const [state, methods] = useMethods(createMethods(options), initialState);

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const point = event.target.getStage()?.getPointerPosition();
    if (point) {
      methods.startPolygon(getScaledPoint(point, options.scale));
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const point = event.target.getStage()?.getPointerPosition();
    if (point) {
      methods.addPointToPolygon(getScaledPoint(point, options.scale));
    }
  };

  const handleMouseUp = () => {
    if (state.isDrawing) {
      methods.completePolygon();
    }
  };

  const isPolygonValid = React.useMemo(() => {
    if (!state.polygon) {
      return false;
    }

    return isValidPolygon(state.polygon.points);
  }, [state.polygon]);

  const measurement = React.useMemo(() => {
    if (!options.measurementInfo || !state.polygon || !state.polygon.area) {
      return null;
    }

    return calculateRoundedMeasurement({
      fromUnit: state.polygon.area.units,
      toUnit: options.measurementInfo.units,
      value: state.polygon.area.value,
      precision: options.measurementInfo.precision,
    });
  }, [options.measurementInfo, state.polygon]);

  const text: Text | null = React.useMemo(() => {
    if (!state.polygon || !measurement) {
      return null;
    }

    const position = calculateAreaLabelPosition(state.polygon);

    if (!position) {
      return null;
    }

    return { value: `${measurement.value} ${measurement.units}`, position };
  }, [state.polygon, measurement]);

  return {
    polygon: state.polygon,
    text,
    isPolygonValid,
    isDrawing: state.isDrawing,
    measurement,
    reset: methods.reset,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
};
