import { Checkbox, Heading, Stack, Text } from '@chakra-ui/react';
import {
  JSONValue,
  Machine,
  MachineFilter,
  MeasurementMappingAndVariants,
  ReportTemplate,
  ReportTemplateDryRun,
  ReportTemplateVariableSource,
  VariableControlType,
  renderFormattedValue,
} from '@piccolohealth/echo-common';
import {
  DataTable,
  ScrollArea,
  TransferList,
  TransferListContentItem,
  TransferListContentProps,
  TransferListOption,
  createColumnHelper,
} from '@piccolohealth/ui';
import { DateTime, P, uuid } from '@piccolohealth/util';
import React from 'react';
import { useAddReportTemplateVariablesMutation } from '../../../graphql/hooks/useAddReportTemplateVariablesMutation';
import { useDeleteReportTemplateVariablesMutation } from '../../../graphql/hooks/useDeleteReportTemplateVariablesMutation';
import { useMeasurementMappingsAndVariantsQuery } from '../../../graphql/hooks/useMeasurementMappingsAndVariantsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportTemplateMachineFilterControl } from './ReportTemplateMachineFilterControl';

interface MeasurementMappingTransferListItem extends MeasurementMappingAndVariants {
  isDynamic: boolean;
  value: JSONValue;
}

interface MeasurementMappingTableContentProps
  extends TransferListContentProps<MeasurementMappingTransferListItem> {
  isLoading: boolean;
}

const MeasurementMappingTableContent = (props: MeasurementMappingTableContentProps) => {
  const { isLoading, options } = props;
  const columns = React.useMemo(() => {
    const columnHelper =
      createColumnHelper<TransferListContentItem<MeasurementMappingTransferListItem>>();

    return [
      columnHelper.display({
        id: 'select',
        size: 32,
        minSize: 32,
        maxSize: 32,
        header: (ps) => {
          const rows = ps.table.getCoreRowModel().rows;
          const isAllChecked = rows.every((row) => row.original.isSelected);
          const isIndeterminate = rows.some((row) => row.original.isSelected) && !isAllChecked;
          const onChange = () => {
            rows.forEach((row) => {
              if (!row.original.isSelected) {
                row.original.onClick();
              }

              if (isAllChecked) {
                row.original.onClick();
              }
            });
          };

          return (
            <Checkbox
              isChecked={isAllChecked}
              isIndeterminate={isIndeterminate}
              onChange={onChange}
            />
          );
        },
        cell: (ps) => {
          const isDisabled = ps.row.original.raw.isDynamic;
          return (
            <Checkbox
              isDisabled={isDisabled}
              isChecked={ps.row.original.isSelected}
              onChange={ps.row.original.onClick}
            />
          );
        },
      }),
      columnHelper.display({
        header: 'Name',
        minSize: 300,
        cell: (ps) => {
          return <Text fontWeight='semibold'>{ps.row.original.raw.mapping.name}</Text>;
        },
      }),
      columnHelper.display({
        header: 'Site',
        minSize: 300,
        cell: (ps) => {
          return <Text>{ps.row.original.raw.mapping.site}</Text>;
        },
      }),
      columnHelper.display({
        header: 'Value',
        cell: (ps) => {
          const rendered = renderFormattedValue(
            ps.row.original.raw.value,
            ps.row.original.raw.mapping.units,
            ps.row.original.raw.mapping.precision,
          ) as string;

          return <Text>{rendered}</Text>;
        },
      }),
    ];
  }, []);

  return (
    <DataTable
      columns={columns}
      data={options}
      size='sm'
      isLoading={isLoading}
      pagination={{
        total: options.length,
        currentPage: 1,
        pageSize: options.length - 1,
        pageSizeOptions: [options.length],
        hasNextPage: false,
        hasPreviousPage: false,
        nextPage: P.noop,
        previousPage: P.noop,
        showTotal: (total: number, range: [number, number]) =>
          `${range[0]}-${range[1]} of ${total} measurement variants`,
        onPageSizeChange: P.noop,
      }}
    />
  );
};

interface Props {
  reportTemplate: ReportTemplate;
  machines: Machine[];
  dryRun?: ReportTemplateDryRun;
}

export const ReportTemplateMeasurementsTransferList = (props: Props) => {
  const { reportTemplate, machines, dryRun } = props;

  const { successToast, errorToast, organization } = useAppContext();

  const [machineFilter, setMachineFilter] = React.useState<MachineFilter[]>([]);

  const addReportTemplateVariablesMutation = useAddReportTemplateVariablesMutation();
  const deleteReportTemplateVariablesMutation = useDeleteReportTemplateVariablesMutation();

  const mappingsAndVariantsQuery = useMeasurementMappingsAndVariantsQuery({
    request: {
      machine: P.isEmpty(machineFilter) ? machines : machineFilter,
      pagination: { limit: 100000 },
    },
  });

  const mappingsAndVariants = (mappingsAndVariantsQuery.data?.dicom.measurementMappingsAndVariants
    .mappingsAndVariants ?? []) as MeasurementMappingAndVariants[];

  const variableOptions = reportTemplate.variables
    .filter((variable) => !P.isEmpty(variable.mappings))
    .flatMap((variable) => {
      const site = P.run(() => {
        switch (variable.__typename) {
          case 'ReportTemplateStaticVariable':
          case 'ReportTemplateChoiceVariable':
          case 'ReportTemplateWallMotionVariable':
            return variable.site;
          default:
            return null;
        }
      });

      const precision = P.run(() => {
        switch (variable.__typename) {
          case 'ReportTemplateStaticVariable':
            return variable.precision;
          default:
            return null;
        }
      });

      const units = P.run(() => {
        switch (variable.__typename) {
          case 'ReportTemplateStaticVariable':
            return variable.units;
          default:
            return null;
        }
      });

      return variable.mappings.map((mapping) => {
        const measurement = dryRun?.measurements.find((m) => m.mapping?.id === mapping.id);

        return {
          label: variable.label,
          value: measurement?.mapping?.id as string,
          raw: {
            mapping: {
              id: variable.id,
              name: variable.label,
              site,
              precision,
              units,
              properties: measurement?.mapping?.properties ?? [],
              createdAt: DateTime.now(),
              updatedAt: DateTime.now(),
            },
            variants: [],
            isDynamic: variable.source === ReportTemplateVariableSource.Dynamic,
            value: measurement?.value,
          },
        };
      });
    });

  const mappingsAndVariantsOptions = P.orderBy(
    mappingsAndVariants.map((measurementMappingAndVariants) => {
      return {
        label: measurementMappingAndVariants.mapping.name,
        value: measurementMappingAndVariants.mapping.id,
        raw: {
          ...measurementMappingAndVariants,
          isDynamic: false,
          value: dryRun?.measurements.find(
            (m) => m.mapping?.id === measurementMappingAndVariants.mapping.id,
          )?.value,
        },
      };
    }),
    (option) => option.label,
  );

  const onChange = async (values: TransferListOption<MeasurementMappingTransferListItem>[]) => {
    const rawSelectedIds = values.map((v) => v.value);
    const rawVariableIds = variableOptions.map((v) => v.value);
    const variablesToAdd = P.difference(rawSelectedIds, rawVariableIds).map((variable) => {
      return {
        __typename: 'ReportTemplateStaticVariable',
        id: uuid(),
        mappingId: values.find((v) => v.value === variable)?.raw.mapping.id,
        label: null,
        site: null,
        category: null,
        controlType: VariableControlType.Input,
        units: null,
        precision: null,
        defaultValue: null,
        isEditable: true,
        source: ReportTemplateVariableSource.Static,
      };
    });
    const variablesToRemove = P.difference(rawVariableIds, rawSelectedIds);

    if (!P.isEmpty(variablesToAdd)) {
      await addReportTemplateVariablesMutation
        .mutateAsync({
          organizationId: organization.id,
          reportTemplateId: reportTemplate.id,
          request: variablesToAdd,
        })
        .then(() => {
          successToast('Variables added successfully');
        })
        .catch((err) => {
          errorToast(`Error adding variables: ${err.message}`);
        });
    }

    if (!P.isEmpty(variablesToRemove)) {
      await deleteReportTemplateVariablesMutation
        .mutateAsync({
          organizationId: organization.id,
          reportTemplateId: reportTemplate.id,
          reportTemplateVariableIds: variablesToRemove,
        })
        .then(() => {
          successToast('Variables removed successfully');
        })
        .catch((err) => {
          errorToast(`Error removing variables: ${err.message}`);
        });
    }
  };

  return (
    <TransferList
      options={mappingsAndVariantsOptions}
      value={variableOptions}
      onChange={onChange}
      components={{
        left: {
          Header: () => (
            <Stack>
              <Heading size='md' w='full'>
                Available Measurements
              </Heading>
              <ReportTemplateMachineFilterControl
                machines={machines}
                value={machineFilter}
                onChange={setMachineFilter}
                w='md'
              />
            </Stack>
          ),
          Content: (props) => (
            <Stack h='full' spacing={4} overflowY='auto'>
              <ScrollArea>
                <MeasurementMappingTableContent
                  {...props}
                  isLoading={mappingsAndVariantsQuery.isLoading}
                />
              </ScrollArea>
            </Stack>
          ),
        },
        right: {
          Header: () => {
            return <Heading size='md'>Measurement Variables</Heading>;
          },
          Content: (props) => (
            <Stack h='full' spacing={4} overflowY='auto'>
              <ScrollArea>
                <MeasurementMappingTableContent {...props} isLoading={false} />
              </ScrollArea>
            </Stack>
          ),
        },
      }}
    />
  );
};
