import { P, inflection } from '@piccolohealth/util';
import { WallMotionVariant } from '../graphql/types';
import {
  DARK_ORANGE,
  DARK_PURPLE,
  DARK_RED,
  LIGHT_BLUE,
  LIGHT_GREEN,
  LIGHT_ORANGE,
  LIGHT_PURPLE,
  MEDIUM_ORANGE,
} from '../utils/constants';
import { toSentence } from '../utils/generic';

const defaultWmComplexValues: WallMotionValues = {
  basalAnterior: 'normal',
  basalAnteroseptal: 'normal',
  basalInferoseptal: 'normal',
  basalInferior: 'normal',
  basalInferolateral: 'normal',
  basalAnterolateral: 'normal',
  midAnterior: 'normal',
  midAnteroseptal: 'normal',
  midInferoseptal: 'normal',
  midInferior: 'normal',
  midInferolateral: 'normal',
  midAnterolateral: 'normal',
  apicalAnterior: 'normal',
  apicalSeptal: 'normal',
  apicalInferior: 'normal',
  apicalLateral: 'normal',
  apical: 'normal',
};

const wallInfo = {
  basalAnterior: {
    text: 'Basal Anterior',
    value: 'basal anterior',
  },
  basalAnteroseptal: {
    text: 'Basal Anteroseptal',
    value: 'basal anteroseptal',
  },
  basalInferoseptal: {
    text: 'Basal Inferoseptal',
    value: 'basal inferoseptal',
  },
  basalInferior: {
    text: 'Basal Inferior',
    value: 'basal inferior',
  },
  basalInferolateral: {
    text: 'Basal Inferolateral',
    value: 'basal inferolateral',
  },
  basalAnterolateral: {
    text: 'Basal Anterolateral',
    value: 'basal anterolateral',
  },
  midAnterior: {
    text: 'Mid Anterior',
    value: 'mid anterior',
  },
  midAnteroseptal: {
    text: 'Mid Anteroseptal',
    value: 'mid anteroseptal',
  },
  midInferoseptal: {
    text: 'Mid Inferoseptal',
    value: 'mid inferoseptal',
  },
  midInferior: {
    text: 'Mid Inferior',
    value: 'mid inferior',
  },
  midInferolateral: {
    text: 'Mid Inferolateral',
    value: 'mid inferolateral',
  },
  midAnterolateral: {
    text: 'Mid Anterolateral',
    value: 'mid anterolateral',
  },
  apicalAnterior: {
    text: 'Apical Anterior',
    value: 'apical anterior',
  },
  apicalSeptal: {
    text: 'Apical Septal',
    value: 'apical septal',
  },
  apicalInferior: {
    text: 'Apical Inferior',
    value: 'apical inferior',
  },
  apicalLateral: {
    text: 'Apical Lateral',
    value: 'apical lateral',
  },
  apical: {
    text: 'Apical',
    value: 'apical',
  },
};

const presentInfo = {
  no: {
    value: 'no',
    text: 'No',
  },
  yes: {
    value: 'yes',
    text: 'Yes',
  },
  global: {
    value: 'global',
    text: 'Global',
  },
};

export type WallMotionValues = Record<WallMotionWall, WallMotionScore>;
export type WallMotionWallInfo = typeof wallInfo;
export type WallMotionPresentInfo = typeof presentInfo;
export type WallMotionWall = keyof WallMotionWallInfo;
export type WallMotionScoreInfo = {
  [key: string]: {
    text: string;
    value: string;
    color: string;
    number: number | null;
  };
};
export type WallMotionScore = keyof WallMotionScoreInfo;

export type WallMotionModule = {
  variant: WallMotionVariant;
  defaultWmComplexValues: WallMotionValues;
  present: WallMotionPresentInfo;
  scores: WallMotionScoreInfo;
  walls: WallMotionWallInfo;
};

const ASE4ChoiceModule: WallMotionModule = {
  variant: WallMotionVariant.Ase_4Choice,
  defaultWmComplexValues,
  present: presentInfo,
  scores: {
    normal: {
      text: 'Normal/Hyperkinetic',
      value: 'normal',
      color: LIGHT_GREEN,
      number: null,
    },
    hypokinetic: {
      text: 'Hypokinetic',
      value: 'hypokinetic',
      color: MEDIUM_ORANGE,
      number: 2,
    },
    akinetic: {
      text: 'Akinetic',
      value: 'akinetic',
      color: DARK_RED,
      number: 3,
    },
    dyskinetic: {
      text: 'Dyskinetic',
      value: 'dyskinetic',
      color: LIGHT_PURPLE,
      number: 4,
    },
  },
  walls: wallInfo,
};

const Legacy7ChoiceModule: WallMotionModule = {
  variant: WallMotionVariant.Legacy_7Choice,
  defaultWmComplexValues,
  present: presentInfo,
  scores: {
    normal: {
      text: 'Normal',
      value: 'normal',
      color: LIGHT_GREEN,
      number: null,
    },
    hyperkinetic: {
      text: 'Hyperkinetic',
      value: 'hyperkinetic',
      color: LIGHT_BLUE,
      number: 1,
    },
    mildlyHypokinetic: {
      text: 'Mildly hypokinetic',
      value: 'mildly hypokinetic',
      color: LIGHT_ORANGE,
      number: 2,
    },
    moderatelyHypokinetic: {
      text: 'Moderately hypokinetic',
      value: 'moderately hypokinetic',
      color: MEDIUM_ORANGE,
      number: 3,
    },
    severelyHypokinetic: {
      text: 'Severely hypokinetic',
      value: 'severely hypokinetic',
      color: DARK_ORANGE,
      number: 4,
    },
    akinetic: {
      text: 'Akinetic',
      value: 'akinetic',
      color: DARK_RED,
      number: 5,
    },
    dyskinetic: {
      text: 'Dyskinetic',
      value: 'dyskinetic',
      color: LIGHT_PURPLE,
      number: 6,
    },
    thinnedAndScarred: {
      text: 'Thinned and scarred',
      value: 'thinned and scarred',
      color: DARK_PURPLE,
      number: 7,
    },
  },
  walls: wallInfo,
};

export const getWallMotionModule = (type?: WallMotionVariant): WallMotionModule => {
  switch (type) {
    case WallMotionVariant.Ase_4Choice:
      return ASE4ChoiceModule;
    case WallMotionVariant.Legacy_7Choice:
      return Legacy7ChoiceModule;
    default:
      return Legacy7ChoiceModule;
  }
};

export const renderWallMotionText = (
  walls: Partial<WallMotionValues>,
  module: WallMotionModule,
  multiple: string,
  singular: string,
) => {
  const isWallMotionWall = (wall: string): wall is WallMotionWall =>
    Object.keys(wallInfo).includes(wall);

  const groupedWalls = Object.entries(walls).reduce<
    Partial<Record<WallMotionScore, WallMotionWall[]>>
  >((result, [wall, value]) => {
    if (value && !result[value] && isWallMotionWall(wall)) {
      result[value] = [];
    }
    if (value && isWallMotionWall(wall)) {
      result[value]?.push(wall);
    }
    return result;
  }, {});

  if (P.isEmptyObject(groupedWalls)) {
    return undefined;
  }

  return Object.entries(groupedWalls)
    .map(([key, values]) => {
      const wallTexts = P.compact((values ?? []).map((wall) => P.get(module.walls, wall)?.text));
      const wallPluralized = wallTexts.length > 1 ? 'walls' : 'wall';
      const pluralize = wallTexts.length > 1 ? multiple : singular;
      const score = P.get(module.scores, key)?.text;
      return inflection.capitalize(
        `The ${toSentence(wallTexts)} ${wallPluralized} ${pluralize} ${score}.`,
      );
    })
    .join(' ');
};

export const renderWallMotion = (
  wmPresent: string,
  wmComplex: WallMotionValues,
  module: WallMotionModule,
): string => {
  const renderGlobal = 'There is global LV systolic dysfunction.';
  const renderNotPresent = 'There are no regional wall motion abnormalities.';

  if (wmPresent === module.present.global.value) {
    return renderGlobal;
  }

  // TODO: Once legacy reports have been migrated to Tiptap, look
  // at sanitizing this data by making all wall motion variables
  // same shape
  if (!wmPresent || wmPresent === module.present.no.value) {
    return renderNotPresent;
  }

  if (wmPresent || wmPresent === module.present.yes.value) {
    const groupedWalls = P.pickBy(wmComplex, (value) => value !== module.scores.normal.value);

    if (P.isEmptyObject(groupedWalls)) {
      return renderNotPresent;
    }

    return P.compact([
      'There are regional wall motion abnormalities.',
      renderWallMotionText(groupedWalls, module, 'are', 'is'),
      'All other walls are normal.',
    ]).join(' ');
  }

  return '';
};
