import { Box, Stack, Text, chakra } from 'chakra-ui-v3';
import React from 'react';

export interface ErrorOverlayProps {
  error: Error;
}

export const ErrorOverlay = (props: ErrorOverlayProps) => {
  return (
    <Box maxW='1200px' mx='auto'>
      <Box mt={8}>
        <Stack p={8} gap={8} rounded='xl' bg='bg.error' shadow='popover'>
          <Text fontSize='xl' fontWeight='bold'>
            Something went wrong:
          </Text>
          <chakra.pre fontWeight='bold'>{props.error.message}</chakra.pre>
          <chakra.pre>{props.error.stack}</chakra.pre>
        </Stack>
      </Box>
    </Box>
  );
};
