import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { CreateWorklistEntry } from '../mutations/createWorklistEntry.mutation';

export const useCreateWorklistEntryMutation = (
  options?: MutationOptions<
    Types.CreateWorklistEntryMutation,
    Types.CreateWorklistEntryMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateWorklistEntry), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('worklistEntries'),
      });
    },
  });
};
