import { AutoCompleteResult } from '@piccolohealth/echo-common';
import {
  AutoComplete,
  AutoCompleteOption,
  AutoCompleteProps,
  PaginationSelectFooter,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import debouncePromise from 'debounce-promise';
import React from 'react';
import { useAutoCompleteReportVariablesInfiniteQuery } from '../../graphql/hooks/useAutoComplete';
import { useAppContext } from '../../hooks/useAppContext';

const DEBOUNCE_MS = 750;

export interface AutoCompleteReportVariableProps
  extends Omit<AutoCompleteProps<AutoCompleteResult>, 'options' | 'onInputChange'> {
  alias: string;
  value: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
}

export const AutoCompleteReportVariable = (props: AutoCompleteReportVariableProps) => {
  const { alias, value, isDisabled, onChange, components, ...rest } = props;
  const { organization } = useAppContext();

  const [inputValue, setInputValue] = React.useState(value);

  const req = {
    organizationId: organization.id,
    request: {
      alias,
      filter: inputValue,
    },
  };

  const query = useAutoCompleteReportVariablesInfiniteQuery(req, {
    enabled: false,
    keepPreviousData: true,
    cacheTime: 0,
  });

  const isLoading = query.isFetching;
  const hasMore = query.hasNextPage ?? false;

  const pagination = P.first(query.data?.pages ?? [])?.organization?.autoComplete?.reportVariables
    .pagination;

  const options: AutoCompleteOption<AutoCompleteResult>[] = P.uniqBy(
    (query.data?.pages ?? [])
      .flatMap((page) => page.organization?.autoComplete.reportVariables.results ?? [])
      .map((variable) => ({
        id: variable.value,
        value: variable.value,
        label: P.upperFirst(variable.value),
        raw: variable,
      })),
    (o) => o.label,
  );

  const debouncedRefetch = React.useMemo(
    () => debouncePromise(query.refetch, DEBOUNCE_MS),
    [query.refetch],
  );

  const onChangePrime = React.useCallback(
    (value: string) => {
      setInputValue(value);
      onChange(value);
      debouncedRefetch();
    },
    [onChange, debouncedRefetch],
  );

  const onOpen = React.useCallback(() => {
    if (!query.isFetched) {
      query.refetch();
    }
  }, [query]);

  // Update input value when value prop changes
  // This is necessary because the input value is controlled
  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <AutoComplete
      {...rest}
      options={options}
      value={inputValue}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={onChangePrime}
      onInputChange={onChangePrime}
      onOpen={onOpen}
      components={{
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items='result'
              total={pagination.total}
              fetchNextPage={query.fetchNextPage}
              isLoading={isLoading}
              hasMore={hasMore}
            />
          ) : null,
        ...components,
      }}
    />
  );
};
