import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateMeasurementRangeGroupRequest,
  MutationCreateMeasurementRangeGroupArgs,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { HookedFormItem } from '../../../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../../components/generic/Modal';
import { useCreateMeasurementRangeGroupMutation } from '../../../../graphql/hooks/useCreateMeasurementRangeGroupMutation';
import { useAppContext } from '../../../../hooks/useAppContext';

type FormValues = CreateMeasurementRangeGroupRequest;

export const MeasurementRangeGroupCreateModal = createModal((props) => {
  const { modal } = props;
  const { successToast, errorToast } = useAppContext();
  const mutation = useCreateMeasurementRangeGroupMutation();

  const initialValues = {
    label: '',
    units: '',
    precision: 0,
    ranges: [],
    sources: [],
  };

  const validationSchema = Yup.object({
    label: Yup.string().required('Label is required'),
    units: Yup.string().required('Units are required'),
    precision: Yup.number().required('Precision is required'),
    ranges: Yup.array().required(),
    sources: Yup.array().required(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const request: MutationCreateMeasurementRangeGroupArgs = {
        request: values,
      };

      mutation
        .mutateAsync(request)
        .then(() => {
          successToast('Measurement range group created successfully');
          modal.hide();
        })
        .catch((err) => {
          errorToast(`Error creating measurement range group: ${err.message}`);
        });
    },
    [mutation, successToast, errorToast, modal],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create measurement range group</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack>
                <HookedFormItem label='Label' name='label'>
                  <HookedInput
                    name='label'
                    placeholder='Range group label (e.g. Left Ventricular End Diastolic Dimension)'
                  />
                </HookedFormItem>
                <HookedFormItem label='Units' name='units'>
                  <HookedInput name='units' placeholder='Units (e.g. mm)' />
                </HookedFormItem>
                <HookedFormItem label='Precision' name='precision'>
                  <HookedInput name='precision' placeholder='Precision (e.g. 0)' />
                </HookedFormItem>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
