import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Divider,
  Stack,
  Text,
} from '@chakra-ui/react';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import * as Yup from 'yup';

interface Props {
  errors: Yup.ValidationError[];
  warnings: Yup.ValidationError[];
}

export const ReportStatusIssues = (props: Props) => {
  if (P.isEmpty(props.warnings) && P.isEmpty(props.errors)) {
    return (
      <Alert status='success'>
        <AlertIcon />
        <AlertTitle>There were no issues found</AlertTitle>
      </Alert>
    );
  }

  const warningItems = props.warnings.map((error) => {
    return {
      title: inflection
        .titleize(inflection.underscore(P.get(error.params as {}, 'label', error.path ?? '') ?? ''))
        .replace('.value', ''),
      message: error.message,
    };
  });

  const errorItems = props.errors.map((error) => {
    return {
      title: inflection
        .titleize(inflection.underscore(P.get(error.params as {}, 'label', error.path ?? '') ?? ''))
        .replace('.value', ''),
      message: error.message,
    };
  });

  const warningContent = P.run(() => {
    if (P.isEmpty(warningItems)) {
      return null;
    }

    return (
      <Stack spacing={4}>
        <Stack spacing={0}>
          <Text fontWeight='bold'>Warnings found</Text>
          <Text fontSize='sm' fontWeight='normal' color='secondary'>
            The following warnings were found
          </Text>
        </Stack>
        {warningItems.map(({ title, message }, index) => (
          <Alert key={`warning-${index}`} status='warning' fontSize='sm' py={3}>
            <AlertIcon />
            <Stack spacing={0}>
              <AlertTitle>{title}</AlertTitle>
              <AlertDescription fontSize='xs' lineHeight='sm'>
                {message}
              </AlertDescription>
            </Stack>
          </Alert>
        ))}
      </Stack>
    );
  });

  const errorContent = P.run(() => {
    if (P.isEmpty(errorItems)) {
      return null;
    }

    return (
      <Stack spacing={4}>
        <Stack spacing={0}>
          <Text fontWeight='bold'>Errors found</Text>
          <Text fontSize='sm' fontWeight='normal' color='secondary'>
            The following errors were found
          </Text>
        </Stack>
        {errorItems.map(({ title, message }, index) => (
          <Alert key={`error-${index}`} status='error' fontSize='sm' py={3}>
            <AlertIcon />
            <Stack spacing={0}>
              <AlertTitle>{title}</AlertTitle>
              <AlertDescription fontSize='xs' lineHeight='sm'>
                {message}
              </AlertDescription>
            </Stack>
          </Alert>
        ))}
      </Stack>
    );
  });

  return (
    <Stack spacing={4}>
      {warningContent}
      {errorContent}
      <Divider />
    </Stack>
  );
};
