import { Tag, TagProps } from '@chakra-ui/react';
import { AuditTrailEvent } from '@piccolohealth/echo-common';
import { inflection } from '@piccolohealth/util';
import React from 'react';

interface Props extends TagProps {
  event: AuditTrailEvent;
}

export const getColor = (event: AuditTrailEvent): string | undefined => {
  if (event.startsWith('REPORT') || event.startsWith('REPORTS')) {
    return 'blue';
  } else if (event.startsWith('ORGANIZATION')) {
    return 'purple';
  } else if (event.startsWith('USER')) {
    return 'green';
  } else if (event.startsWith('PDF')) {
    return 'orange';
  } else if (event.startsWith('REPORT_TEMPLATE')) {
    return 'yellow';
  } else if (event.startsWith('REPORT_')) {
    return 'cyan';
  } else if (event.startsWith('DISTRIBUTION')) {
    return 'red';
  } else if (event.startsWith('LABEL')) {
    return 'pink';
  } else if (event.startsWith('MACHINE')) {
    return 'teal';
  } else {
    return undefined;
  }
};

export const AuditTrailEventTag = (props: Props) => {
  const { event, ...rest } = props;
  const color = getColor(event);
  const text = inflection.titleize(event);

  return (
    <Tag colorScheme={color} {...rest}>
      {text}
    </Tag>
  );
};
