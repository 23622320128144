import { Badge, BadgeProps } from '@chakra-ui/react';
import React from 'react';

export enum InstanceFormat {
  MP4,
  JPEG,
}

interface Props extends BadgeProps {
  format: InstanceFormat;
}

export const InstanceFormatTag = (props: Props) => {
  const { format, ...rest } = props;

  if (format === InstanceFormat.MP4) {
    return (
      <Badge size='sm' variant='subtle' colorScheme='green' {...rest}>
        MP4
      </Badge>
    );
  }

  return (
    <Badge size='sm' variant='subtle' colorScheme='red' {...rest}>
      JPEG
    </Badge>
  );
};
