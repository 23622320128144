import { StyleObjectOrFn, css as computeCss, useTheme } from '@chakra-ui/react';
import { serializeStyles } from '@emotion/serialize';
import React from 'react';

export const useGetCss = () => {
  const theme = useTheme();

  const fn = React.useCallback(
    (styleObj: StyleObjectOrFn) => {
      const css = computeCss(styleObj)(theme as any);
      const styles = serializeStyles([css]).styles;

      return {
        styles,
        css,
      };
    },
    [theme],
  );

  return fn;
};
