import type { ComponentStyleConfig } from '@chakra-ui/react';
import { Modal as ChakraModal, extendTheme } from '@chakra-ui/react';
import { Alert } from './components/alert';
import { Badge } from './components/badge';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Input } from './components/input';
import { Menu } from './components/menu';
import { Modal } from './components/modal';
import { Popover } from './components/popover';
import { Select } from './components/select';
import { Stepper } from './components/stepper';
import { Table } from './components/table';
import { Tabs } from './components/tabs';
import { Tag } from './components/tag';
import { Text } from './components/text';
import { Textarea } from './components/textarea';
import { Tooltip } from './components/tooltip';

const components: Record<string, ComponentStyleConfig> = {
  Alert,
  Text,
  Button,
  Badge,
  Tag,
  Select,
  Input,
  Textarea,
  Tabs,
  Popover,
  Tooltip,
  Modal,
  Menu,
  Checkbox,
  Table,
  Stepper,
};

export const theme = extendTheme({
  fonts: {
    heading: `Inter, sans-serif`,
    body: `Inter, sans-serif`,
  },
  breakpoints: {
    '3xl': '1800px',
  },
  styles: {
    global: () => ({
      'html, body, #app': {
        h: 'full',
        m: 0,
        p: 0,
        overflowX: 'hidden',
        overflowY: 'hidden',
      },

      '*:focus-visible': {
        outline: 'none',
      },

      '.lagRadar': {
        position: 'fixed',
        bottom: '0.5rem',
        right: '0.5rem',
        zIndex: 10000,
      },

      '.editor-component-selected': {
        outlineOffset: '-1px',
        outline: '1px solid #4c3dc4',
      },

      '.editor-component-hover': {
        outlineOffset: '-1px',
        outline: '1px solid #c3bfec',
      },

      '.row-dragging': {
        zIndex: 9999,
      },

      '.video': {
        position: 'absolute',
        top: 0,
        left: 0,
      },

      'div.ProseMirror': {
        whiteSpace: 'normal',
      },

      '.ProseMirror:focus': {
        outline: 'none',
      },

      '.ProseMirror .chakra-popover__popper': {
        display: 'inline',
      },

      '.ProseMirror .is-empty::before': {
        color: 'gray.400',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },

      '.ProseMirror li.is-empty::before': {
        color: 'gray.400',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
        marginLeft: '22px',
      },

      em: {
        fontStyle: 'revert',
      },

      strong: {
        fontStyle: 'revert',
      },

      'ol, ul': {
        listStyle: 'revert',
      },

      statements: {
        lineHeight: '22px',
      },

      'variable[data-placeholder]': {
        _before: {
          content: '"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"',
        },
      },

      "[class*='a11yText-A11yText']": {
        display: 'none',
      },
    }),
  },
  colors: {
    danger: '#E53E3E',
    warning: '#DD6B20',
    success: '#38A169',
    info: '#9F7AEA',
    extraDarkPurple: '#00152A',
    darkRed: '#f5222d',
    darkOrange: '#fa8c16',
    darkPurple: '#b37feb',
    lightBlue: '#bae7ff',
    lightGreen: '#b7eb8f',
    lightGrey: '#e8e8e8',
    lightOrange: '#ffe7ba',
    lightPurple: '#efdbff',
    mediumOrange: '#ffc069',
    secondary: '#718096',
  },
  shadows: {
    popover: `rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px`,
  },
  layerStyles: {
    bordered: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      rounded: 'xl',
    },
    fade: {
      maskImage: 'linear-gradient(black 90%, transparent 100%)',
    },
    inline: {
      w: 'full',
      bg: 'transparent',
      _hover: {
        bg: 'inherit',
      },
      _focus: {
        bg: 'inherit',
      },
      _focusWithin: {
        bg: 'inherit',
      },
      _focusVisible: {
        bg: 'inherit',
      },
      _disabled: {
        cursor: 'not-allowed',
        opacity: 1,
      },
    },
    inlineFocus: {
      _focusWithin: {
        // #EDF2F7 = gray.100
        bg: '#EDF2F7',
        rounded: 'sm',
        boxShadow: '0 0 0 1px #EDF2F7, 0 0 0 3px #EDF2F7;',
      },
    },
  },
  components,
});

// Set non-stylistic default props
// See - https://github.com/chakra-ui/chakra-ui/issues/1424

ChakraModal.defaultProps = { ...ChakraModal.defaultProps, preserveScrollBarGap: true };
