import { Center } from '@chakra-ui/react';
import React from 'react';

export const AuthLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Center bg='extraDarkPurple' h='100vh' userSelect='none'>
      <div id='recaptcha-container'>
        <div id='recaptcha' />
      </div>
      {props.children}
    </Center>
  );
};
