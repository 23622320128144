import { Badge, BadgeProps } from '@chakra-ui/react';
import { prettyBytes } from '@piccolohealth/util';
import React from 'react';

interface Props extends Omit<BadgeProps, 'size'> {
  size: number;
  frameCount: number;
}

export const InstanceFrameSizeBadge = (props: Props) => {
  const { size, frameCount, ...rest } = props;

  const averageFrameSize = size / frameCount;

  const color = averageFrameSize > 5000000 ? 'red' : 'green';

  return (
    <Badge size='sm' colorScheme={color} {...rest}>
      Frame Size: {prettyBytes(averageFrameSize)}
    </Badge>
  );
};
