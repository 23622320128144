import { Box, Stack, Tag, Text } from '@chakra-ui/react';
import {
  DistributionEvent,
  DistributionPayload,
  DistributionTrigger,
  renderHealthLinkAddress,
  renderHealthLinkName,
  renderMedicalObjectsProviderAddress,
  renderMedicalObjectsProviderName,
} from '@piccolohealth/echo-common';
import { DescriptionItem, DescriptionList, FancyDate } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { DistributionEventsList } from './DistributionEventsList';
import { DistributionTriggerTag } from './DistributionTriggerTag';

interface Props {
  payload: DistributionPayload;
  trigger: DistributionTrigger;
  events?: DistributionEvent[];
  createdAt?: DateTime;
  hideBase?: boolean;
}

const getDescriptionItems = (
  payload: DistributionPayload,
  trigger: DistributionTrigger,
  events?: DistributionEvent[],
  createdAt?: DateTime,
  hideBase?: boolean,
): DescriptionItem[] => {
  const baseItems = hideBase
    ? []
    : P.compact([
        createdAt && {
          title: 'Date',
          content: <FancyDate direction='row' date={DateTime.fromISO(createdAt.toString())} />,
        },
        {
          title: 'Trigger',
          content: <DistributionTriggerTag trigger={trigger} />,
        },
      ]);

  const middleItems = P.run(() => {
    switch (payload.__typename) {
      case 'DistributionMedicalObjectsPayload': {
        return [
          {
            title: 'From provider',
            content: (
              <Stack>
                <Text noOfLines={2}>
                  {P.compact([
                    renderMedicalObjectsProviderName(payload.from.providerDetails),
                    renderMedicalObjectsProviderAddress(payload.from.providerDetails),
                  ]).join(' - ')}
                </Text>
                <Box>
                  {payload.from.providerNumber.length > 0 && (
                    <Tag>{payload.from.providerNumber}</Tag>
                  )}
                </Box>
              </Stack>
            ),
          },
          {
            title: 'To provider',
            content: (
              <Stack>
                <Text noOfLines={2}>
                  {renderMedicalObjectsProviderName(payload.to.providerDetails)} -{' '}
                  {renderMedicalObjectsProviderAddress(payload.to.providerDetails)}
                </Text>
                <Tag w='fit-content'>{payload.to.providerNumber}</Tag>
              </Stack>
            ),
          },
        ];
      }
      case 'DistributionHealthLinkPayload': {
        return [
          {
            title: 'From provider',
            content: (
              <Stack>
                <Text noOfLines={2}>{payload.from.name}</Text>
                <Tag w='fit-content'>{payload.from.providerNumber}</Tag>
              </Stack>
            ),
          },
          {
            title: 'To provider',
            content: (
              <Stack>
                <Text noOfLines={2}>
                  {renderHealthLinkName(payload.to.practitionerName)} -{' '}
                  {renderHealthLinkAddress(payload.to.organizationAddress)}
                </Text>
                <Tag w='fit-content'>{payload.to.providerNumber}</Tag>
              </Stack>
            ),
          },
        ];
      }
      case 'DistributionEmailPayload': {
        return [
          {
            title: 'From',
            content: (
              <Text noOfLines={1}>
                {payload.sharerName} - {payload.sharerEmail}
              </Text>
            ),
          },
          {
            title: 'To',
            content: <Text noOfLines={1}>{payload.shareeEmail}</Text>,
          },
          {
            title: 'Message',
            content: <Text noOfLines={1}>{payload.message}</Text>,
          },
        ];
      }
      case 'DistributionFaxPayload': {
        return [
          {
            title: 'From',
            content: (
              <Text noOfLines={1}>
                {payload.from.sharerName} - {payload.from.sharerEmail}
              </Text>
            ),
          },
          {
            title: 'To',
            content: <Text noOfLines={1}>{payload.to.faxNumber}</Text>,
          },
        ];
      }
      default:
        return [];
    }
  });

  const bottomItems = P.compact([
    !P.isNil(events)
      ? {
          title: 'Events',
          content: <DistributionEventsList events={events} />,
        }
      : null,
  ]);

  return [...baseItems, ...middleItems, ...bottomItems];
};

export const DistributionSummaryList = (props: Props) => {
  const { payload, trigger, events, createdAt, hideBase } = props;

  const items = getDescriptionItems(payload, trigger, events, createdAt, hideBase);

  return <DescriptionList items={items} rowGap={4} fontSize='sm' />;
};
