import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { FastForwardReport } from '../mutations/fastForwardReport.mutation';
import { getReportBaseKey } from './useReportQuery';
import { getReportsBaseKey } from './useReportsQuery';

export const useFastForwardReportMutation = (
  options?: MutationOptions<
    Types.FastForwardReportMutation,
    Types.FastForwardReportMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(FastForwardReport), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      await queryClient.invalidateQueries(
        getReportBaseKey({
          organizationId: variables.organizationId,
          reportId: variables.reportId,
        }),
      );

      await queryClient.invalidateQueries(
        getReportsBaseKey({
          organizationId: variables.organizationId,
        }),
      );
    },
  });
};
