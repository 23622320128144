import { Box, Divider, Stack, StackProps } from '@chakra-ui/react';
import React from 'react';

export const DividerWithContent = (props: React.PropsWithChildren<StackProps>) => {
  const { children, ...rest } = props;

  return (
    <Stack direction='row' alignItems='center' {...rest}>
      <Divider />
      <Box>{children}</Box>
      <Divider />
    </Stack>
  );
};
