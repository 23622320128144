import {
  CreateReportsExportJobMutationVariables,
  Job,
  JobStatus,
  PiccoloError,
} from '@piccolohealth/echo-common';
import React from 'react';
import { MutationFn } from '../graphql/fetcher';
import { useCreateReportsExportJobMutation } from '../graphql/hooks/useCreateReportExportJobMutation';
import { useJobQuery } from '../graphql/hooks/useJobQuery';
import { useAppContext } from './useAppContext';

export interface UseJobResponse<A> {
  start: (variables: A) => void;
  isLoading: boolean;
  isCompleted: boolean;
  isFailed: boolean;
  job?: Job | null;
  error: PiccoloError | null;
}

const useJob = <A, B>(
  mutationFn: MutationFn<A, B>,
  parseResponse: (a: A) => Job,
): UseJobResponse<B> => {
  const { organization } = useAppContext();
  const [jobId, setJobId] = React.useState<string | null>(null);
  const [jobRefetchingEnabled, setJobRefetchingEnabled] = React.useState(false);

  const query = useJobQuery(
    {
      organizationId: organization.id,
      jobId: jobId ?? '',
    },
    {
      enabled: jobRefetchingEnabled,
      refetchInterval: 2000,
      onSuccess: (data) => {
        const job = data.organization?.job;

        if (!job) {
          setJobRefetchingEnabled(false);
          return;
        }

        if (job.status === JobStatus.Completed || job.status === JobStatus.Failed) {
          setJobRefetchingEnabled(false);
          return;
        }
      },
      onError: () => {
        setJobRefetchingEnabled(false);
      },
    },
  );
  const mutation = mutationFn({});

  const start = (variables: B) => {
    return mutation.mutate(variables, {
      onSuccess: (data: A) => {
        const job = parseResponse(data);
        setJobId(job.id);
        setJobRefetchingEnabled(true);
      },
    });
  };

  const job = query.data?.organization?.job;
  const error = mutation.error || query.error;
  const isLoading =
    mutation.isLoading ||
    jobRefetchingEnabled ||
    job?.status === JobStatus.Queued ||
    job?.status === JobStatus.InProgress;
  const isCompleted = job?.payload && job?.status === JobStatus.Completed;
  const isFailed = job?.status === JobStatus.Failed;

  return {
    start,
    isLoading,
    isCompleted,
    isFailed,
    job,
    error,
  };
};

export type UseReportsExportJobResponse = UseJobResponse<CreateReportsExportJobMutationVariables>;

export const useReportsExportJob = (): UseReportsExportJobResponse => {
  return useJob(useCreateReportsExportJobMutation, (result) => result.createReportsExportJob);
};
