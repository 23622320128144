import { Box, Flex, FormLabel, Heading, Kbd, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { Content } from '../../generic/Content';
import { Divider } from '../../generic/Divider';

interface AboutSectionProps {
  title: string;
}

const AboutSection = (props: React.PropsWithChildren<AboutSectionProps>) => {
  const { title, children } = props;

  return (
    <Stack spacing={4}>
      <Heading size='sm'>{title}</Heading>
      <VStack spacing={2} w='100%' alignItems='flex-start'>
        {children}
      </VStack>
    </Stack>
  );
};

interface KbdProps {
  text: string | number;
}

const KbdLabel = ({ text }: KbdProps) => {
  return <Kbd p={1}>{text}</Kbd>;
};

interface InfoBlockProps {
  label: string;
}

const InfoBlock = (props: React.PropsWithChildren<InfoBlockProps>) => {
  const { label, children } = props;

  return (
    <Flex alignItems='center'>
      <FormLabel fontWeight='600' fontSize='sm' m={0} mr={2}>
        {label}:
      </FormLabel>
      {children}
    </Flex>
  );
};

export const AboutSettings = () => {
  const { config, organization } = useAppContext();
  const versionNumber = `${config.buildInfo.branch}-${config.buildInfo.commit}`;

  return (
    <Content title='General'>
      <Box maxW='lg' p={4}>
        <AboutSection title='DICOM'>
          <InfoBlock label='IP'>
            <KbdLabel text={organization.dicomSettings.ip} />
          </InfoBlock>
          <InfoBlock label='Port'>
            <KbdLabel text={organization.dicomSettings.port} />
          </InfoBlock>
          <InfoBlock label='AET'>
            <KbdLabel text={organization.dicomSettings.aet} />
          </InfoBlock>
        </AboutSection>
        <Divider />
        <AboutSection title='Version Info'>
          <InfoBlock label='Version'>
            <KbdLabel text={versionNumber} />
          </InfoBlock>
        </AboutSection>
      </Box>
    </Content>
  );
};
