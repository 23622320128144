import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ConfigContext, useConfig } from '../context/ConfigContext';
import { OrganizationContext, useOrganization } from '../context/OrganizationContext';
import { UserContext, useUser } from '../context/UserContext';
import { useGetCss } from './useGetCss';
import { UseToastReturn, useToast } from './useToast';

export type UseAppContextReturn = UseToastReturn &
  ConfigContext & {
    location: Location;
    auth: ReturnType<typeof useAuth>;
    user: UserContext;
    organization: OrganizationContext;
    queryClient: ReturnType<typeof useQueryClient>;
    css: ReturnType<typeof useGetCss>;
  };

export const useAppContext = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const config = useConfig();
  const user = useUser();
  const organization = useOrganization();
  const toast = useToast();
  const location = useLocation();
  const css = useGetCss();

  return {
    ...config,
    ...toast,
    location,
    auth,
    user,
    organization,
    queryClient,
    css,
  };
};
