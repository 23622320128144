import { Box, Input, ResponsiveValue, Text } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { ReportStatementSite } from '@piccolohealth/echo-common';
import { Select } from '@piccolohealth/ui';
import _ from 'lodash';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import { FormItem } from '../../../forms/FormItem';
import { TiptapStatementSiteList } from '../../../tiptap/editors/TiptapStatementSiteList';
import { TiptapContext } from '../../../tiptap/utils/TiptapContext';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { StatementSiteChooser } from '../../common/settings/StatementSiteChooser';

interface Props {
  statementSiteId: string;
  limit: number;
  size: ResponsiveValue<string>;
  spacing: ResponsiveValue<string>;
  listType: 'ordered' | 'unordered';
}

export const InlineStatementSiteList = (props: Props) => {
  const { statementSiteId, limit, size, spacing, listType } = props;
  const { reportTemplate, isDisabled, timezone } = useReport();
  const { getValues } = useFormContext();

  const { field } = useController({
    name: `statementSites.${statementSiteId}.statements[0].value`,
  });

  const { statementSite, reportTemplateStatementSite, reportTemplateStatements } =
    React.useMemo(() => {
      const statementSites = getValues('statementSites');
      const statementSite = _.chain(statementSites)
        .values()
        .find((site) => site.id === statementSiteId)
        .value() as ReportStatementSite;

      const reportTemplateStatementSite = reportTemplate.statementSites.find(
        (statementSite) => statementSite.id === statementSiteId,
      );

      const reportTemplateStatements = reportTemplateStatementSite?.statements ?? [];

      return {
        statementSite,
        reportTemplateStatementSite,
        reportTemplateStatements,
      };
    }, [getValues, statementSiteId, reportTemplate]);

  const variablePairs = React.useMemo(() => {
    const { variables } = getValues();
    return reportTemplate.variables.map((template) => ({
      template,
      variable: _.find(variables, { id: template.id }),
    }));
  }, [getValues, reportTemplate]);

  const getFormName = React.useCallback((variableId: string) => {
    return `variables.${variableId}.value`;
  }, []);

  const context: TiptapContext = React.useMemo(
    () => ({
      isDisabled,
      timezone,
      reportTemplate,
      variablePairs,
      reportTemplateStatements,
      getFormName,
    }),
    [isDisabled, timezone, reportTemplate, variablePairs, reportTemplateStatements, getFormName],
  );

  if (!statementSite || !reportTemplateStatementSite) {
    return <Text>No statements</Text>;
  }

  return (
    <Box fontSize={size}>
      <TiptapStatementSiteList
        limit={limit}
        value={field.value}
        context={context}
        size={size}
        spacing={spacing}
        listType={listType}
        onChange={field.onChange}
      />
    </Box>
  );
};

const InlineStatementSiteListSettings = () => {
  const {
    actions: { setProp },
    statementSiteId,
    limit,
    size,
    spacing,
    listType,
  } = useNode((node) => ({
    statementSiteId: node.data.props.statementSiteId,
    limit: node.data.props.limit,
    size: node.data.props.size,
    spacing: node.data.props.spacing,
    listType: node.data.props.listType,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Inline statement site list'>
        <FormItem id='statementSiteId' label='Statement site'>
          <StatementSiteChooser
            statementSiteId={statementSiteId}
            onChange={(value) =>
              setProp((props: Props) => (props.statementSiteId = value as string))
            }
          />
        </FormItem>

        <FormItem id='limit' label='Limit'>
          <Input
            defaultValue={limit}
            onChange={(e) =>
              setProp((props: Props) => (props.limit = parseInt(e.target.value) ?? 0))
            }
            size='sm'
          />
        </FormItem>
        <FormItem id='size' label='Size'>
          <Input
            defaultValue={size}
            value={size}
            size='sm'
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
          />
        </FormItem>
        <FormItem id='spacing' label='Spacing'>
          <Input
            defaultValue={spacing}
            value={spacing}
            size='sm'
            onChange={(e) =>
              setProp((props: Props) => (props.spacing = e.target.value as Props['spacing']))
            }
          />
        </FormItem>
        <FormItem id='listType' label='List Type'>
          <Select
            value={{ value: listType, label: listType, raw: listType }}
            onChange={(e) => setProp((props: Props) => (props.listType = e.raw))}
            size='sm'
            options={[
              { value: 'ordered', label: 'ordered', raw: 'ordered' as const },
              { value: 'unordered', label: 'unordered', raw: 'unordered' as const },
            ]}
          />
        </FormItem>
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineStatementSiteList.defaultProps = {
  statementSiteId: '',
  limit: 8,
  size: 'sm',
  spacing: '1',
  listType: 'ordered',
};

InlineStatementSiteList.craft = {
  name: 'InlineStatementSiteList',
  props: InlineStatementSiteList.defaultProps,
  related: {
    settings: InlineStatementSiteListSettings,
  },
};
