import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { TotpEnrolCard } from './components/TotpEnrolCard';

export const TotpEnrol = () => {
  const auth = useAuth();

  if (!auth.isLoggedIn) {
    return <Navigate to='/login' />;
  }

  if (auth.isMFAEnrolled) {
    return <Navigate to='/login' />;
  }

  return <TotpEnrolCard />;
};
