import { Box } from '@chakra-ui/react';
import Konva from 'konva';
import React from 'react';
import { Layer, Stage } from 'react-konva';
import { MeasurementArea } from './MeasurementArea';
import { MeasurementLine } from './MeasurementLine';
import { MeasurementVolume } from './MeasurementVolume';
import { UseMeasurementControlReturn } from './hooks/useMeasurementControl';

interface Props {
  measurementControl: UseMeasurementControlReturn;
}

export const MeasurementTools = (props: Props) => {
  const { measurementControl } = props;

  const { activeTool, lengthTool, areaTool, volumeTool, dimensions } = measurementControl;

  const onMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();

    if (!stage) {
      return;
    }

    stage.container().style.cursor = 'crosshair';
  };

  if (!activeTool) {
    return null;
  }

  return (
    <Box pos='absolute' top='50%' left='50%' transform='translate(-50%, -50%)'>
      <Stage
        scale={dimensions.scale}
        width={dimensions.containerWidth}
        height={dimensions.containerHeight}
        onMouseEnter={onMouseEnter}
        onMouseDown={activeTool.handleMouseDown}
        onMousemove={activeTool.handleMouseMove}
        onMouseup={activeTool.handleMouseUp}
      >
        <Layer>
          <MeasurementLine tool={lengthTool} />
          <MeasurementArea tool={areaTool} />
          <MeasurementVolume tool={volumeTool} />
        </Layer>
      </Stage>
    </Box>
  );
};
