import { PermissionName } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { PageError } from './PageError';

// TODO: Remove this component when redo permissions
export const AdminProtectedRoute = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { auth, user } = useAppContext();

  const hasPermission = user.extraPermissions.includes(PermissionName.Admin);

  if (auth.isLoading || user.isLoading) {
    return <Spin />;
  }

  if (!auth.isAuthenticated) {
    return <Spin />;
  }

  if (!hasPermission) {
    return <PageError type='Forbidden' />;
  }

  return <>{children}</>;
};
