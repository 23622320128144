import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SortableReportTemplateStatementSites } from './SortableReportTemplateStatementSites';
import { SortableReportTemplateStatements } from './SortableReportTemplateStatements';

export const HookedStatementsManager = () => {
  const { watch } = useFormContext();
  const id = watch(`currentReportTemplateStatementSiteId`);

  return (
    <Flex>
      <Box w='sm' flexGrow={0}>
        <SortableReportTemplateStatementSites />
      </Box>
      <Box borderLeftColor='gray.100' borderLeftWidth='1px' ml={4} w='full' flexGrow={1}>
        <SortableReportTemplateStatements key={`sortable-statement-templates-${id}`} />
      </Box>
    </Flex>
  );
};
