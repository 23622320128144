import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { AuditTrailEntry } from '@piccolohealth/echo-common';
import React from 'react';
import { JsonTree } from '../generic/JsonTree';
import { createModal, showModal } from '../generic/Modal';

interface AuditTrailEntryInfoModalProps {
  entry: AuditTrailEntry;
}

export const AuditTrailEntryInfoModal = createModal<AuditTrailEntryInfoModalProps>((props) => {
  const { entry, modal } = props;

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{entry.message}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <JsonTree src={entry} collapsed={2} style={{ wordBreak: 'break-all' }} />
        </ModalBody>
        <ModalFooter>
          <Button key='close' onClick={modal.hide}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

interface Props {
  entry: AuditTrailEntry;
}

export const AuditTrailEntryInfoControl = (props: Props) => {
  const { entry } = props;

  return <Button onClick={() => showModal(AuditTrailEntryInfoModal, { entry })}>Details</Button>;
};
