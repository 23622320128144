import React from 'react';

export declare type AnyFunction<T = any> = (...args: T[]) => any;

export function useGetLatest<T>(obj: T) {
  const ref = React.useRef<T>();
  ref.current = obj;

  return React.useCallback(() => ref.current, []);
}

type DebounceRef = {
  timeout?: number;
  promise?: Promise<any>;
  reject?: AnyFunction;
  resolve?: AnyFunction;
};

// Adapted from https://github.com/tannerlinsley/react-table/blob/master/src/publicUtils.js#L163
export function useAsyncDebounce<F extends AnyFunction<any>>(defaultFn: F, defaultWait = 0) {
  const debounceRef = React.useRef<DebounceRef>({});

  const getDefaultFn = useGetLatest(defaultFn);
  const getDefaultWait = useGetLatest(defaultWait);

  return React.useCallback(
    async (...args: F[]) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = window.setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          const defaultFunction = getDefaultFn();
          if (!defaultFunction) {
            debounceRef.current?.reject?.(new Error('No default function found'));
            return;
          }
          debounceRef.current?.resolve?.(await defaultFunction(...args));
        } catch (err) {
          debounceRef.current?.reject?.(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait],
  );
}
