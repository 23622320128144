import gql from 'graphql-tag';

export const Organization = gql`
  query Organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      shortId
      name
      stripeCustomerId
      stripeSubscriptionId
      contract
      status
      addressLineOne
      addressLineTwo
      email
      phone
      logo
      timezone
      dicomSettings {
        ip
        port
        aet
      }
      distributionSettings {
        defaultDistributionType
        defaultDistribution {
          trigger
          payload {
            __typename
            ... on DistributionMedicalObjectsPayload {
              to {
                providerDetails {
                  id
                  firstName
                  middleName
                  lastName
                  prefix
                  suffix
                  address {
                    class
                    streetAddress
                    otherDesignation
                    city
                    state
                    postCode
                    country
                    addressType
                    otherGeographicDesignation
                  }
                }
                providerNumber
              }
              from {
                providerDetails {
                  id
                  firstName
                  middleName
                  lastName
                  prefix
                  suffix
                  address {
                    class
                    streetAddress
                    otherDesignation
                    city
                    state
                    postCode
                    country
                    addressType
                    otherGeographicDesignation
                  }
                }
                providerNumber
              }
            }
            ... on DistributionEmailPayload {
              sharerId
              sharerEmail
              sharerName
              shareeEmail
              message
            }
            ... on DistributionHealthLinkPayload {
              to {
                practitionerId
                practitionerName {
                  text
                  family
                  given
                  prefix
                  suffix
                }
                organizationId
                organizationName
                organizationAddress {
                  city
                  country
                  line
                  postalCode
                  state
                  text
                  type
                  use
                }
                endpointId
                endpointName
                providerNumber
              }
              from {
                providerNumber
                name
              }
            }
            ... on DistributionFaxPayload {
              to {
                faxNumber
              }
              from {
                sharerId
                sharerEmail
                sharerName
              }
            }
          }
        }
      }
      integrations {
        medicalObjects {
          id
          organizationId
          type
          createdAt
          updatedAt
          settings {
            enabled
            capricornUrl
          }
        }
        healthLink {
          id
          organizationId
          type
          createdAt
          updatedAt
          settings {
            enabled
          }
        }
        fax {
          id
          organizationId
          type
          createdAt
          updatedAt
          settings {
            enabled
          }
        }
      }
      featureFlags
      roles {
        id
        name
        description
        permissions
      }
    }
  }
`;

export const OrganizationMachineStats = gql`
  query OrganizationMachineStats($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      machineStats {
        machine {
          manufacturer
          model
        }
        count
        percentage
      }
    }
  }
`;
