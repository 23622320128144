import { AutoCompleteResult } from '@piccolohealth/echo-common';
import {
  AutoComplete,
  MultiSelectOption,
  MultiSelectProps,
  PaginationSelectFooter,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import debouncePromise from 'debounce-promise';
import React from 'react';
import { useAutoCompleteEmailsInfiniteQuery } from '../../graphql/hooks/useAutoComplete';
import { useAppContext } from '../../hooks/useAppContext';

const DEBOUNCE_MS = 1000;

export interface AutoCompleteEmailProps
  extends Omit<
    MultiSelectProps<AutoCompleteResult>,
    'value' | 'onChange' | 'options' | 'selectedValues'
  > {
  value: string;
  onChange: (value: string) => void;
}

export const AutoCompleteEmail = (props: AutoCompleteEmailProps) => {
  const { value, onChange, isDisabled, ...rest } = props;
  const { organization } = useAppContext();
  const [inputValue, setInputValue] = React.useState<string>(value);

  React.useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value ?? '');
    }
  }, [inputValue, value]);

  const req = {
    organizationId: organization.id,
    request: {
      filter: inputValue,
    },
  };

  const query = useAutoCompleteEmailsInfiniteQuery(req, {
    enabled: false,
    keepPreviousData: true,
    cacheTime: 0,
  });

  const isLoading = query.isRefetching;
  const hasMore = query.hasNextPage ?? false;

  const pagination = P.first(query.data?.pages ?? [])?.organization?.autoComplete?.emails
    .pagination;

  const emails = (query.data?.pages ?? [])
    .flat()
    .flatMap((page) => page.organization?.autoComplete.emails.results || []);

  const options: MultiSelectOption<string>[] = React.useMemo(() => {
    return emails.map((email) => {
      return {
        value: email.value,
        label: email.value,
        raw: email.value,
      };
    });
  }, [emails]);

  const debouncedRefetch = React.useMemo(
    () => debouncePromise(query.refetch, DEBOUNCE_MS),
    [query.refetch],
  );

  const onChangePrime = React.useCallback(
    (value: string) => {
      setInputValue(value);
      onChange(value);
      debouncedRefetch();
    },
    [debouncedRefetch, onChange, setInputValue],
  );

  const onOpen = React.useCallback(() => {
    if (!query.isFetched) {
      query.refetch();
    }
  }, [query]);

  return (
    <AutoComplete
      {...rest}
      options={options}
      value={inputValue}
      onChange={onChangePrime}
      onInputChange={onChangePrime}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onOpen={onOpen}
      components={{
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items='email'
              total={pagination.total}
              fetchNextPage={query.fetchNextPage}
              isLoading={isLoading}
              hasMore={hasMore}
            />
          ) : null,
      }}
    />
  );
};
