import { useTheme } from '@chakra-ui/react';
import { InsightsValue } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { PieChart, PieChartData } from '../../../components/charts/PieChart';
import { ChakraColorMap, getChakraColor } from '../../../utils/colors';

interface Props {
  values: InsightsValue[];
}

const getChartData = (values: InsightsValue[], colors: ChakraColorMap): PieChartData[] => {
  if (P.isEmpty(values)) {
    return [];
  }

  return values.map((row) => {
    return {
      id: `${row.name}`,
      label: row.name,
      value: row.count,
      color: getChakraColor(colors, row.color ?? 'purple'),
    };
  });
};

export const InsightsPieChart = (props: Props) => {
  const { values } = props;

  const theme = useTheme();

  return <PieChart chartData={getChartData(values, theme.colors)} />;
};
