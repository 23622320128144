import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import React from 'react';
import { NavLink, To } from 'react-router-dom';

export interface ButtonLinkProps extends ButtonProps {
  to: To;
  replace?: boolean;
  state?: any;
}

export const NavButton = forwardRef((props: ButtonLinkProps, ref) => {
  const { to, replace, state, children, ...rest } = props;

  return (
    <NavLink to={props.to} replace={replace} state={state}>
      {({ isActive }) => (
        <Button ref={ref} isActive={isActive} size='sm' colorScheme='purple' {...rest}>
          {children}
        </Button>
      )}
    </NavLink>
  );
});
