import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import ReportIllustration from '/illustrations/report.png';
import UploadIllustration from '/illustrations/upload.png';
import { BulletSteps } from '../../components/generic/BulletSteps';
import { ActionCard } from './ActionCard';

interface Props {
  onCreateReportAction: () => void;
  onUploadAction: () => void;
  onClose: () => void;
}

export const ChooseActionStep = (props: Props) => {
  const { onCreateReportAction, onUploadAction, onClose } = props;

  return (
    <>
      <ModalHeader>
        <Flex align='start'>
          <Box>
            <Heading size='md'>What would you like to do?</Heading>
            <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
              Please select one of the following to proceed
            </Text>
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <BulletSteps currentStep={1} totalSteps={2} />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </HStack>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <HStack
          w='full'
          flexDir='row'
          align='center'
          justify='space-around'
          spacing={8}
          mt={8}
          mb={8}
        >
          <ActionCard
            image={
              <Box w={40}>
                <Image
                  w={24}
                  h={24}
                  ml={10}
                  src={ReportIllustration}
                  alt='Create blank report'
                  fallback={
                    <Skeleton ml={10} h={24} w={24} startColor='gray.100' endColor='gray.50' />
                  }
                />
              </Box>
            }
            title='Create a blank report'
            description='A new report with no DICOM files attached'
            onClick={onCreateReportAction}
            data-pw='createBlankReportCard'
          />
          <ActionCard
            image={
              <Image
                w={24}
                h={24}
                src={UploadIllustration}
                alt='Upload DICOM files'
                fallback={<Skeleton h={24} w={24} startColor='gray.100' endColor='gray.50' />}
              />
            }
            title='Upload DICOM files'
            description='Upload selected DICOM files to create a report'
            onClick={onUploadAction}
            data-pw='uploadStudyCard'
          />
        </HStack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </>
  );
};
