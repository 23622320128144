import { Async, P } from '@piccolohealth/util';
import { FeatureFlag, User } from '../graphql/types';
import { PermissionName } from './constants';
import { PiccoloError } from './errors';
import { toSentence } from './generic';

export interface Permission {
  validate: (permissions: string[]) => Promise<boolean>;
  values: string[];
}

export const fixedPermission = (perm: string): Permission => {
  return {
    validate: async (permissions: string[]) => permissions.includes(perm),
    values: [perm],
  };
};

export const idPermission = (perm: string) => (id: string) => {
  return fixedPermission(`${perm}:${id}`);
};

export const andPermission = (...perms: Permission[]): Permission => {
  return {
    validate: async (permissions: string[]) => {
      return Async.reduce(
        perms,
        async (acc, perm) => (await perm.validate(permissions)) && acc,
        true,
      );
    },
    values: perms.flatMap((perm) => perm.values),
  };
};

export const orPermission = (...perms: Permission[]): Permission => {
  return {
    validate: async (permissions: string[]) => {
      return Async.some(perms, (perm) => perm.validate(permissions));
    },
    values: perms.flatMap((perm) => perm.values),
  };
};

export const withPermission = async <T>(
  user: User,
  organizationId: string,
  fn: () => T,
  permission: Permission,
): Promise<T> => {
  const membership = user.organizationMemberships.find((organizationMembership) => {
    return organizationMembership.organizationId === organizationId;
  });

  const hasPermission = membership && (await permission.validate(membership?.permissions));

  if (hasPermission) {
    return fn();
  }

  throw new PiccoloError({
    type: 'Forbidden',
    message: 'User does not have permission to perform this action',
    metadata: { user, permission },
  });
};

// TODO: Remove this when redo permissions
export const withExtraPermission = async <T>(
  user: User,
  fn: () => Promise<T>,
  permission: string,
): Promise<T> => {
  const hasPermission = user.extraPermissions.includes(permission);

  if (hasPermission) {
    return fn();
  }

  throw new PiccoloError({
    type: 'Forbidden',
    message: 'User does not have permission to perform this action',
    metadata: { user, permission },
  });
};

export const hasFeature = (featureFlags: FeatureFlag[], featureFlag: FeatureFlag): boolean => {
  return featureFlags.some((flag) => flag === featureFlag);
};

export const renderPermissions = (
  permissions: string[],
): {
  resource: string;
  details: string;
}[] => {
  // Split each permission into it's resource and details
  const split = permissions.map((permission) => {
    const [resource, ...details] = permission.split(':');
    return { resource, details: details.join(' ') };
  });

  // Group all permissions under common resources (e.g. organization, studies)
  const group = split.reduce((acc: { [key: string]: string[] }, { resource, details }) => {
    return { ...acc, [resource]: acc[resource] ? [...acc[resource], details] : [details] };
  }, {});

  // Finally, render all groups as a human readable string
  return Object.entries(group).map(([resource, permissions]) => {
    return { resource: P.capitalize(resource), details: P.capitalize(toSentence(permissions)) };
  });
};

export const PERMISSIONS = {
  jobRead: fixedPermission(PermissionName.JobRead),
  labelsCreate: fixedPermission(PermissionName.LabelsCreate),
  labelsDelete: fixedPermission(PermissionName.LabelsDelete),
  labelsUpdate: fixedPermission(PermissionName.LabelsUpdate),
  labelsRead: fixedPermission(PermissionName.LabelsRead),
  organizationSupport: fixedPermission(PermissionName.OrganizationSupport),
  organizationUpdate: fixedPermission(PermissionName.OrganizationUpdate),
  organizationRead: fixedPermission(PermissionName.OrganizationRead),
  distributionCreate: fixedPermission(PermissionName.DistributionCreate),
  distributionRead: fixedPermission(PermissionName.DistributionRead),
  distributionUpdate: fixedPermission(PermissionName.DistributionUpdate),
  distributionDelete: fixedPermission(PermissionName.DistributionDelete),
  distributionSend: fixedPermission(PermissionName.DistributionSend),
  reportsCreate: fixedPermission(PermissionName.ReportsCreate),
  reportsDelete: fixedPermission(PermissionName.ReportsDelete),
  reportsFinalize: fixedPermission(PermissionName.ReportsFinalize),
  reportsRead: fixedPermission(PermissionName.ReportsRead),
  reportsReadShared: fixedPermission(PermissionName.ReportsReadShared),
  reportsReport: fixedPermission(PermissionName.ReportsReport),
  reportsShare: fixedPermission(PermissionName.ReportsShare),
  reportsExport: fixedPermission(PermissionName.ReportsExport),
  reportsLabelAdd: fixedPermission(PermissionName.ReportsLabelAdd),
  reportsLabelRemove: fixedPermission(PermissionName.ReportsLabelRemove),
  reportTemplateCreate: fixedPermission(PermissionName.ReportTemplateCreate),
  reportTemplateRead: fixedPermission(PermissionName.ReportTemplateRead),
  reportTemplateUpdate: fixedPermission(PermissionName.ReportTemplateUpdate),
  reportAttachmentCreate: fixedPermission(PermissionName.ReportAttachmentCreate),
  reportAttachmentRead: fixedPermission(PermissionName.ReportAttachmentRead),
  reportAttachmentDelete: fixedPermission(PermissionName.ReportAttachmentDelete),
  usersRead: fixedPermission(PermissionName.UsersRead),
  usersUpdate: fixedPermission(PermissionName.UsersUpdate),
  worklistRead: fixedPermission(PermissionName.WorklistRead),
  worklistUpdate: fixedPermission(PermissionName.WorklistUpdate),
  worklistDelete: fixedPermission(PermissionName.WorklistDelete),
  worklistCreate: fixedPermission(PermissionName.WorklistCreate),
};
