import { Heading } from '@chakra-ui/react';
import { RangeDatepicker } from '@piccolohealth/ui';
import React from 'react';
import { ReportsFilter } from '../../../hooks/useReportsFilter';

interface Props {
  filter: ReportsFilter;
}

export const ReportDateFilterControl = ({ filter }: Props) => {
  const {
    onReportStartDateFilter,
    onReportEndDateFilter,
    reportStartDateFilter,
    reportEndDateFilter,
  } = filter;

  return (
    <>
      <Heading as='h5' size='sm' pb='2' data-pw='reportFilterDateTitle'>
        Report date
      </Heading>
      <RangeDatepicker
        startDate={reportStartDateFilter ?? null}
        endDate={reportEndDateFilter ?? null}
        onStartDateChange={(date) => onReportStartDateFilter(date ?? undefined)}
        onEndDateChange={(date) => onReportEndDateFilter(date ?? undefined)}
      />
    </>
  );
};
