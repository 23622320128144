import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

export interface FileInputProps extends ButtonProps {
  multiple: boolean;
  accept?: string;
  files: File[];
  onFilesChange: (files: File[]) => void;
  renderFilesSelected?: (files: File[]) => JSX.Element;
}

export const FileInput = (props: React.PropsWithChildren<FileInputProps>) => {
  const { multiple, accept, files, onFilesChange, renderFilesSelected, children, ...rest } = props;
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const onClick = () => {
    inputRef.current?.click();
  };

  const onChange = (files: FileList | null) => {
    onFilesChange(Object.values(files || []));
  };

  const contents = renderFilesSelected && files.length > 0 ? renderFilesSelected(files) : children;

  return (
    <Button onClick={onClick} variant='outline' {...rest} data-pw='uploadFileButton'>
      <input
        type='file'
        multiple={multiple}
        hidden
        accept={accept}
        ref={inputRef}
        data-pw='uploadFileInput'
        onChange={(e) => onChange(e.target.files)}
      />
      {contents}
    </Button>
  );
};
