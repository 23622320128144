import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { PiccoloError } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useHealthLinkIntegrationSettingsQuery } from '../../../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../../../hooks/useAppContext';
import { Error } from '../../../generic/Error';
import { createModal } from '../../../generic/Modal';
import { IntegrationStatus } from '../IntegrationStatus';
import { HealthLinkIntegrationForm } from './HealthLinkIntegrationForm';

interface Props {
  title: string;
  description: string;
  logo: string;
  isIntegrationEnabled: boolean;
}

export const HealthLinkIntegrationModal = createModal<Props>((props) => {
  const { modal, title, description, logo, isIntegrationEnabled } = props;
  const { organization } = useAppContext();

  const { data, error, isLoading } = useHealthLinkIntegrationSettingsQuery({
    organizationId: organization.id,
  });

  const integration = data?.organization?.integrations.healthLink;

  let content;

  if (isLoading) {
    content = <Spin />;
  } else if (error) {
    content = <Error error={error} p={4} />;
  } else if (P.isUndefined(integration)) {
    content = (
      <Error
        error={new PiccoloError({ type: 'IntegrationNotFound', message: 'Integration not found' })}
        p={4}
      />
    );
  } else {
    content = <HealthLinkIntegrationForm initialValues={integration.settings} hide={modal.hide} />;
  }

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth='1px'>
          <Box mr={8}>
            <IntegrationStatus
              title={title}
              description={description}
              logo={logo}
              isIntegrationEnabled={isIntegrationEnabled}
            />
          </Box>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
      </ModalContent>
    </Modal>
  );
});
