import { Button, HStack, IconButton, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { VariablesBySite } from '../sidebar/MeasurementsSidebar';
import { UseMeasurementControlReturn } from '../tool/hooks/useMeasurementControl';

export interface StepProps {
  measurementControl: UseMeasurementControlReturn;
  variables: VariablesBySite;
}

export interface StepPageProps {
  title: string;
  description: string;
  onClose: () => void;
  onBack?: () => void;
}

export const StepPage = (props: React.PropsWithChildren<StepPageProps>) => {
  const { title, description, onClose, onBack, children } = props;

  return (
    <Stack w='full' spacing={4}>
      <Stack w='full' spacing={1}>
        <HStack>
          <Text fontSize='sm' color='white' fontWeight='semibold'>
            {title}
          </Text>
          <Spacer />
          {onBack && (
            <Button
              size='xs'
              leftIcon={<FaArrowLeft />}
              variant='ghost'
              color='white'
              _hover={{
                bg: 'purple.600',
              }}
              _active={{
                bg: 'purple.400',
              }}
              onClick={onBack}
            >
              Back
            </Button>
          )}
          <IconButton
            aria-label='Close'
            lineHeight='normal'
            icon={<FaTimes />}
            size='xs'
            variant='ghost'
            color='white'
            _hover={{
              bg: 'purple.600',
            }}
            _active={{
              bg: 'purple.400',
            }}
            onClick={onClose}
          />
        </HStack>
        <Text fontSize='xs' color='gray.300'>
          {description}
        </Text>
      </Stack>
      {children}
    </Stack>
  );
};
