import { Permission } from '@piccolohealth/echo-common';
import React from 'react';
import { usePermission } from '../../hooks/usePermission';

interface Props {
  permission: Permission;
}

export const VisibleWithPermission = (props: React.PropsWithChildren<Props>) => {
  const { permission } = props;
  const hasPermission = usePermission(permission).value;

  if (hasPermission) {
    return <>{props.children}</>;
  }

  return null;
};
