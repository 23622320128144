import React from 'react';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { Divider as ChakraDivider } from '../../../generic/Divider';
import { useSSRNode } from '../../hooks/useSSRNode';
import { NodeBorderProps, NodeBorderSettings } from '../nodes/NodeBorder';
import { NodeSpacingProps, NodeSpacingSettings } from '../nodes/NodeSpacing';
import { SettingsAccordion } from '../settings/SettingsAccordion';
import { SettingsItem } from '../settings/SettingsItem';

export type Props = BaseNodeProps & NodeSpacingProps & NodeBorderProps;

export const Divider = (props: React.PropsWithChildren<Props>) => {
  const { children, ssr, borderColor, ...rest } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  return (
    <div ref={(ref) => connect(drag(ref))}>
      <ChakraDivider boxSizing='border-box' borderColor={borderColor ?? 'gray.400'} {...rest} />
    </div>
  );
};

const DividerSettings = () => {
  return (
    <SettingsAccordion>
      <SettingsItem title='Spacing'>
        <NodeSpacingSettings />
      </SettingsItem>
      <SettingsItem title='Border'>
        <NodeBorderSettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

Divider.defaultProps = {
  ...NodeSpacingSettings.defaultProps,
  ...NodeBorderSettings.defaultProps,
  margin: '12px 0px 12px 0px',
};

Divider.craft = {
  name: 'Divider',
  props: Divider.defaultProps,
  related: {
    settings: DividerSettings,
  },
};
