import { Button, HStack, Spacer } from '@chakra-ui/react';
import React from 'react';
import { Card } from '../../../components/generic/Card';
import { Divider } from '../../../components/generic/Divider';
import { showModal } from '../../../components/generic/Modal';
import { useAppContext } from '../../../hooks/useAppContext';
import { DefaultDistributionAddModal } from './DefaultDistributionAddModal';
import { DefaultDistributionDeleteModal } from './DefaultDistributionDeleteModal';
import { DistributionAddButton } from './DistributionAddButton';
import { DistributionHeader } from './DistributionHeader';
import { DistributionSummary } from './DistributionSummary';

export const DefaultDistributionChooser = () => {
  const { organization } = useAppContext();

  const onAddDefaultDistribution = React.useCallback(() => {
    showModal(DefaultDistributionAddModal);
  }, []);

  const onRemove = React.useCallback(() => {
    showModal(DefaultDistributionDeleteModal);
  }, []);

  const defaultDistribution = organization.distributionSettings.defaultDistribution;

  if (!defaultDistribution) {
    return <DistributionAddButton onClick={onAddDefaultDistribution} minH='40' />;
  }

  return (
    <Card p={4} h='full' w='lg'>
      <DistributionHeader payload={defaultDistribution.payload} />
      <Divider />
      <DistributionSummary
        payload={defaultDistribution.payload}
        trigger={defaultDistribution.trigger}
      />
      <Spacer />
      <Divider />
      <HStack>
        <Spacer />
        <Button variant='link' colorScheme='red' onClick={onRemove}>
          Remove
        </Button>
      </HStack>
    </Card>
  );
};
