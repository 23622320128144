import { HStack, Spacer } from '@chakra-ui/react';
import { ReportAttachment } from '@piccolohealth/echo-common';
import React from 'react';
import { Card } from '../../../components/generic/Card';
import { Divider } from '../../../components/generic/Divider';
import { AttachmentDeleteButton } from './AttachmentDeleteControl';
import { AttachmentDownloadButton } from './AttachmentDownloadControl';
import { AttachmentHeader } from './AttachmentHeader';
import { AttachmentSummary } from './AttachmentSummary';
import { AttachmentViewButton } from './AttachmentViewControl';

interface Props {
  reportId: string;
  attachment: ReportAttachment;
}

export const AttachmentCard = (props: Props) => {
  const { reportId, attachment } = props;

  return (
    <Card p={4} h='full' w='lg'>
      <AttachmentHeader attachment={attachment} />
      <Divider />
      <AttachmentSummary attachment={attachment} />
      <Spacer />
      <Divider />
      <HStack>
        <Spacer />
        <AttachmentDeleteButton
          reportId={reportId}
          attachmentId={attachment.id}
          variant='link'
          colorScheme='red'
        />
        <AttachmentViewButton viewUrl={attachment.viewUrl} variant='link' colorScheme='purple' />
        <AttachmentDownloadButton
          downloadUrl={attachment.downloadUrl}
          variant='link'
          colorScheme='purple'
        />
      </HStack>
    </Card>
  );
};
