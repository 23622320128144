import { Icon, Tag, TagLabel } from '@chakra-ui/react';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FaHourglassEnd, FaHourglassStart } from 'react-icons/fa';

interface Props {
  expiresAt: DateTime | null;
  isExpired: boolean;
}

export const ReportShareExpiryTag = (props: Props) => {
  const expiresAt = DateTime.fromISO(props.expiresAt as unknown as string);
  const expiry = expiresAt.toRelative();

  const oneDayFromNow = DateTime.now().plus({ days: 1 }).toMillis();
  const expiresWithinOneDay = expiresAt.toMillis() <= oneDayFromNow;

  const expiresAtText = () => {
    if (P.isNull(props.expiresAt)) {
      return 'No expiry';
    }

    if (props.isExpired) {
      return 'Expired';
    }

    return `Expires ${expiry}`;
  };

  return (
    <Tag
      size='sm'
      colorScheme={props.isExpired ? 'red' : expiresWithinOneDay ? 'orange' : 'gray'}
      data-pw='reportShareModalExpiryTag'
    >
      <Icon as={props.isExpired ? FaHourglassEnd : FaHourglassStart} />
      <TagLabel ml='2'>{expiresAtText()}</TagLabel>
    </Tag>
  );
};
