import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteLabel } from '../mutations/deleteLabel.mutation';

export const useDeleteLabelMutation = (
  options?: MutationOptions<Types.DeleteLabelMutation, Types.DeleteLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteLabel), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      await queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('labels') ||
          query.queryKey.includes('reports') ||
          query.queryKey.includes('report'),
      });
    },
  });
};
