import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReportTemplate } from '@piccolohealth/echo-common';
import { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMergeReportTemplateMutation } from '../../../graphql/hooks/useMergeReportTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormSection } from '../../forms/FormSection';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedResetButton } from '../../forms/hookform/HookedResetButton';
import { HookedSelect } from '../../forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { createModal } from '../../generic/Modal';

interface FormValues {
  newReportTemplateId: string;
}

interface Props {
  reportTemplate: ReportTemplate;
  reportTemplates: ReportTemplate[];
  onDelete: () => Promise<void>;
}

export const ReportTemplateMergeModal = createModal<Props>((props) => {
  const { modal, reportTemplate, reportTemplates, onDelete } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const options: SelectOption<ReportTemplate>[] = reportTemplates
    .filter((rt) => rt.id !== reportTemplate.id)
    .map((reportTemplate) => {
      return { label: reportTemplate.name, value: reportTemplate.id, raw: reportTemplate };
    });

  const initialValues: FormValues = {
    newReportTemplateId: reportTemplate.id,
  };

  const validationSchema = Yup.object({
    newReportTemplateId: Yup.string().required('Required'),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useMergeReportTemplateMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const { newReportTemplateId } = values;

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          oldReportTemplateId: reportTemplate.id,
          newReportTemplateId,
        })
        .then(async () => {
          modal.hide();
          methods.reset();
          await onDelete();
          successToast('Report template merged successfully');
        })
        .catch((err) => errorToast(`Error merging report template: ${err.message}`));
    },
    [
      successToast,
      errorToast,
      methods,
      modal,
      mutation,
      onDelete,
      organization.id,
      reportTemplate.id,
    ],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Merge report template</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection>
                <HookedFormItem label='Report template' name='newReportTemplateId'>
                  <HookedSelect
                    name='newReportTemplateId'
                    options={options}
                    placeholder='Choose a report template to merge with'
                    data-pw='reportTemplateMergeModalReportTemplateSelect'
                  />
                </HookedFormItem>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <HookedResetButton>Reset</HookedResetButton>
                <HookedSubmitButton data-pw='reportTemplateMergeModalSubmitButton'>
                  Merge
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
