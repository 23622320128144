import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { AddReportTemplate } from '../mutations/addReportTemplate.mutation';
import { useReportTemplatesQuery } from './useReportTemplatesQuery';

export const useAddReportTemplateMutation = (
  options?: MutationOptions<
    Types.AddReportTemplateMutation,
    Types.AddReportTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(AddReportTemplate), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportTemplatesQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
    },
  });
};
