import { Badge } from '@chakra-ui/react';
import React from 'react';

interface Props {
  count: number;
}

export const NumberedBadge = (props: Props) => {
  const { count } = props;

  const colorScheme = count > 0 ? 'purple' : 'gray';

  return (
    <Badge borderRadius='full' px='2' colorScheme={colorScheme}>
      {count}
    </Badge>
  );
};
