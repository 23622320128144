import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

export const TextLink = (props: ReactRouterLinkProps & ChakraLinkProps) => {
  const { children, ...rest } = props;

  return (
    <ChakraLink as={ReactRouterLink} {...rest}>
      {children}
    </ChakraLink>
  );
};
