import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import { CommandProps, Node, NodeViewProps, Range, mergeAttributes } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import React, { ComponentType } from 'react';
import { TiptapContext, useTiptapContext } from '../../utils/TiptapContext';
import { renderVariablePair } from '../../utils/renderVariablePair';

export type VariableNodeProps = {
  name: string;
  reportTemplateVariable: ReportTemplateVariable;
  isDisabled?: boolean;
  shouldCapitalize: () => boolean;
} & NodeViewProps;

interface VariableNodeOptions {
  context: TiptapContext;
  component: ComponentType<VariableNodeProps>;
}

export const VariableNode = Node.create<VariableNodeOptions>({
  name: 'variable',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      capitalize: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'variable[id]',
        getAttrs: (node) => {
          const id = (node as HTMLElement).getAttribute('id') as string;

          const matchingTemplate = this.options.context.variablePairs.find(
            ({ template }) => template.id === id,
          );

          // If the variable is not found in the template, then we should not parse
          // this variable
          if (!matchingTemplate) {
            return false;
          }

          // Otherwise, we should parse the variable
          return null;
        },
      },
    ];
  },

  renderHTML(props) {
    /**
     * Rendering HTML has two paths:
     *
     * If SSR=true, we render the variable as a placeholder or value
     *   The value is included in the DOM. Examples are:
     *   <variable id="123">normal</variable>
     *   <variable id="123" data-placeholder></variable>
     *
     * If SSR=false, we let React handle rendering.
     *   We only include the id in the DOM. Example:
     *   <variable id="123"></variable>
     *   This means the statement saved to the DB contains no value, only the variable id.
     */

    const renderedValue = renderVariablePair(
      this.options.context.variablePairs,
      props.node.attrs.id,
      props.node.attrs.capitalize,
    );

    const ssr = this.options.context.isDisabled;
    const isLoading = P.isUndefined(this.editor);

    if (ssr) {
      switch (renderedValue.type) {
        case 'placeholder':
          return [
            'variable',
            mergeAttributes(props.HTMLAttributes, { 'data-placeholder': true }),
            '',
          ];

        case 'empty':
          return ['variable', mergeAttributes(props.HTMLAttributes), ''];

        case 'value':
          return ['variable', mergeAttributes(props.HTMLAttributes), renderedValue.value];
      }
    } else {
      if (isLoading) {
        switch (renderedValue.type) {
          case 'placeholder':
            return [
              'variable',
              mergeAttributes(props.HTMLAttributes, { 'data-placeholder': true }),
              '',
            ];
          case 'empty':
            return ['variable', mergeAttributes(props.HTMLAttributes), ''];
          case 'value':
            return [
              'variable',
              mergeAttributes(props.HTMLAttributes, { 'data-placeholder': false }),
              renderedValue.value,
            ];
        }
      }

      // CSR value render with empty content, as React will handle rendering
      return ['variable', mergeAttributes(props.HTMLAttributes), ''];
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer((props: NodeViewProps) => {
      const { id } = props.node.attrs;
      const { getFormName, reportTemplate, isDisabled } = useTiptapContext();

      const shouldCapitalize = React.useCallback(() => {
        const pos = props.getPos();

        if (P.isNil(pos)) {
          return false;
        }

        // Get the position of the variable
        const resolvedPos = props.editor.view.state.doc.resolve(pos);
        // Get the text in the node, before the variable
        const textBefore = resolvedPos.nodeBefore?.textContent?.trimEnd();

        // If there is no text before the variable, then we should capitalize
        if (!textBefore) {
          return true;
        }

        // If the trimmed text before the variable ends with a full stop, then we should capitalize
        // We should consider adding more punctuation rules here
        return textBefore.endsWith('.');
      }, [props]);

      const reportTemplateVariable = reportTemplate.variables.find((v) => v.id === id);

      if (!reportTemplateVariable) {
        return null;
      }

      const name = getFormName(id);

      return (
        <NodeViewWrapper as='span'>
          <this.options.component
            {...props}
            name={name}
            reportTemplateVariable={reportTemplateVariable}
            isDisabled={isDisabled}
            shouldCapitalize={shouldCapitalize}
          />
        </NodeViewWrapper>
      );
    });
  },

  addCommands() {
    return {
      insertVariableAt:
        (range: Range, id: string) =>
        ({ editor, chain }: CommandProps) => {
          const nodeBefore = editor.view.state.selection.$from.nodeBefore;

          // If the previous character is /, then extend the range back 1
          if (nodeBefore?.text?.endsWith('/')) {
            range.from -= 1;
          }

          range.to -= 1;

          return chain().insertContentAt(range, { type: this.name, attrs: { id } }).run();
        },
    };
  },
});
