import { Handlebars } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import { useWatch } from 'react-hook-form';
import { useReport } from '../context/ReportContext';

export const useCompiledTemplate = (template: string) => {
  const { timezone, reportTemplate } = useReport();

  const handlebarsVariables = Handlebars.getHandlebarsVariables(template).flatMap(
    (v) => v.variables,
  );
  const variableIds = handlebarsVariables.map((v) => `variables.${v}`);
  const variables = P.compact(useWatch({ name: variableIds }));
  const values = P.keyBy(variables, (v) => v.id);

  const compiledTemplate = Handlebars.compileHandlebarsTemplate({
    timezone,
    template,
    reportTemplate,
    values,
  });

  return {
    values,
    compiledTemplate,
  };
};
