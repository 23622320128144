import { Button, Icon, Menu, MenuButton, MenuDivider, MenuList } from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { SelectedReportsDeleteMenuItem } from './SelectedReportsDeleteControl';
import { SelectedReportsExportMenuItem } from './SelectedReportsExportControl';

interface Props {
  reportIds: string[];
  onDelete: () => Promise<void>;
}

export const SelectedReportsActionsButton = ({ reportIds, onDelete }: Props) => {
  const isDisabled = reportIds.length < 1;

  return (
    <Menu>
      <MenuList>
        <SelectedReportsExportMenuItem reportIds={reportIds} key='export' />
        <MenuDivider />
        <SelectedReportsDeleteMenuItem reportIds={reportIds} onDelete={onDelete} key='delete' />
      </MenuList>
      <MenuButton
        as={Button}
        variant='outline'
        isDisabled={isDisabled}
        rightIcon={<Icon as={FaAngleDown} />}
        data-pw='selectedReportsActionMenu'
      >
        Actions
      </MenuButton>
    </Menu>
  );
};
