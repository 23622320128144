import { ReportStatusFilter, ReportTypeFilter } from '@piccolohealth/echo-common';
import {
  ParamDecoder,
  arrayOfParam,
  dateTimeParam,
  numberParam,
  stringParam,
  useDebouncedCallback,
  useQueryParams,
} from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import { PaginationFilter } from '../graphql/fetcher';

const DEFAULT_REPORTS_PAGE_NUMBER = 0;
const DEFAULT_REPORTS_PAGE_SIZE = 10;
const DEFAULT_REPORT_NAME_FILTER = '';
const DEFAULT_REPORT_PATIENT_DOB_FILTER = undefined;
const DEFAULT_REPORT_START_DATE_FILTER = undefined;
const DEFAULT_REPORT_END_DATE_FILTER = undefined;
const DEFAULT_REPORT_STATUSES_FILTER: ReportStatusFilter[] = [];
const DEFAULT_REPORT_TYPE_FILTER: ReportTypeFilter[] = [];
const DEFAULT_ASSIGNED_TO_FILTER: string[] = [];
const DEFAULT_LABELS_FILTER: string[] = [];

export const reportsFilterDefaults: ReportsFilter = {
  pageSizeOptions: [10, 20, 30],
  reportPatientNameFilter: DEFAULT_REPORT_NAME_FILTER,
  reportPatientDobFilter: DEFAULT_REPORT_PATIENT_DOB_FILTER,
  reportStartDateFilter: DEFAULT_REPORT_START_DATE_FILTER,
  reportEndDateFilter: DEFAULT_REPORT_END_DATE_FILTER,
  reportStatusesFilter: DEFAULT_REPORT_STATUSES_FILTER,
  reportTypesFilter: DEFAULT_REPORT_TYPE_FILTER,
  assignedToFilter: DEFAULT_ASSIGNED_TO_FILTER,
  labelsFilter: DEFAULT_LABELS_FILTER,
  pageSize: DEFAULT_REPORTS_PAGE_SIZE,
  currentPageNumber: DEFAULT_REPORTS_PAGE_NUMBER,
  showTotal: () => '',
  setPageSize: P.noop,
  setCurrentPageNumber: P.noop,
  onReportPatientNameFilter: P.noop,
  onReportPatientDobFilter: P.noop,
  onReportStartDateFilter: P.noop,
  onReportEndDateFilter: P.noop,
  onReportStatusesFilter: P.noop,
  onReportTypesFilter: P.noop,
  onAssignedToFilter: P.noop,
  onLabelsFilter: P.noop,
};

const reportTypeFilterParam: ParamDecoder<ReportTypeFilter> = {
  encode: (value: ReportTypeFilter) => {
    if (P.isNil(value.reportTemplateId)) {
      return 'unknown';
    } else {
      return value.reportTemplateId;
    }
  },
  decode: (value: string) => {
    return {
      reportTemplateId: value === 'unknown' ? undefined : value,
    };
  },
};

export interface ReportsFilter extends PaginationFilter {
  reportPatientNameFilter: string;
  reportPatientDobFilter: DateTime | undefined;
  reportStartDateFilter: DateTime | undefined;
  reportEndDateFilter: DateTime | undefined;
  reportStatusesFilter: ReportStatusFilter[];
  reportTypesFilter: ReportTypeFilter[];
  assignedToFilter: string[];
  labelsFilter: string[];

  onReportPatientNameFilter: (name: string) => void;
  onReportPatientDobFilter: (date?: DateTime) => void;
  onReportStartDateFilter: (start?: DateTime) => void;
  onReportEndDateFilter: (end?: DateTime) => void;
  onReportStatusesFilter: (values: ReportStatusFilter[]) => void;
  onReportTypesFilter: (values: ReportTypeFilter[]) => void;
  onAssignedToFilter: (values: string[]) => void;
  onLabelsFilter: (values: string[]) => void;
}

export const useReportsFilter: () => ReportsFilter = () => {
  const [params, setParams] = useQueryParams({
    page: numberParam,
    pageSize: numberParam,
    name: stringParam,
    dob: dateTimeParam,
    start: dateTimeParam,
    end: dateTimeParam,
    type: arrayOfParam(reportTypeFilterParam, { delimiter: ',' }),
    status: arrayOfParam(stringParam, { delimiter: ',' }),
    assignedTo: arrayOfParam(stringParam),
    labels: arrayOfParam(stringParam),
  });

  const setCurrentPageNumber = (value: number) =>
    setParams({
      page: value,
    });

  const setPageSize = (value: number) =>
    setParams({
      pageSize: value,
    });

  const onReportPatientNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        name: value,
        page: 0,
      }),
    1000,
  );

  const onReportPatientDobFilter = (value?: DateTime) =>
    setParams({
      dob: value,
      page: 0,
    });

  const onReportStartDateFilter = (value?: DateTime) =>
    setParams({
      start: value,
      page: 0,
    });

  const onReportEndDateFilter = (value?: DateTime) =>
    setParams({
      end: value,
      page: 0,
    });

  const onReportStatusesFilter = (values: ReportStatusFilter[]) =>
    setParams({
      status: values,
      page: 0,
    });

  const onReportTypesFilter = (values: ReportTypeFilter[]) =>
    setParams({
      type: values,
      page: 0,
    });

  const onAssignedToFilter = (values: string[]) =>
    setParams({
      assignedTo: values,
      page: 0,
    });

  const onLabelsFilter = (values: string[]) =>
    setParams({
      labels: values,
      page: 0,
    });

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} reports`;

  return {
    pageSizeOptions: [10, 20, 30],
    reportPatientNameFilter: params.name ?? DEFAULT_REPORT_NAME_FILTER,
    reportPatientDobFilter: params.dob ?? DEFAULT_REPORT_PATIENT_DOB_FILTER,
    reportStartDateFilter: params.start ?? DEFAULT_REPORT_START_DATE_FILTER,
    reportEndDateFilter: params.end ?? DEFAULT_REPORT_END_DATE_FILTER,
    reportStatusesFilter: (params.status as ReportStatusFilter[]) ?? DEFAULT_REPORT_STATUSES_FILTER,
    reportTypesFilter: params.type ?? DEFAULT_REPORT_TYPE_FILTER,
    assignedToFilter: params.assignedTo ?? DEFAULT_ASSIGNED_TO_FILTER,
    labelsFilter: params.labels ?? DEFAULT_LABELS_FILTER,
    pageSize: params.pageSize ?? DEFAULT_REPORTS_PAGE_SIZE,
    currentPageNumber: params.page ?? DEFAULT_REPORTS_PAGE_NUMBER,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    onReportPatientNameFilter,
    onReportPatientDobFilter,
    onReportStartDateFilter,
    onReportEndDateFilter,
    onReportStatusesFilter,
    onReportTypesFilter,
    onAssignedToFilter,
    onLabelsFilter,
  };
};
