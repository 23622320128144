import { Button } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../generic/Modal';
import { ReportTemplateCreateModal } from '../../modals/templates/ReportTemplateCreateModal';

export const ReportTemplateCreateControl = () => {
  return (
    <Button
      size='sm'
      variant='solid'
      colorScheme='purple'
      leftIcon={<FaPlus />}
      data-pw='reportTemplateCreateButton'
      onClick={() => showModal(ReportTemplateCreateModal)}
    >
      Add template
    </Button>
  );
};
