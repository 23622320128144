import { Stack } from '@chakra-ui/react';
import React from 'react';
import { MeasurementSelect } from '../MeasurementSelect';
import { MeasurementInProgress } from './MeasurementInProgress';
import { StepPage, StepProps } from './StepPage';

export const LinearMeasurementStep = (props: StepProps) => {
  const { measurementControl, variables } = props;

  return (
    <StepPage
      title='Linear Measurement'
      description='Select the name, and draw on the viewer to take a linear measurement'
      onClose={measurementControl.onClose}
      onBack={measurementControl.onReset}
    >
      <Stack spacing={4}>
        <MeasurementSelect
          value={measurementControl.reportTemplateVariableId}
          onChange={measurementControl.onSelectReportTemplateVariableId}
          variables={variables}
          size='xs'
          type='linear'
        />
        <MeasurementInProgress control={measurementControl} />
      </Stack>
    </StepPage>
  );
};
