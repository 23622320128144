import { Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import { EChartsOption, PieSeriesOption } from 'echarts';
import React from 'react';
import { CHART_COLORS } from './ChartsTheme';
import { ECharts } from './ECharts';

interface Props {
  chartData: PieChartData[];
  units?: string;
}

export interface PieChartData {
  id: string;
  label: string;
  value: number;
  color: string;
}

export const PieChart = (props: Props) => {
  const { chartData, units } = props;

  const formattedData = React.useMemo(() => {
    return chartData.map((item) => ({
      name: item.label,
      value: item.value,
      itemStyle: {
        color: item.color || CHART_COLORS.gray,
      },
    }));
  }, [chartData]);

  const options: EChartsOption = React.useMemo(() => {
    return {
      tooltip: {
        trigger: 'item',
        formatter: `<strong>{b}</strong>: {c}${units ? ' ' + units : ''} ({d}%)`,
        textStyle: { fontSize: 12 },
      },
      legend: {
        orient: 'vertical',
        top: 'middle',
        bottom: 'middle', // Position legend at the bottom
        left: 0, // Align legend to the left
      },
      series: [
        {
          name: 'Data',
          type: 'pie',
          center: ['75%', '50%'], // Moves the chart to the top-right

          radius: ['30%', '60%'],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: 'inside',
            formatter: '{c}',
            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: formattedData,
        } as PieSeriesOption,
      ],
    };
  }, [formattedData, units]);

  if (P.isEmpty(chartData)) {
    return <Empty />;
  }

  return <ECharts options={options} />;
};
