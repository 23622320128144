import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigContext';
import { useCountdown } from '../../hooks/useCountdown';

export const IdleLogoutModal = () => {
  const { config } = useConfig();
  const { logout, isAuthenticated } = useAuth();
  const [isIdle, setIsIdle] = React.useState(false);
  const { start, seconds, stop } = useCountdown(config.settings.idleWarningTimeout / 1000);

  const handleOnIdle = React.useCallback(() => {
    setIsIdle(true);
    start();
  }, [setIsIdle, start]);

  const handleLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  const idleTimer = useIdleTimer({
    startManually: true,
    startOnMount: false,
    timeout: config.settings.idleTimeout,
    stopOnIdle: true,
    onIdle: handleOnIdle,
    crossTab: true,
  });

  const handleIdleReset = React.useCallback(() => {
    setIsIdle(false);
    idleTimer.reset();
    stop();
  }, [setIsIdle, idleTimer, stop]);

  React.useEffect(() => {
    if (isAuthenticated) {
      idleTimer.start();
    }
  }, [isAuthenticated, idleTimer]);

  React.useEffect(() => {
    if (seconds <= 0) {
      handleLogout();
    }
  }, [seconds, handleLogout]);

  if (!isIdle) {
    return null;
  }

  return (
    <ChakraV3.Modal
      open={isIdle}
      onOpenChange={(e) => !e.open && handleIdleReset()}
      title="You've been idle too long"
      footer={
        <ChakraV3.Button w='full' onClick={handleIdleReset}>
          Continue session
        </ChakraV3.Button>
      }
    >
      <ChakraV3.Result
        status='warning'
        title='Session expiry warning'
        description='Your session is about to expire due to inactivity. You will be logged out in:'
        extra={<ChakraV3.Heading size='4xl'>{seconds} seconds</ChakraV3.Heading>}
        p={4}
      />
    </ChakraV3.Modal>
  );
};
