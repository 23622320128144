import { Button, HStack, Icon, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface Props {
  title: string;
  description: string;
  badge?: React.ReactNode;
  icon: IconType;
  onClick: () => void;
}

export const MFATypeButton = (props: Props) => {
  const { title, description, badge, icon, onClick } = props;

  return (
    <Button
      size='lg'
      p={0}
      w='full'
      h='full'
      variant='outline'
      rounded='xl'
      onClick={onClick}
      _hover={{ bg: 'gray.50', borderColor: 'gray.500' }}
    >
      <HStack w='full' spacing={4} alignItems='center' py={4} px={4}>
        <Icon as={icon} fontSize='20' color='gray.600' />
        <Stack w='full' alignItems='start' spacing={1}>
          <HStack w='full'>
            <Text fontSize='md' fontWeight='semibold'>
              {title}
            </Text>
            <Spacer />
            {badge}
          </HStack>
          <Text fontSize='sm' color='secondary' fontWeight='normal'>
            {description}
          </Text>
        </Stack>
      </HStack>
    </Button>
  );
};
