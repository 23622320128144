import { Progress, Text } from '@chakra-ui/react';
import { InsightsValue } from '@piccolohealth/echo-common';
import { DataTable, createColumnHelper } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  values: InsightsValue[];
  limit: number;
}

interface Item {
  id: string;
  label: string;
  value: number;
  percentage: number;
  color: string;
}

export interface ChartData {
  items: Item[];
  total: number;
}

const getChartData = (values: InsightsValue[], limit: number): ChartData => {
  if (P.isEmpty(values)) {
    return {
      items: [],
      total: 0,
    };
  }

  const data = P.take(
    P.orderBy(values, (v) => v.count, 'desc'),
    limit,
  );

  const total = P.sum(data.map((r) => r.count));

  const items = data.map((row, index) => {
    return {
      id: `${row.name}-${index}`,
      label: row.name,
      value: row.count,
      color: 'purple',
      percentage: P.round((row.count / total) * 100, 2),
    };
  });

  return {
    items,
    total,
  };
};

export const InsightsTable = (props: Props) => {
  const { values, limit } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Item>();

    return [
      columnHelper.display({
        header: 'Name',
        cell: (ps) => <Text noOfLines={1}>{ps.row.original.label}</Text>,
      }),
      columnHelper.display({
        header: 'Reports',
        size: 40,
        cell: (ps) => <Text>{ps.row.original.value}</Text>,
      }),
      columnHelper.display({
        header: 'Percent',
        size: 80,
        cell: (ps) => (
          <Progress
            value={ps.row.original.percentage}
            colorScheme='purple'
            width='100%'
            rounded='xl'
          />
        ),
      }),
    ];
  }, []);

  const chartData = getChartData(values, limit);

  return <DataTable columns={columns} data={chartData.items} size='sm' />;
};
