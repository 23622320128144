import { P } from '@piccolohealth/util';

export interface ChakraColorMap {
  [id: string]: { [n: number]: string } | string;
}

export const getChakraColor = (colors: ChakraColorMap, color: string): string => {
  const chakraColor = P.get(colors, color);

  if (!chakraColor) {
    return 'purple.500';
  }

  if (P.isObject(chakraColor)) {
    return chakraColor[100];
  }

  return chakraColor;
};
