import { Machine, MachineFilter } from '@piccolohealth/echo-common';
import {
  MultiSelect,
  MultiSelectOption,
  MultiSelectProps,
  OnChangeRequest,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormItem } from '../../../../components/forms/FormItem';
import { useMachinesQuery } from '../../../../graphql/hooks/useMachinesQuery';

const machineToOption = (machine: Machine): MultiSelectOption<MachineFilter> => {
  return {
    value: JSON.stringify(machine),
    label: machine.model,
    raw: machine,
  };
};

interface Props
  extends Omit<
    MultiSelectProps<MachineFilter>,
    'value' | 'onChange' | 'options' | 'selectedValues'
  > {
  value: MachineFilter[];
  onChange: (value: MachineFilter[]) => void;
}

export const MachineFilterControl = (props: Props) => {
  const { value, onChange, ...rest } = props;
  const { data } = useMachinesQuery({});

  const options = React.useMemo(() => {
    const machines = (data?.dicom?.machines as Machine[]) ?? [];
    return machines.map(machineToOption);
  }, [data?.dicom?.machines]);

  const selectedValues: MultiSelectOption<MachineFilter>[] = React.useMemo(() => {
    return P.compact(
      value.map((machine) => options.find((o) => P.isEqual(JSON.parse(o.value), machine))),
    );
  }, [options, value]);

  const onChangePrime = React.useCallback(
    (req: OnChangeRequest<MachineFilter>) => {
      onChange(req.values.map(({ value }) => JSON.parse(value)));
    },
    [onChange],
  );

  return (
    <FormItem id='machine' label='Machine'>
      <MultiSelect
        size='sm'
        options={options}
        selectedValues={selectedValues}
        onChange={onChangePrime}
        placeholder='All machines'
        data-pw='filterByMachinesMultiselect'
        optionVariant='tag'
        {...rest}
      />
    </FormItem>
  );
};
