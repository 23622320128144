import { ResponsiveValue } from '@chakra-ui/react';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { Extensions, useEditor } from '@tiptap/react';
import React from 'react';
import { ContextMenu } from '../extensions/contextmenu/ContextMenu';
import { getContextMenuRenderer } from '../extensions/contextmenu/contextMenuRenderer';
import { reportEditingPages } from '../extensions/contextmenu/pages/reportEditing';
import { OneLineDocument } from '../nodes/OneLineDocument';
import { Statements } from '../nodes/Statements';
import { VariableControl } from '../nodes/variable/VariableControl';
import { VariableNode, VariableNodeProps } from '../nodes/variable/VariableNode';
import { TiptapContext } from '../utils/TiptapContext';
import { TiptapEditorContent } from '../utils/TiptapEditor';

interface Props {
  value: string;
  context: TiptapContext;
  size?: ResponsiveValue<string>;
  onChange: (value: string) => void;
}

export const TiptapStatementSite = (props: Props) => {
  const { value, context, size, onChange } = props;

  const commandMenuRenderer = React.useCallback(
    () => getContextMenuRenderer(reportEditingPages),
    [],
  );

  const variableControl = React.useCallback(
    (props: VariableNodeProps) => {
      return <VariableControl {...props} size={size} />;
    },
    [size],
  );

  const extensions: Extensions = [
    OneLineDocument,
    Statements,
    Text,
    History,
    Placeholder.configure({
      placeholder: "Type '/' or right-click for commands",
    }),
    Paragraph,
    VariableNode.configure({
      component: variableControl,
      context,
    }),
    ContextMenu.configure({
      renderer: commandMenuRenderer,
    }),
  ];

  const editor = useEditor(
    {
      extensions,
      content: value,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
      editable: !context.isDisabled,
      editorProps: {
        attributes: {
          // Prevents the Prosemirror DOM element from being tabbable
          tabindex: '-1',
        },
      },
    },
    [context.isDisabled, onChange],
  );

  React.useEffect(() => {
    if (editor && !editor.isDestroyed && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  return (
    <TiptapEditorContent context={context} editor={editor} value={value} extensions={extensions} />
  );
};
