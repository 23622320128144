import { BaseSchema, StringSchema, ValidationError, string } from 'yup';
import { Maybe } from 'yup/lib/types';

export const validateSchema = (schema: BaseSchema<any>, values: any): ValidationError[] => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return [];
  } catch (error) {
    return error.inner;
  }
};

export const enumSchema = <A extends Maybe<string>>(value: Record<string, A>) => {
  return string().oneOf(Object.values(value as any)) as StringSchema<A>;
};
