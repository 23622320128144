import { Input } from '@chakra-ui/react';
import React from 'react';
import { AuditTrailFilter } from '../../../hooks/useAuditTrailFilter';
import { FormItem } from '../../forms/FormItem';

interface Props {
  filter: AuditTrailFilter;
}

export const AuditTrailMessageFilterControl = ({ filter }: Props) => {
  const { messageFilter, onMessageFilter } = filter;
  return (
    <FormItem id='message' label='Message'>
      <Input
        defaultValue={messageFilter}
        placeholder='Search by message'
        onChange={(event) => onMessageFilter(event.target.value)}
        size='sm'
      />
    </FormItem>
  );
};
