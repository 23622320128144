import { Tag, TagProps } from '@chakra-ui/react';
import { ReportStatus } from '@piccolohealth/echo-common';

import React from 'react';

interface Props extends TagProps {
  status: ReportStatus;
}

export const ReportStatusTag = (props: Props) => {
  const { status, ...rest } = props;
  switch (status) {
    case ReportStatus.Unreported:
      return (
        <Tag colorScheme='blue' data-pw='reportStatusUnreportedTag' {...rest}>
          Unreported
        </Tag>
      );
    case ReportStatus.AwaitingReview:
      return (
        <Tag colorScheme='yellow' data-pw='reportStatusAwaitingReviewTag' {...rest}>
          Awaiting Review
        </Tag>
      );
    case ReportStatus.Finalized:
      return (
        <Tag colorScheme='green' data-pw='reportStatusFinalizedTag' {...rest}>
          Finalized
        </Tag>
      );
  }
};
