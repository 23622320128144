import { Tag } from '@chakra-ui/react';
import { ReportTemplateStatus } from '@piccolohealth/echo-common';
import React from 'react';

interface Props {
  status: ReportTemplateStatus;
}

export const ReportTemplateStatusTag = (props: Props) => {
  switch (props.status) {
    case ReportTemplateStatus.Draft:
      return <Tag colorScheme='blue'>Draft</Tag>;
    case ReportTemplateStatus.Published:
      return <Tag colorScheme='green'>Published</Tag>;
    case ReportTemplateStatus.Archived:
      return <Tag colorScheme='gray'>Archived</Tag>;
  }
};
