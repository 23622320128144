import { Button, HStack, Icon } from '@chakra-ui/react';
import { Node, NodeTree, useEditor } from '@craftjs/core';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCopy, FaPaste } from 'react-icons/fa';
import { useKeyPressEvent } from 'react-use';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import { getRandomId } from '../../../utils/craftjs';

export const CopyPaste = () => {
  const { selectedNodeId, query, actions } = useEditor((state, query) => ({
    selectedNodeId: query.getEvent('selected').last(),
  }));

  const getCloneTree = React.useCallback(
    (tree: NodeTree) => {
      const newNodes: Record<string, Node> = {};

      const changeNodeId = (node: Node, newParentId?: string) => {
        const newNodeId = getRandomId();
        const childNodes = node.data.nodes.map((childId) =>
          changeNodeId(tree.nodes[childId], newNodeId),
        );
        const linkedNodes = Object.keys(node.data.linkedNodes).reduce((acc, id) => {
          const newLinkedNodeId = changeNodeId(tree.nodes[node.data.linkedNodes[id]], newNodeId);
          return {
            ...acc,
            [id]: newLinkedNodeId,
          };
        }, {});

        const tmpNode = {
          ...node,
          id: newNodeId,
          data: {
            ...node.data,
            parent: newParentId || node.data.parent,
            nodes: childNodes,
            linkedNodes,
          },
        };
        const freshNode = query.parseFreshNode(tmpNode).toNode();
        newNodes[newNodeId] = freshNode;
        return newNodeId;
      };

      const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId]);
      return {
        rootNodeId,
        nodes: newNodes,
      };
    },
    [query],
  );

  const copy = React.useCallback(() => {
    if (!selectedNodeId) {
      return;
    }

    const tree = query.node(selectedNodeId).toNodeTree();
    const nodePairs = Object.keys(tree.nodes).map((id) => [id, query.node(id).toSerializedNode()]);
    const serializedTree = {
      rootNodeId: tree.rootNodeId,
      nodes: JSON.stringify(Object.fromEntries(nodePairs)),
    };

    navigator.clipboard.writeText(JSON.stringify(serializedTree));
  }, [selectedNodeId, query]);

  const paste = React.useCallback(() => {
    if (!selectedNodeId) {
      return;
    }

    navigator.clipboard.readText().then((json) => {
      const theNode = query.node(selectedNodeId).get();
      const parentNode = query.node(theNode.data.parent).get();
      const indexToAdd = parentNode.data.nodes.indexOf(selectedNodeId);

      const data = JSON.parse(json);
      const newNodes = JSON.parse(data.nodes);
      const nodePairs = Object.keys(newNodes).map((nodeId) => {
        return [
          nodeId,
          query.parseSerializedNode(newNodes[nodeId]).toNode((node: Node) => (node.id = nodeId)),
        ];
      });
      const tree = { rootNodeId: data.rootNodeId, nodes: Object.fromEntries(nodePairs) };
      const newTree = getCloneTree(tree);

      actions.addNodeTree(newTree, parentNode.id, indexToAdd + 1);
      actions.selectNode(newTree.rootNodeId);
    });
  }, [selectedNodeId, actions, query, getCloneTree]);

  useKeyPressEvent('ctrl + c', copy, P.noop, useKeyboardJs as any);
  useKeyPressEvent('command + c', copy, P.noop, useKeyboardJs as any);
  useKeyPressEvent('ctrl + v', paste, P.noop, useKeyboardJs as any);
  useKeyPressEvent('command + v', paste, P.noop, useKeyboardJs as any);

  return (
    <HStack>
      <Button
        leftIcon={<Icon as={FaCopy} />}
        isDisabled={P.isNil(selectedNodeId)}
        onClick={copy}
        flex='1'
      >
        Copy
      </Button>
      <Button
        leftIcon={<Icon as={FaPaste} />}
        isDisabled={P.isNil(selectedNodeId)}
        onClick={paste}
        flex='1'
      >
        Paste
      </Button>
    </HStack>
  );
};
