import { Box } from '@chakra-ui/react';
import React from 'react';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { useSSRNode } from '../../hooks/useSSRNode';

export type Props = BaseNodeProps;

export const Deprecated = (props: Props) => {
  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  return (
    <Box ref={(ref) => connect(drag(ref))} color='red.400' fontWeight='bold'>
      Deprecated component.
    </Box>
  );
};

Deprecated.defaultProps = {};

Deprecated.craft = {
  name: 'Deprecated',
  props: Deprecated.defaultProps,
};
