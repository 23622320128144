import * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteReports } from '../mutations/deleteReports.mutation';

export const useDeleteReportsMutation = (
  options?: MutationOptions<Types.DeleteReportsMutation, Types.DeleteReportsMutationVariables>,
) => {
  return useMutation(gqlFetcher(DeleteReports), options);
};
