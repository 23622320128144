import * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { MutationOptions, gqlFetcher } from '../fetcher';
import { CreateOrganization } from '../mutations/createOrganization.mutation';

export const useCreateOrganizationMutation = (
  options?: MutationOptions<
    Types.CreateOrganizationMutation,
    Types.CreateOrganizationMutationVariables
  >,
) => {
  return useMutation(gqlFetcher(CreateOrganization), options);
};
