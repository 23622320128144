import { Text } from '@chakra-ui/react';
import { Invoice } from '@piccolohealth/echo-common';
import { DescriptionList } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';

interface Props {
  invoice: Invoice;
}

export const InvoiceProductTypeSummary = (props: Props) => {
  const items = P.orderBy(props.invoice.subscriptionItems, (i) => i.product.name).map((item) => {
    return {
      title: item.product.name,
      content: (
        <Text fontSize='sm'>
          {item.usageQuantity} {inflection.inflect('item', item.usageQuantity)}
        </Text>
      ),
    };
  });

  return <DescriptionList rowGap={2} items={items} />;
};
