import { HStack, Input } from '@chakra-ui/react';
import React from 'react';
import { AddMeasurementControl } from '../AddMeasurementControl';
import { UseMeasurementControlReturn } from '../tool/hooks/useMeasurementControl';
import { VariablesBySite } from './MeasurementsSidebar';

interface Props {
  value: string;
  measurementControl: UseMeasurementControlReturn;
  variablesBySite: VariablesBySite;
  onChange: (value: string) => void;
}

export const MeasurementListHeader = (props: Props) => {
  const { value, onChange, measurementControl, variablesBySite } = props;
  return (
    <HStack py='10px' pr={2}>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        size='sm'
        placeholder='Search measurements...'
        color='white'
        border='none'
        bg='gray.700'
      />
      <AddMeasurementControl measurementControl={measurementControl} variables={variablesBySite} />
    </HStack>
  );
};
