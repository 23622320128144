import { IntegrationType } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { showModal } from '../../../generic/Modal';
import { IntegrationCard } from '../IntegrationCard';
import { FaxIntegrationModal } from './FaxIntegrationModal';

import FaxLogo from '/logos/fax.logo.png';

const title = 'Fax';
const description = 'Distribute reports via fax';

export const FaxIntegrationCard = () => {
  const { organization } = useAppContext();

  const isIntegrationEnabled = organization.hasIntegrationEnabled(IntegrationType.Fax);

  return (
    <IntegrationCard
      title={title}
      description={description}
      logo={FaxLogo}
      isIntegrationEnabled={isIntegrationEnabled}
      onEditIntegration={() =>
        showModal(FaxIntegrationModal, {
          title,
          description,
          logo: FaxLogo,
          isIntegrationEnabled,
        })
      }
      h='4xs'
      w='sm'
    />
  );
};
