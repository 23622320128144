import React from 'react';
import * as ReactQRCode from 'react-qr-code';

export interface QRCodeProps extends ReactQRCode.QRCodeProps {
  value: string;
}

export const QRCode = (props: QRCodeProps) => {
  const { value } = props;

  return (
    <ReactQRCode.default
      value={value}
      size={256}
      bgColor='transparent'
      style={{
        height: 'auto',
        maxWidth: '100%',
        width: '100%',
      }}
    />
  );
};
