import { Box, HStack, InputGroup, InputRightAddon, Stack } from '@chakra-ui/react';
import { User } from '@piccolohealth/echo-common';
import { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormSection } from '../../../components/forms/FormSection';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../../components/forms/hookform/HookedInput';
import { HookedRadioGroup } from '../../../components/forms/hookform/HookedRadioGroup';
import { HookedReportTypeChooser } from '../../../components/forms/hookform/HookedReportTypeChooser';
import { HookedSelect } from '../../../components/forms/hookform/HookedSelect';
import { HookedSingleDatepicker } from '../../../components/forms/hookform/HookedSingleDatepicker';
import { Divider } from '../../../components/generic/Divider';
import { useUsersQuery } from '../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../hooks/useAppContext';

export const WorklistEntryForm = () => {
  const { organization } = useAppContext();

  const { data, isLoading } = useUsersQuery({
    organizationId: organization.id,
  });

  const cardiologistOptions: SelectOption<User>[] = React.useMemo(() => {
    return ((data?.organization?.users as User[]) ?? [])
      .filter((user) => {
        const membership = user.organizationMemberships.find(
          (membership) => membership.organizationId === organization.id,
        );
        const roles = membership?.roles ?? [];
        return roles.some((role) => role.name === 'Cardiologist');
      })
      .map((user) => ({
        label: user.name,
        value: user.id,
        raw: user,
      }));
  }, [data?.organization?.users, organization.id]);

  return (
    <Stack spacing={6}>
      <FormSection heading='Patient details'>
        <HStack w='full' spacing={8}>
          <HookedFormItem name='patientFirstName' label='First name'>
            <HookedInput name='patientFirstName' placeholder='Enter first name' />
          </HookedFormItem>
          <HookedFormItem name='patientLastName' label='Last name'>
            <HookedInput name='patientLastName' placeholder='Enter last name' />
          </HookedFormItem>
        </HStack>
        <HStack>
          <HookedFormItem name='patientId' label='Patient ID'>
            <HookedInput name='patientId' placeholder='Enter patient ID' />
          </HookedFormItem>
        </HStack>
        <HStack w='full' spacing={8}>
          <HookedFormItem label='Date of birth' name='patientDob'>
            <HookedSingleDatepicker name='patientDob' placeholder='Enter date of birth' />
          </HookedFormItem>
          <HookedFormItem label='Sex' name='patientSex'>
            <HookedRadioGroup
              name='patientSex'
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
            />
          </HookedFormItem>
        </HStack>

        <HStack w='full' spacing={8}>
          <HookedFormItem label='Patient height' name='patientHeight'>
            <InputGroup size='sm'>
              <HookedInput
                name='patientHeight'
                borderRightRadius='none'
                placeholder='Enter height'
              />
              <InputRightAddon borderRightRadius='md'>m</InputRightAddon>
            </InputGroup>
          </HookedFormItem>
          <HookedFormItem label='Patient weight' name='patientWeight'>
            <InputGroup size='sm'>
              <HookedInput
                name='patientWeight'
                borderRightRadius='none'
                placeholder='Enter weight'
              />
              <InputRightAddon borderRightRadius='md'>kg</InputRightAddon>
            </InputGroup>
          </HookedFormItem>
        </HStack>
      </FormSection>
      <Divider />

      <FormSection heading='Report details'>
        <HStack w='full' spacing={8}>
          <HookedFormItem label='Report type' name='reportTemplate'>
            <Box w='min-content'>
              <HookedReportTypeChooser name='reportTemplate' />
            </Box>
          </HookedFormItem>

          <HookedFormItem label='Cardiologist' name='performingPhysicianId'>
            <HookedSelect
              name='performingPhysicianId'
              options={cardiologistOptions}
              isLoading={isLoading}
              placeholder='Choose a cardiologist'
            />
          </HookedFormItem>
        </HStack>
      </FormSection>
    </Stack>
  );
};
