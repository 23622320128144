import { Text, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { Machine } from '@piccolohealth/echo-common';
import React from 'react';
import { MachineTag } from './MachineTag';

interface Props {
  machines: Machine[];
}

export const MeasurementMachineTagsList = (props: Props) => {
  const { machines } = props;

  const displayedMachines = machines.slice(0, 3);
  const remainingMachines = machines.slice(3);

  const tooltip = remainingMachines.length > 0 && (
    <Tooltip
      label={
        <Wrap>
          {remainingMachines.map((machine) => (
            <WrapItem key={machine.model}>
              <MachineTag machine={machine} />
            </WrapItem>
          ))}
        </Wrap>
      }
      placement='bottom'
      hasArrow
    >
      <Text fontSize='xs' fontWeight='semibold'>
        +{remainingMachines.length} more
      </Text>
    </Tooltip>
  );

  return (
    <Wrap align='center'>
      {displayedMachines.map((machine) => (
        <WrapItem key={machine.model}>
          <MachineTag machine={machine} />
        </WrapItem>
      ))}
      {tooltip}
    </Wrap>
  );
};
