import { AutoComplete, AutoCompleteProps } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props<A> extends Omit<AutoCompleteProps<A>, 'value' | 'onChange' | 'onInputChange'> {
  name: string;
}

export const HookedAutoComplete = <A extends unknown>(props: Props<A>) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return (
    <AutoComplete
      {...rest}
      onChange={field.onChange}
      onInputChange={field.onChange}
      value={field.value}
    />
  );
};
