import { useEditor, useNode } from '@craftjs/core';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  render: React.ReactElement;
}

export const RenderNode = (props: React.PropsWithChildren<Props>) => {
  const { render } = props;
  const { query } = useEditor();

  const { isHover, isActive, dom, name, id } = useNode((node) => {
    return {
      isHover: node.events.hovered,
      isEnabled: query.getOptions().enabled,
      isEmpty: query.node(node.id).isCanvas() && P.isEmpty(query.node(node.id).childNodes()),
      isActive: node.events.selected,
      dom: node.dom,
      name: node.data.name,
      id: node.id,
    };
  });

  React.useEffect(() => {
    if (dom) {
      if (isActive) {
        dom.classList.remove('editor-component-hover');
        dom.classList.add('editor-component-selected');
      } else if (isHover) {
        dom.classList.remove('editor-component-selected');
        dom.classList.add('editor-component-hover');
      } else {
        dom.classList.remove('editor-component-selected');
        dom.classList.remove('editor-component-hover');
      }
      dom.dataset.cjsId = id;
      dom.dataset.cjsName = name;
    }
  }, [dom, isActive, isHover, id, name]);

  return render;
};
