import { Heading } from '@chakra-ui/react';
import { SingleDatepicker } from '@piccolohealth/ui';
import React from 'react';
import { ReportsFilter } from '../../../hooks/useReportsFilter';

interface Props {
  filter: ReportsFilter;
}

export const PatientDobFilterControl = ({ filter }: Props) => {
  const { reportPatientDobFilter, onReportPatientDobFilter } = filter;

  return (
    <>
      <Heading as='h5' size='sm' pb='2' data-pw='reportPatientDobFilterTitle'>
        Patient DOB
      </Heading>
      <SingleDatepicker
        date={reportPatientDobFilter ?? null}
        onChange={(v) => onReportPatientDobFilter(v ?? undefined)}
        placeholder='All dates'
        data-pw='patientDateOfBirthFilterInput'
      />
    </>
  );
};
