import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { COMPANY_NAME } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { AuthCard } from './components/AuthCard';
import { AuthLayout } from './components/AuthLayout';

export const Logout = () => {
  const auth = useAuth();

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({ mutationFn: auth.logout });

  React.useEffect(() => {
    mutate();
  }, [mutate]);

  const onClickLogin = () => {
    navigate('/login');
  };

  if (isLoading) {
    return (
      <AuthLayout>
        <Spin />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={4} align='center'>
          <Icon as={FaRegCheckCircle} color='success' boxSize={16} />
          <Text fontSize='sm' textAlign='center'>
            You are now logged out of {COMPANY_NAME}
          </Text>
          <Button w='full' size='md' colorScheme='purple' onClick={onClickLogin}>
            Login
          </Button>
        </Stack>
      </AuthCard>
    </AuthLayout>
  );
};
