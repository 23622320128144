import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { renderPiccoloError } from '@piccolohealth/echo-common';
import { stringParam, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaRegCheckCircle } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { HookedFormItem } from '../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../components/forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../components/forms/hookform/HookedSubmitButton';
import { useAuth } from '../../context/AuthContext';
import { AuthCard } from './components/AuthCard';
import { AuthLayout } from './components/AuthLayout';
import { BackToLoginButton } from './components/BackToLoginButton';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const schema: Yup.SchemaOf<FormValues> = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export const ResetPassword = () => {
  const [params] = useQueryParams({
    oobCode: stringParam,
  });
  const { oobCode } = params;

  const { resetPassword, logout } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    logout();
  }, [logout]);

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    if (!oobCode) {
      return;
    }

    try {
      await resetPassword(oobCode, data.password);
    } catch (error) {
      const { message } = renderPiccoloError(error.response.data);
      methods.reset({ password: '', confirmPassword: '' });
      methods.setError('password', { message });
    }
  };

  const onClickLogin = () => {
    navigate('/login');
  };

  if (!oobCode) {
    return <Navigate to='/login' />;
  }

  const content = P.run(() => {
    if (methods.formState.isSubmitSuccessful) {
      return (
        <AuthCard>
          <Stack spacing={4} align='center'>
            <Icon as={FaRegCheckCircle} color='success' boxSize={16} />
            <Text fontSize='sm' textAlign='center'>
              Your password has been reset successfully. Please login with your new password.
            </Text>
            <Button w='full' size='md' colorScheme='purple' onClick={onClickLogin}>
              Login
            </Button>
          </Stack>
        </AuthCard>
      );
    }

    return (
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Reset Password
          </Text>
          <Text fontSize='sm' color='secondary'>
            Please enter your new password below
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <HookedFormItem name='password' label='New Password'>
                <HookedInput
                  name='password'
                  type='password'
                  size='md'
                  placeholder='Enter new password'
                />
              </HookedFormItem>
              <HookedFormItem name='confirmPassword' label='Confirm Password'>
                <HookedInput
                  name='confirmPassword'
                  type='password'
                  size='md'
                  placeholder='Confirm new password'
                />
              </HookedFormItem>

              <HookedSubmitButton size='md' mt={4} loadingText='Resetting password...'>
                Reset Password
              </HookedSubmitButton>
            </Stack>
          </form>
        </FormProvider>
        <BackToLoginButton />
      </AuthCard>
    );
  });

  return <AuthLayout>{content}</AuthLayout>;
};
