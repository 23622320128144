import { Text } from '@chakra-ui/react';
import { Extensions } from '@tiptap/core';
import { generateHTML, generateJSON } from '@tiptap/html';
import React from 'react';

type Node = {
  type: string;
  content?: Node[];
  [key: string]: any;
};

const sanitizeNode = (item: Node) => {
  // Iterate through each node.
  (item.content ?? []).forEach((value, index) => {
    // Only deal with variable nodes
    if (value.type === 'variable') {
      // If the variable node is the first in the content array,
      // it should start with a capital letter
      if (index === 0) {
        value.attrs = { ...value.attrs, capitalize: true };
      } else {
        const previousValue = item.content?.[index - 1];

        // If the previous node is a text node, and that text node ends with a period,
        // then the variable node should start with a capital letter
        if (previousValue?.type === 'text') {
          const trimmedPreviousValue = previousValue.text?.trimEnd();

          if (!trimmedPreviousValue) {
            value.attrs = { ...value.attrs, capitalize: true };
          } else if (trimmedPreviousValue.endsWith('.')) {
            value.attrs = { ...value.attrs, capitalize: true };
          }
        }
      }
    }
  });
};

const transform = (node: Node, f: (value: Node) => void) => {
  f(node); // Apply the callback function to the current node

  (node.content ?? []).forEach((childNode) => {
    transform(childNode, f);
  });
};

interface Props {
  html: string;
  extensions: Extensions;
}

export const TiptapSSR = (props: Props) => {
  const { html, extensions } = props;

  if (html === '' || html === undefined) {
    return (
      <Text className='tiptap-statement-site-item'>
        <br />
      </Text>
    );
  }

  const json = generateJSON(html, extensions) as Node;
  transform(json, sanitizeNode);
  const __html = generateHTML(json, extensions);

  return <Text dangerouslySetInnerHTML={{ __html }} />;
};
