import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../../hooks/useAppContext';

export const ProtectedRoute = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { auth, user } = useAppContext();

  if (auth.isLoading || user.isLoading) {
    return <Spin />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to='/login' />;
  }

  return <>{children}</>;
};
