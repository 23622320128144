import { Box } from '@chakra-ui/react';
import { getDefaultVariableValue } from '@piccolohealth/echo-common';
import { Command, FloatingPopover } from '@piccolohealth/ui';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { Extensions, useEditor } from '@tiptap/react';
import React from 'react';
import { ContextMenu } from '../extensions/contextmenu/ContextMenu';
import {
  TiptapCommandMenuState,
  getContextMenuRenderer,
} from '../extensions/contextmenu/contextMenuRenderer';
import { dropdownEditPage } from '../extensions/contextmenu/pages/dropdowns';
import { statementEditingPage } from '../extensions/contextmenu/pages/statementEditing';
import { OneLineStatementDocument } from '../nodes/OneLineStatementDocument';
import { HighlightedVariableValue } from '../nodes/variable/HighlightedVariableValue';
import { VariableNode, VariableNodeProps } from '../nodes/variable/VariableNode';
import { TiptapContext, useTiptapContext } from '../utils/TiptapContext';
import { TiptapEditorContent } from '../utils/TiptapEditor';

interface Props {
  value: string;
  context: TiptapContext;
  isDisabled: boolean;
  site: string;
  onChange: (value: string) => void;
}

export const TiptapReportTemplateStatement = (props: Props) => {
  const { value, context, site, onChange } = props;

  const VariableControl = (props: VariableNodeProps) => {
    const { shouldCapitalize } = props;
    const reportTemplateVariable = props.reportTemplateVariable;
    const value = getDefaultVariableValue(reportTemplateVariable);

    const context = useTiptapContext();

    const commandMenuState: TiptapCommandMenuState = {
      context: context,
      editor: props.editor,
      range: {
        from: props.editor.state.selection.anchor,
        to: props.editor.state.selection.anchor,
      },
    };

    const content = (
      <Box as='span'>
        <HighlightedVariableValue
          reportTemplateVariable={reportTemplateVariable}
          template={value}
          value={value}
          capitalize={shouldCapitalize()}
          showChoices
        />
      </Box>
    );

    if (reportTemplateVariable.__typename === 'ReportTemplateChoiceVariable') {
      return (
        <FloatingPopover
          render={(props) => (
            <Command.CommandMenu
              onOpenChange={props.context.onOpenChange}
              rootPageId={{ id: dropdownEditPage.id, args: { reportTemplateVariable } }}
              getPages={() => [dropdownEditPage] as Command.Page<TiptapCommandMenuState>[]}
              ctx={commandMenuState}
            />
          )}
        >
          {content}
        </FloatingPopover>
      );
    } else {
      return content;
    }
  };

  const contextMenu = React.useCallback(() => {
    return getContextMenuRenderer(statementEditingPage(site));
  }, [site]);

  const extensions: Extensions = [
    OneLineStatementDocument,
    Text,
    Paragraph,
    History,
    Placeholder.configure({
      placeholder: "Type '/' or right-click for commands",
    }),
    VariableNode.configure({
      context,
      component: VariableControl,
    }),
    ContextMenu.configure({
      renderer: contextMenu,
    }),
  ];

  const editor = useEditor(
    {
      extensions,
      content: value,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
      editable: !context.isDisabled,
    },
    [context.isDisabled, onChange],
  );

  React.useEffect(() => {
    if (editor && !editor.isDestroyed && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  return (
    <TiptapEditorContent context={context} editor={editor} value={value} extensions={extensions} />
  );
};
