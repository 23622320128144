import Konva from 'konva';
import React from 'react';
import { useMethods } from 'react-use';
import {
  Line,
  MeasurementInfo,
  MeasurementTool,
  Text,
  UltrasoundRegion,
  Vector2D,
  calculateLabelPosition,
  calculateLength,
  calculateRoundedMeasurement,
  clampToRegion,
  getMeasurementMetrics,
  getScaledPoint,
} from '../../utils';

export interface UseLengthToolOptions {
  scale: Vector2D;
  ultrasoundRegions: UltrasoundRegion[];
  measurementInfo: MeasurementInfo | null;
}

export interface UseLengthToolReturn extends MeasurementTool {
  line: Line | null;
  isDrawing: boolean;
  handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseUp: () => void;
}

interface State {
  isDrawing: boolean;
  line: Line | null;
  startingRegion: UltrasoundRegion | null;
}

const initialState: State = {
  isDrawing: false,
  line: null,
  startingRegion: null,
};

const createMethods = (options: UseLengthToolOptions) => (state: State) => ({
  reset() {
    return initialState;
  },

  startLine(point: Vector2D) {
    const region = options.ultrasoundRegions.find((r) => r.contains(point));

    if (!region) {
      return state;
    }

    const { suffix } = getMeasurementMetrics(options.ultrasoundRegions, [point, point]);

    if (suffix == 'pixels') {
      return state;
    }

    return {
      ...state,
      isDrawing: true,
      startingRegion: region,
      line: { start: point, end: point },
    };
  },

  drawLine(point: Vector2D) {
    if (!state.isDrawing || !state.line || !state.startingRegion) {
      return state;
    }

    const clampedEnd = clampToRegion(point, state.startingRegion);
    const { rowPixelSpacing, colPixelSpacing, suffix } = getMeasurementMetrics(
      options.ultrasoundRegions,
      [state.line.start, clampedEnd],
    );

    const length = calculateLength(
      state.line.start,
      clampedEnd,
      rowPixelSpacing ?? 1,
      colPixelSpacing ?? 1,
    );

    return {
      ...state,
      line: {
        start: state.line.start,
        end: clampedEnd,
        length: { value: length, units: suffix },
      },
    };
  },

  endLine() {
    return {
      ...state,
      isDrawing: false,
    };
  },
});

export const useLengthTool = (options: UseLengthToolOptions): UseLengthToolReturn => {
  const [state, methods] = useMethods(createMethods(options), initialState);

  const handleMouseDown = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      const point = event.target.getStage()?.getPointerPosition();

      if (point) {
        methods.startLine(getScaledPoint(point, options.scale));
      }
    },
    [methods, options.scale],
  );

  const handleMouseMove = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      const point = event.target.getStage()?.getPointerPosition();

      if (point) {
        methods.drawLine(getScaledPoint(point, options.scale));
      }
    },
    [methods, options.scale],
  );

  const handleMouseUp = React.useCallback(() => {
    methods.endLine();
  }, [methods]);

  const measurement = React.useMemo(() => {
    if (!options.measurementInfo || !state.line || !state.line.length) {
      return null;
    }

    return calculateRoundedMeasurement({
      fromUnit: state.line.length.units,
      toUnit: options.measurementInfo.units,
      value: state.line.length.value,
      precision: options.measurementInfo.precision,
    });
  }, [options.measurementInfo, state.line]);

  const text: Text | null = React.useMemo(() => {
    if (!state.line?.length || !measurement) {
      return null;
    }

    const position = calculateLabelPosition(state.line.start, state.line.end, 15);

    return {
      value: `${measurement.value} ${measurement.units}`,
      position,
    };
  }, [state.line, measurement]);

  return {
    line: state.line,
    text,
    isDrawing: state.isDrawing,
    measurement,
    reset: methods.reset,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
};
