import { P } from '@piccolohealth/util';
import { Role, User } from '../graphql/types';

export interface UserAndRoles {
  user: User;
  roles: Role[];
}

export const getUserAndRoles = (organizationId: string, user: User): UserAndRoles => {
  const membership = user.organizationMemberships.find(
    (organizationMembership) => organizationMembership.organizationId === organizationId,
  );

  return { user, roles: P.compact(membership?.roles ?? []) };
};
