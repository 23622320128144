import { Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

export const MFAChooseMethodButton = () => {
  const auth = useAuth();

  const navigate = useNavigate();

  const onTryAnotherMethod = async () => {
    navigate('/mfa/options');
  };

  if (auth.isMFAEnrolled) {
    return null;
  }

  return (
    <Button
      variant='link'
      w='fit-content'
      alignSelf='center'
      cursor='pointer'
      onClick={onTryAnotherMethod}
    >
      Try another method
    </Button>
  );
};
