import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteDistributionMutation } from '../../../graphql/hooks/useDeleteDistributionMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  reportId: string;
  distributionId: string;
}

export const DistributionDeleteModal = createModal<Props>((props) => {
  const { reportId, distributionId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteDistributionMutation({});
  const { hide, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId,
        distributionId,
        request: {},
      })
      .then(async () => {
        hide();
        successToast('Distribution deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting distribution: ${err.message}`);
      });
  }, [mutation, organization, reportId, distributionId, hide, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this distribution?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              type='submit'
              onClick={onClick}
              data-pw='distributionDeleteModalSubmitButton'
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
